<template>
  <v-dialog
    content-class="baseModal"
    v-model="isValidAuth"
    max-width="700"
    :persistent="true"
    dark
  >
    <div class="text-center">
      <h2 class="msgText">
        Please stay tuned for new<br />
        features to be available soon
      </h2>
      <div class="underlineBtn mt-5" @click="logout">Logout</div>
    </div>
  </v-dialog>
</template>
<script>
  import { AUTH_LOGOUT } from '@/store/auth.module';
  export default {
    name: 'MessageModal',
    methods: {
      logout() {
        this.$store.dispatch(AUTH_LOGOUT, { isSessionExpired: false });
      }
    },
    computed: {
      message() {
        return this.$store.state.shared.modalMessage;
      },
      isValidAuth() {
        return false;
      },
      action() {
        return this.$store.state.shared.modalAction;
      }
    }
  };
</script>
<style lang="scss">
  .msgText {
    text-transform: uppercase;
  }
</style>
