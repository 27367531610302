import { apiService, getQueryParamString } from '@/services';

export const JOB_ORGANIZER = {
  getJobList,
  postJobReview,
  getCastingJobToReview,
  getCastingJobById,
  getTalentList,
  removeJob,
  applyRole,
  removeDraftJob,
  getDraftJob,
  updateInvitationStatus,
  checkReapply
};

const URL = {
  API: 'api',
  V1: 'v1',
  V2: 'v2',
  CASTING: 'casting',
  REVIEW: 'review',
  TALENTS: 'talents',
  APPLY: 'apply',
  CASTING_DRAFTS: 'casting-drafts',
  JOB_DRAFTS: 'job-drafts',
  INVITE: 'invite',
  CHECK_REAPPLY: 'check-reapply'
};

function getJobList(query) {
  const queryParam = getQueryParamString({
    ...query,
    sorting: query?.sorting?.value
  });
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.CASTING}?` + queryParam.toString()
  );
}
function getDraftJob(query) {
  const queryParam = getQueryParamString({
    ...query,
    sorting: query?.sorting?.value
  });
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.JOB_DRAFTS}?` + queryParam.toString()
  );
}
function postJobReview(id, data) {
  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.CASTING}/${id}/${URL.REVIEW}`,
    data
  );
}
function getCastingJobToReview(id) {
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.CASTING}/${id}/${URL.REVIEW}`
  );
}
function getCastingJobById(id) {
  return apiService().get(`/${URL.API}/${URL.V2}/${URL.CASTING}/${id}`);
}
function getTalentList(data) {
  const queryParam = getQueryParamString({
    ...data
  });
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.CASTING}/${URL.TALENTS}?` +
      queryParam.toString()
  );
}
function removeJob(id) {
  return apiService().delete(`/${URL.API}/${URL.V1}/${URL.CASTING}/${id}`);
}
function removeDraftJob(id) {
  return apiService().delete(
    `/${URL.API}/${URL.V1}/${URL.CASTING_DRAFTS}/${id}`
  );
}
function applyRole(data) {
  let requestBody = {
    talent_uid: data.talentId,
    agency_uid: data.agencyId,
    casting_job_id: data.jobId,
    casting_role_id: data.roleId
  };
  if (data?.unionNo) {
    requestBody['union_number'] = data.unionNo;
  }
  return apiService().post(
    `/${URL.API}/${URL.V2}/${URL.CASTING}/${URL.APPLY}`,
    requestBody
  );
}
function checkReapply(data) {
  let requestBody = {
    talent_uid: data.talentId,
    agency_uid: data.agencyId,
    casting_job_id: data.jobId,
    casting_role_id: data.roleId
  };
  if (data?.unionNo) {
    requestBody['union_number'] = data.unionNo;
  }
  return apiService().post(
    `/${URL.API}/${URL.V2}/${URL.CASTING}/${URL.CHECK_REAPPLY}`,
    requestBody
  );
}
function updateInvitationStatus(id, data) {
  return apiService().put(
    `/${URL.API}/${URL.V1}/${URL.CASTING}/${URL.INVITE}/${id}`,
    data
  );
}
