const DISPLAY_INFOS = {
  RATES: 'Rates',
  LIST_PROJECTS: 'Notable Projects',
  TALENTS_NO: 'No. of talents',
  WEBSITE_LINK: 'Website Link',
  FACEBOOK: 'Facebook',
  INSTAGRAM: 'Instagram',
  TWITTER: 'Twitter',
  LINKEDIN: 'LinkedIn',
  YOUTUBE: 'Youtube',
  TIKTOK: 'Tiktok',
  TWITCH: 'Twitch',
  CV_FILE: 'CV File',
  SKILLS: 'Skills',
  DESIGNATION: 'Designation',
  COMPANY_WRITE_UP: 'Company Write Up',
  COMPANY_SHOWREEL_VIDEO: 'Company Showreel Video',
  COMPANY_SHOWREEL_IMAGE: 'Company Showreel Image',
  COUNTRY: 'Country',
  GENDER: 'Gender',
  PRONOUNS: 'Pronouns',
  DOB: 'Date of Birth',
  DESCRIPTION: 'Description',
  RACE: 'Race',
  LANGUAGES_SPOKEN: 'Languages Spoken',
  CHARACTERISTICS: 'Characteristics',
  INTRO_SUMMARY: 'Intro Summary',
  BIO: 'Bio',
  BIO_LINKS: 'Bio Links',
  INTRO_VIDEO: 'Intro Video',
  GALLERY: 'Gallery',
  AGE_TO_PLAY: 'On-Screen Age' //age_to_play
};

export { DISPLAY_INFOS };
