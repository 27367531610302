<template>
  <v-dialog
    v-model="dialogInfo.show"
    :persistent="dialogInfo.isPersistent"
    width="450"
    content-class="app-dialog-info"
    dark
  >
    <v-card
      min-height="300"
      class="d-flex justify-center align-center pa-10 pa-sm-5"
    >
      <div class="content">
        <div class="text-center">
          <v-icon v-if="dialogInfo.type == 'info'" color="primary" class="icon"
            >mdi-information</v-icon
          >
          <v-icon v-if="dialogInfo.type == 'error'" color="error" class="icon"
            >mdi-alert-octagon</v-icon
          >
          <v-icon
            v-if="dialogInfo.type == 'warning'"
            color="warning"
            class="icon"
            >mdi-alert</v-icon
          >
          <v-icon
            v-if="dialogInfo.type == 'success'"
            color="success"
            class="icon"
            >mdi-check-bold</v-icon
          >
        </div>
        <div
          class="text-h5 text-center text-uppercase font-weight-bold mt-5 mb-2"
        >
          {{ dialogInfo.title }}
        </div>
        <div class="text-center mb-6">
          {{ dialogInfo.description }}
        </div>
        <v-row class="d-flex justify-space-around mt-10">
          <template v-for="(items, index) in dialogInfo.buttons">
            <v-col :key="index" cols="12" md="6" class="pa-1 pa-md-2">
              <v-btn
                :color="items.color"
                :outlined="items.outlined"
                rounded
                depressed
                block
                min-height="40"
                :class="items.class || ''"
                @click="items.action"
              >
                {{ items.text }}
              </v-btn>
            </v-col>
          </template>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'AppDialogInfo',
    computed: {
      dialogInfo() {
        return this.$store.state.shared.dialogInfo;
      }
    }
  };
</script>

<style lang="scss">
  .app-dialog-info {
    .content {
      width: 350px;
    }
    .icon {
      font-size: 8rem !important;
    }
  }
</style>
