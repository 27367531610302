const SHARED = Object.freeze({
  DEFAULT_LOCALE: 'en',
  FALLBACK_LOCALE: 'en',
  DEFAULT_LAYOUT: 'Layout',
  PROFILE_LAYOUT: 'ProfileLayout',
  PROFILE_LAYOUT_WITHOUT_SIDEBAR: 'ProfileLayoutWithoutSideBar',
  DEFAULT_LAYOUT_WITH_FOOTER: 'LayoutWithFooter',
  DEFAULT_PHONE_CODE: '+65',
  DATE_TODAY: new Date().toISOString().substr(0, 10)
});

const I18N = Object.freeze({
  DEFAULT_LANGUAGE: process.env.VUE_APP_I18N_LOCALE,
  LOCALE_STORAGE_KEY_NAME: 'language',
  AVAILABLE_LANGUAGES: ['en']
});

const JWT = Object.freeze({
  TOKEN: 'token',
  JWT_TOKEN_TYPE: 'Bearer ',
  SESSION_EXPIRATION_TIME: 'sessionExpirationTime',
  LAST_ACTIVITY_TIME: 'lastActivityTime'
});

const API = Object.freeze({
  BASE_API_URL: process.env.VUE_APP_BASE_API_URL,
  CHAT_API_URL: process.env.VUE_APP_CHAT_API_URL,
  DATETIME_FROM_START_TIME: ' 00:00:00.000',
  DATETIME_TO_END_TIME: ' 23:59:59.999'
});

const LOCAL = Object.freeze({
  CONSTANTS: 'constants'
});

const ANY = [String, Object, Number, Boolean, Array, Date, Symbol];
const DEFAULT_DATE_FORMAT = 'D MMM YYYY';
const DEFAULT_TIME_FORMAT = 'LT';

const SESSION = Object.freeze({
  TEASER: 'teaser'
});

export { SHARED, I18N, JWT, API, LOCAL, SESSION, ANY, DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT};
