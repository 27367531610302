<template>
  <div
    class="chat"
    :class="openChatClass ? 'active' : 'diactive'"
  >
    <div class="chatClose">
      <v-icon color="white" @click="chatClose()">mdi-close</v-icon>
    </div>
    <iframe
      :src="chatUrl"
      target="openChat"
      frameborder="0"
      width="400px"
      height="92%"
    >
    </iframe>
  </div>
</template>

<script>
  import { uiHelper } from '@/utils/ui.helper';
  // import { bus } from '@/main.js';
  import { Configuration } from '@/utils';
  import { mapGetters } from 'vuex';
  import { AUTH_IS_AUTHENTICATED } from '@/store/auth.module';

  export default {
    name: 'AppChat',
    data: () => ({
      chatUrl: null,
      openChat: false,
      openChatClass: false
    }),
    created() {
      // this.getChatUrl();
      // bus.$on('openChatWithId', (id) => {
      //   this.chatUrl = this.chatUrl + `&chatGroupId=${id}`;
      // });
    },
    computed: {
      ...mapGetters({
        isAuthenticated: [AUTH_IS_AUTHENTICATED]
      }),
      chatGroupId() {
        return this.$store.state.shared.openChatId;
      },
      openChatClassState() {
        return this.$store.state.shared.openChatClass;
      }
    },
    watch: {
      // isAuthenticated(val) {
      //   if (val) {
      //     this.getChatUrl();
      //   }
      // },
      chatGroupId(val, prev) {
        if(val && val != prev) {
          this.chatUrl = null;
          this.getChatUrl(val);
        }
      },
      openChatClassState(val) {
        if(val && !this.chatGroupId) this.getChatUrl();
        this.openChatClass = this.$store.state.shared.openChatClass;
      }
    },
    methods: {
      getChatUrl(val = null) {
        let token = uiHelper.getLocalStorage('token');
        const URL = Configuration.value('chatApiUrl');
        this.chatUrl = `${URL}#/chatScreen?token=${token}`;
        if(val) this.chatUrl = this.chatUrl + `&chatGroupId=${val}`;
      },
      chatClose() {
        this.chatUrl = null;
        this.$closeChat();
      }
    }
  };
</script>

<style lang="scss" scoped>
  .chat-bg {
    min-width: 100%;
    min-height: 100%;
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    z-index: 10;
  }
  .chat {
    background: #000;
    position: fixed;
    bottom: 0;
    z-index: 10;
    height: 100%;
    &.active {
      animation: 0.5s linear move-in forwards;
    }
    &.diactive {
      animation: 0.5s linear move-out forwards;
    }
    .chatClose {
      display: flex;
      justify-content: end;
      padding: 15px;
      color: #fff;
      width: 100%;
    }
  }

  // Mobile size responsive start
  @media screen and (max-width: 400px) {
    .chat {
      width: 100%;
      iframe {
        z-index: -1;
        width: 100%;
      }
    }
  }
  // Mobile size responsive end

  // animation set start
  @keyframes move-in {
    from {
      display: none;
      right: -400px;
    }
    to {
      right: 0px;
      display: block;
    }
  }
  @keyframes move-out {
    from {
      display: block;
      right: 0px;
    }
    to {
      right: -450px;
      display: none;
    }
  }
  // animation set end
</style>
