import { CONSTANTS } from '@/api';
import { LOCAL, ROUTE_NAME } from '@/constants';
import { DISPLAY_INFOS } from '@/constants/enums';
import { uiHelper, apiHelper } from '@/utils';
import {
  SHARED_OPEN_LOADING,
  SHARED_CLOSE_LOADING
} from '@/store/shared.module';

/* Define Module Name */
const MODULE_NAME = 'constants/';

/* Define Action Name */
const A_CONSTANTS = 'getConstants';
/* Define Reset State Action Name */
const A_INITIAL_CONSTANTS_STATE = 'initialConstantsState';

/* Define Getter Name */
const G_AVAILABLE_PERMISSIONS = 'availablePermissions';

/* Define Mutation Name */
const M_CONSTANTS = 'setConstants';
/* Define Reset State Mutation Name */
const M_INITIAL_CONSTANTS_STATE = 'setInitialConstantState';

/* Define Export Name */
export const CONSTANTS_GET_CONSTANTS = MODULE_NAME + A_CONSTANTS;
export const CONSTANTS_AVAILABLE_PERMISSIONS =
  MODULE_NAME + G_AVAILABLE_PERMISSIONS;

/* Define Reset State Name */
export const CONSTANTS_INITIAL_CONSTANTS_STATE =
  MODULE_NAME + A_INITIAL_CONSTANTS_STATE;

const state = {
  constants: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {
  project_type_options: () => {
    return (
      JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS)).project_types || []
    ).map((i) => ({
      value: i.id,
      text: i.name
    }));
  },
  agencyCategory: () => {
    return (
      JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS)).agency_category ||
      []
    ).map((i) => ({
      value: i.value,
      description: i.description,
      key: i.key,
      text: i.description
    }));
  },
  advertisementPosition: () => {
    return (
      JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS))
        .advertisement_position || []
    );
  },
  loadingPeriod: () => {
    return (
      JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS)).loading_period || []
    ).map((i) => ({
      value: i.value,
      text: i.description
    }));
  },
  loadingPlatform: () => {
    return (
      JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS)).loading_platform ||
      []
    ).map((i) => ({
      value: i.value,
      text: i.description
    }));
  },
  countries: () => {
    return (
      JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS)).country || []
    ).map((i) => ({
      value: i.id,
      text: i.name
    }));
  },
  currency: () => {
    return (
      JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS)).all_country || []
    ).map((i) => ({
      value: i.currency,
      title: i.currency
    }));
  },
  allCountries: () => {
    return (
      JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS)).all_country || []
    ).map((i) => ({
      ...i,
      value: i.id,
      text: i.name
    }));
  },
  jobOrganizerSortFilter: () => {
    return JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS))
      .job_organizer_sorting_filter;
  },
  applicationManagementApplicationFilter: () => {
    return JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS))
      .application_management_application_filter;
  },
  applicationStatusList: () => {
    return JSON.parse(
      uiHelper.getLocalStorage(LOCAL.CONSTANTS)
    ).application_status.map((i) => ({ ...i, description: i.key }));
  },
  applicationManagementChannelFilter: () => {
    return JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS))
      .application_management_channel_filter;
  },
  genders: () => {
    return JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS)).gender;
  },
  race: () => {
    return JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS)).race.map(
      (i) => ({
        ...i,
        text: i.description
      })
    );
  },
  age: () => {
    return JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS)).age.map(
      (i) => ({
        ...i,
        text: i.description
      })
    );
  },
  height: () => {
    return JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS)).height.map(
      (i) => ({
        ...i,
        text: i.description
      })
    );
  },
  displayInformations: () => {
    const roles = ['agency', 'freelancer', 'talent', 'production_house'];

    const getRoles = (obj) => {
      let showRole = [];
      roles.forEach((x) => {
        if (obj[x]) {
          showRole.push(x);
        }
      });
      return showRole;
    };
    return JSON.parse(
      uiHelper.getLocalStorage(LOCAL.CONSTANTS)
    ).display_informations.map((i) => ({
      showRole: getRoles(i),
      id: i.id,
      key: i.key,
      sorting: i.key,
      label: DISPLAY_INFOS[i.key]
    }));
  },
  flag: () => {
    return JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS))?.flag;
  },
  hair_color: () => {
    return JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS)).hair_color.map(
      (i) => ({
        ...i,
        text: i.description
      })
    );
  },
  mediaCollections: () => {
    return JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS))
      .media_collections;
  },
  rate: () => {
    return JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS)).rate.map(
      (i) => ({
        ...i,
        text: i.description
      })
    );
  },
  hair_type: () => {
    return JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS)).hair_type.map(
      (i) => ({
        ...i,
        text: i.description
      })
    );
  },
  hair_length: () => {
    return JSON.parse(
      uiHelper.getLocalStorage(LOCAL.CONSTANTS)
    ).hair_length.map((i) => ({
      ...i,
      text: i.description
    }));
  },
  eye_color: () => {
    return JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS)).eye_color.map(
      (i) => ({
        ...i,
        text: i.description
      })
    );
  },
  skin_color: () => {
    return JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS)).skin_color.map(
      (i) => ({
        ...i,
        text: i.description.substring(
          i.description.length - 6,
          i.description.length
        )
      })
    );
  },
  languageSpoken: () => {
    return JSON.parse(
      uiHelper.getLocalStorage(LOCAL.CONSTANTS)
    ).language_spoken.map((i) => ({
      ...i,
      text: i.description
    }));
  },
  avatarImageUrl: () => {
    return JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS))
      .avatar_image_url;
  },
  projectRoles: () => {
    return JSON.parse(
      uiHelper.getLocalStorage(LOCAL.CONSTANTS)
    ).project_roles.map((i) => ({
      id: i.id,
      name: i.name,
      value: i.id,
      text: i.name,
      projectTypeIds: i.project_type_ids.map((x) => ({
        id: x.id,
        name: x.name,
        datasetId: x.dataset_id
      }))
    }));
  },

  applicationStatus: () => {
    return Object.assign(
      {},
      ...JSON.parse(
        uiHelper.getLocalStorage(LOCAL.CONSTANTS)
      ).application_status.map((i) => ({ [i.value]: i.description }))
    );
  },
  getAppInfos: () => {
    const keys = {
      ANDROID: 'androidLink',
      IOS: 'iosLink',
      HUAWEI: 'huaweiLink'
    };
    return {
      ...Object.assign(
        {},
        ...(
          (JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS)) || {})
            .app_info || []
        ).map((i) => ({ [keys[i.platform]]: i.url }))
      ),
      qrCodeUrl: window.location.origin + '/' + ROUTE_NAME.SCAN
    };
  }
};

// Actions
const actions = {
  async [A_CONSTANTS]({ dispatch, commit }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    await CONSTANTS.getConstants()
      .then((response) => {
        let result = response;
        commit(M_CONSTANTS, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch(() => {
        commit(M_INITIAL_CONSTANTS_STATE);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_CONSTANTS_STATE]({ commit }) {
    commit(M_INITIAL_CONSTANTS_STATE);
  }
};

// Mutations
const mutations = {
  [M_CONSTANTS](state, result) {
    if (result.code == 200) {
      let data = result.data;

      state.constants = {
        code: result.code,
        complete: true,
        data,
        message: null
      };

      uiHelper.setLocalStorage(
        LOCAL.CONSTANTS,
        JSON.stringify(state.constants.data)
      );
    } else {
      state.constants = {
        code: result.code,
        complete: false,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_INITIAL_CONSTANTS_STATE](state) {
    state.constants = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
