<template>
  <div>
    <div class="row">
      <div class="d-flex align-center col-12">
        <v-text-field
          append-icon="mdi-magnify"
          placeholder="Type something to search"
          v-model="view_model"
          @input="search"
          @click:clear="
            view_model = '';
            search();
          "
          filled
          rounded
          dark
          clearable
        ></v-text-field>
      </div>
    </div>
    <div class="row">
      <div class="d-flex align-center col-6">
        <CustomCountrySelect ref="countrySelect" @onchange="onCountryChange">
          <span style="cursor: pointer" @click="changeLocation">
            <u>Countries</u>
            <v-icon dark>mdi-chevron-down</v-icon>
          </span>
        </CustomCountrySelect>
      </div>
      <div class="d-flex align-center col-6">
        <CustomProjectType ref="projectTypes" @onchange="onProjectTypeChange">
          <span class="projectFilterToggle" @click="changeProjectTypes">
            <u>Project Types</u>
            <v-icon dark>mdi-chevron-down</v-icon>
          </span>
        </CustomProjectType>
      </div>
    </div>
  </div>
</template>

<script>
  import CustomCountrySelect from '../inputs/CustomCountrySelect.vue';
  import CustomProjectType from '../inputs/CustomProjectType.vue';

  export default {
    name: 'AppSearchBar',
    props: {
      search: {
        type: Function,
        default: () => {}
      },
      value: {
        type: String,
        default: ''
      }
    },
    components: { CustomCountrySelect, CustomProjectType },
    data: () => ({
      filterCountry: [],
      filterProjectType: []
    }),
    computed: {
      view_model: {
        get() {
          return this.value;
        },
        set(val) {
          return this.$emit('input', val || '');
        }
      }
    },
    methods: {
      changeLocation() {
        this.$refs.countrySelect.toggle();
      },
      changeProjectTypes() {
        this.$refs.projectTypes.toggle();
      },
      checkFilterOptions(data) {
        return data && Array.isArray(data) && data.length > 0;
      },
      onCountryChange(location) {
        const isValidated = this.checkFilterOptions(location);
        this.filterCountry = isValidated ? location : [];
        this.onSearch();
      },
      onProjectTypeChange(projectTypes) {
        const isValidated = this.checkFilterOptions(projectTypes);
        this.filterProjectType = isValidated ? projectTypes : [];
        this.onSearch();
      },
      onSearch() {
        let options = {};
        if (this.filterCountry.length > 0)
          options.location = this.filterCountry;
        if (this.filterProjectType.length > 0)
          options.projectType = this.filterProjectType;
        this.search(options);
      }
    }
  };
</script>

<style scoped lang="scss"></style>
