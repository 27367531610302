<template>
  <div>
    <template v-if="step == 0">
      <p class="font-weight-light text-lg-h6 text-xl-h5">
        A 6-digit code has been sent via
        {{ formValues.auth_method === 'mobile' ? 'SMS' : 'email' }} to
        {{
          formValues.auth_method === 'mobile'
            ? formValues.mobile_dial_code + '-' + formValues.mobile
            : formValues.email
        }}
      </p>
      <div class="mb-12 mt-5">
        <v-otp-input
          class="otp-input"
          dark
          length="6"
          type="number"
          v-model="otp"
          @finish="verifyOTPAction"
        />
      </div>
    </template>
    <template v-else-if="step == 1">
      <h1 class="text-uppercase">Create Password</h1>
      <p class="font-weight-light text-lg-h6 text-xl-h5">
        Create a high security password
      </p>
      <v-form v-model="validPassword">
        <div class="my-3">
          <AppFormField
            v-model="form.password"
            class="password-input"
            name="password_id"
            :label="$t('label.password')"
            :rules="formValidation.passwordRules(form.password)"
            type="password"
            :allowShowPassword="show.password"
            required
            outlined
            dark
          />
          <AppFormField
            v-model="form.passwordConfirmation"
            class="password-input"
            name="password_id"
            :label="$t('label.passwordConfirmation')"
            :rules="
              formValidation.confirmPasswordRules(
                form.password,
                form.passwordConfirmation
              )
            "
            type="password"
            :allowShowPassword="show.passwordConfirmation"
            required
            outlined
            dark
          />
        </div>
      </v-form>
      <div class="text-center mt-12 pt-4">
        <v-btn class="btn1" @click="createPassword" :disabled="!validPassword"> Create </v-btn>
      </div>
    </template>
    <template v-else-if="step == 2">
      <h2 class="text-uppercase mb-12 mt-2 text-center">
        First time login is completed. I'm ready to log in to meet people.
      </h2>
      <div class="text-center pt-5">
        <v-btn class="btn1" @click="login"> Log In </v-btn>
      </div>
    </template>
  </div>
</template>

<script>
  import {
    AUTH_VERIFY_OTP,
    AUTH_INITIAL_VERIFY_OTP_STATE
  } from '@/store/auth.module';
  import { OTP_TYPE } from '@/constants/enums/otp-type.enum.js';
  import { formValidationHelper } from '@/utils';
  import { AUTH } from '@/api';

  export default {
    name: 'AdminSendOTP',
    props: ['formValues'],
    data() {
      return {
        step: 0,
        otp: null,
        form: {
          password: null,
          passwordConfirmation: null
        },
        show: {
          password: true,
          passwordConfirmation: true
        },
        formValidation: formValidationHelper,
        validPassword: false
      };
    },
    computed: {
      verifyOTPComplete() {
        return this.$store.state.auth.verifyOTP.complete;
      }
    },
    watch: {
      verifyOTPComplete() {
        let response = this.$store.state.auth.verifyOTP;
        if (response.complete) {
          this.verifyOTPCompleteAction(response);
        }
      }
    },
    methods: {
      verifyOTPCompleteAction(response) {
        if (response.code == 200) this.step = 1;
        this.$store.dispatch(AUTH_INITIAL_VERIFY_OTP_STATE);
      },
      verifyOTPAction() {
        const data = {
          auth_method: this.formValues.auth_method,
          mobileDialCode: this.formValues.mobile_dial_code,
          mobile: this.formValues.mobile,
          email: this.formValues.email,
          otpType: OTP_TYPE.REGISTER,
          code: this.otp
        };
        this.$store.dispatch(AUTH_VERIFY_OTP, { data });
      },
      async createPassword() {
        try {
          const data = {
            auth_method: this.formValues.auth_method,
            mobileDialCode: this.formValues.mobile_dial_code,
            mobile: this.formValues.mobile,
            email: this.formValues.email,
            password: this.form.password,
            password_confirmation: this.form.passwordConfirmation
          };
          await AUTH.resetPassword(data);

          this.openAppDialogInfo(
            'success',
            'Password has been created successfully.',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                  this.step = 2;
                }
              }
            ]
          );
        } catch (e) {
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
      },
      login() {
        this.$emit('login', this.form.password);
      }
    }
  };
</script>

<style scoped lang="scss">
  .otp-input {
    ::v-deep .v-input__slot {
      color: #ff2ff2;
      background: transparent !important;
      min-height: 80px;
    }
  }
</style>
