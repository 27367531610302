import { apiService } from '@/services';

export const LOOKUP = {
  getSkills
};

const URL = {
  API: 'api',
  V1: 'v1',
  LOOKUP: 'lookup',
  SKILLS: 'skills'
};

function getSkills() {
  return apiService().get(`/${URL.API}/${URL.V1}/${URL.LOOKUP}/${URL.SKILLS}`);
}
