import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#FF2FF2',
        secondary: '#00F7F7',
        tertiary: '#FB2BCD',
        quaternary: '#F827A2',
        senary: '#F22266',
        septenary: '#ED1C24',
        accent: '#639FCD',
        error: '#ef476f',
        text: '#d9d9d9',
        label: '#b6b9b8',
        title: '#808080',
        title2: '#848484',
        disabled: '#747474',
        divider: '#707070',
        background: '#000000',
        surface: '#ffffff'
      }
    },
    options: { customProperties: true }
  },
  breakpoint: {
    mobileBreakpoint: 'sm' // This is equivalent to a value of 960
  }
});
