import { PROFILE } from '@/api';
import { apiHelper } from '@/utils';
import {
  SHARED_OPEN_LOADING,
  SHARED_CLOSE_LOADING
} from '@/store/shared.module';

/* Define Module Name */
const MODULE_NAME = 'profile/';

/* Define Action Name */
const A_UPDATE_DISPLAY_INFORMATION = 'updateDisplayInformation';
const A_GET_BLOCKED_USERS = 'getBlockedUsers';
const A_GET_ADMIN_LIST = 'getAdminList';
const A_RESEND_INVITATION = 'resendInvitation';
const A_REMOVE_ADMIN = 'removeAdmin';
const A_CREATE_ADMIN = 'createAdmin';
const A_TRANSFER_OWNERSHIP = 'transferOwnership';
const A_GET_LINKED_AGENCY = 'getLinkedAgency';
const A_UPDATE_BIO_INFO = 'updateBioInfo';
const A_UPDATE_INTEREST = 'updateInterest';
const A_UPDATE_GUARDIAN_INFO = 'updateGuardianInfo';
const A_GET_PENDING_TALENTS = 'getPendingTalents';
const A_CREATE_AGENCY_LINK_REQUEST = 'createAgencyLinkRequest';
const A_GET_AGENCY = 'getAgency';
const A_GET_AGENCY_PENDING_REQUEST = 'getAgencyPendingRequest';
const A_APPROVE_AGENCY_LINK_REQUEST = 'approveAgencyLinkRequest';
const A_REJECT_AGENCY_LINK_REQUEST = 'rejectAgencyLinkRequest';
const A_GET_UNLINKED_TALENTS = 'getUnlinkedTalents';
const A_CREATE_TALENT_LINK_REQUEST = 'createTalentLinkRequest';

/* Define Reset State Action Name */
const A_INITIAL_UPDATE_DISPLAY_INFORMATION_STATE =
  'initialUpdateDisplayInformationState';
const A_INITIAL_GET_BLOCKED_USERS_STATE = 'initialGetBlockedUsersState';
const A_INITIAL_GET_ADMIN_LIST_STATE = 'initialGetAdminListState';
const A_INITIAL_RESEND_INVITATION_STATE = 'initialResetInvitationState';
const A_INITIAL_REMOVE_ADMIN_STATE = 'initialRemoveAdminState';
const A_INITIAL_CREATE_ADMIN_STATE = 'initialCreateAdminState';
const A_INITIAL_TRANSFER_OWNERSHIP_STATE = 'initialTransferOwnershipState';
const A_INITIAL_GET_LINKED_AGENCY_STATE = 'initialGetLinkedAgencyState';
const A_INITIAL_UPDATE_BIO_INFO_STATE = 'initialUpdateBioInfoState';
const A_INITIAL_UPDATE_INTEREST_STATE = 'initialUpdateInterestState';
const A_INITIAL_UPDATE_GUARDIAN_INFO_STATE = 'initialUpdateGuardianInfoState';
const A_INITIAL_GET_PENDING_TALENTS_STATE = 'initialGetPendingTalentsState';
const A_INITIAL_CREATE_AGENCY_LINK_REQUEST_STATE =
  'initialCreateAgencyLinkRequestState';
const A_INITIAL_GET_AGENCY_STATE = 'initialGetAgencyState';
const A_INITIAL_GET_AGENCY_PENDING_REQUEST_STATE =
  'initialGetAgencyPendingRequestState';
const A_INITIAL_APPROVE_AGENCY_LINK_REQUEST_STATE =
  'initialApproveAgencyLinkRequestState';
const A_INITIAL_REJECT_AGENCY_LINK_REQUEST_STATE =
  'initialRejectAgencyLinkRequestState';
const A_INITIAL_GET_UNLINKED_TALENTS_STATE = 'initialGetUnlinkedTalentsState';
const A_INITIAL_CREATE_TALENT_LINK_REQUEST_STATE =
  'initialCreateTalentLinkRequestState';

/* Define Mutation Name */
const M_UPDATE_DISPLAY_INFORMATION = 'setUpdateDisplayInformation';
const M_GET_BLOCKED_USERS = 'setGetBlockedUsers';
const M_GET_ADMIN_LIST = 'setGetAdminList';
const M_RESEND_INVITATION = 'setResetInvitation';
const M_REMOVE_ADMIN = 'setRemoveAdmin';
const M_CREATE_ADMIN = 'setCreateAdmin';
const M_TRANSFER_OWNERSHIP = 'setTransferOwnership';
const M_GET_LINKED_AGENCY = 'setGetLinkedAgency';
const M_UPDATE_BIO_INFO = 'setUpdateBioInfo';
const M_UPDATE_INTEREST = 'setUpdateInterest';
const M_UPDATE_GUARDIAN_INFO = 'setUpdateGuardianInfo';
const M_GET_PENDING_TALENTS = 'setGetPendingTalents';
const M_CREATE_AGENCY_LINK_REQUEST = 'setCreateAgencyLinkRequest';
const M_GET_AGENCY = 'setGetAgency';
const M_GET_AGENCY_PENDING_REQUEST = 'setGetAgencyPendingRequest';
const M_APPROVE_AGENCY_LINK_REQUEST = 'setApproveAgencyLinkRequest';
const M_REJECT_AGENCY_LINK_REQUEST = 'setRejectAgencyLinkRequest';
const M_GET_UNLINKED_TALENTS = 'setGetUnlinkedTalents';
const M_CREATE_TALENT_LINK_REQUEST = 'setCreateTalentLinkRequest';

/* Define Reset State Mutation Name */
const M_INITIAL_UPDATE_DISPLAY_INFORMATION_STATE =
  'setInitialUpdateDisplayInformationState';
const M_INITIAL_GET_BLOCKED_USERS_STATE = 'setInitialGetBlockedUsersState';
const M_INITIAL_GET_ADMIN_LIST_STATE = 'setInitialGetAdminListState';
const M_INITIAL_RESEND_INVITATION_STATE = 'setInitialResetInvitationState';
const M_INITIAL_REMOVE_ADMIN_STATE = 'setInitialRemoveAdminState';
const M_INITIAL_CREATE_ADMIN_STATE = 'setInitialCreateAdminState';
const M_INITIAL_TRANSFER_OWNERSHIP_STATE = 'setInitialTransferOwnershipState';
const M_INITIAL_GET_LINKED_AGENCY_STATE = 'setInitialGetLinkedAgencyState';
const M_INITIAL_UPDATE_BIO_INFO_STATE = 'setInitialUpdateBioInfoState';
const M_INITIAL_UPDATE_INTEREST_STATE = 'setInitialUpdateInterestState';
const M_INITIAL_UPDATE_GUARDIAN_INFO_STATE = 'setInitialUpdateGuardianInfoState';
const M_INITIAL_GET_PENDING_TALENTS_STATE = 'setInitialGetPendingTalentsState';
const M_INITIAL_CREATE_AGENCY_LINK_REQUEST_STATE =
  'setInitialCreateAgencyLinkRequestState';
const M_INITIAL_GET_AGENCY_STATE = 'setInitialGetAgencyState';
const M_INITIAL_GET_AGENCY_PENDING_REQUEST_STATE =
  'setInitialGetAgencyPendingRequestState';
const M_INITIAL_APPROVE_AGENCY_LINK_REQUEST_STATE =
  'setInitialApproveAgencyLinkRequestState';
const M_INITIAL_REJECT_AGENCY_LINK_REQUEST_STATE =
  'setInitialRejectAgencyLinkRequestState';
const M_INITIAL_GET_UNLINKED_TALENTS_STATE =
  'setInitialGetUnlinkedTalentsState';
const M_INITIAL_CREATE_TALENT_LINK_REQUEST_STATE =
  'setInitialCreateTalentLinkRequestState';

/* Define Export Name */
export const PROFILE_UPDATE_DISPLAY_INFORMATION =
  MODULE_NAME + A_UPDATE_DISPLAY_INFORMATION;
export const PROFILE_GET_BLOCKED_USERS = MODULE_NAME + A_GET_BLOCKED_USERS;
export const PROFILE_GET_ADMIN_LIST = MODULE_NAME + A_GET_ADMIN_LIST;
export const PROFILE_RESEND_INVITATION = MODULE_NAME + A_RESEND_INVITATION;
export const PROFILE_REMOVE_ADMIN = MODULE_NAME + A_REMOVE_ADMIN;
export const PROFILE_CREATE_ADMIN = MODULE_NAME + A_CREATE_ADMIN;
export const PROFILE_TRANSFER_OWNERSHIP = MODULE_NAME + A_TRANSFER_OWNERSHIP;
export const PROFILE_GET_LINKED_AGENCY = MODULE_NAME + A_GET_LINKED_AGENCY;
export const PROFILE_UPDATE_BIO_INFO = MODULE_NAME + A_UPDATE_BIO_INFO;
export const PROFILE_UPDATE_INTEREST = MODULE_NAME + A_UPDATE_INTEREST;
export const PROFILE_UPDATE_GUARDIAN_INFO = MODULE_NAME + A_UPDATE_GUARDIAN_INFO;
export const PROFILE_GET_PENDING_TALENTS = MODULE_NAME + A_GET_PENDING_TALENTS;
export const PROFILE_CREATE_AGENCY_LINK_REQUEST =
  MODULE_NAME + A_CREATE_AGENCY_LINK_REQUEST;
export const PROFILE_GET_AGENCY = MODULE_NAME + A_GET_AGENCY;
export const PROFILE_GET_AGENCY_PENDING_REQUEST =
  MODULE_NAME + A_GET_AGENCY_PENDING_REQUEST;
export const PROFILE_APPROVE_AGENCY_LINK_REQUEST =
  MODULE_NAME + A_APPROVE_AGENCY_LINK_REQUEST;
export const PROFILE_REJECT_AGENCY_LINK_REQUEST =
  MODULE_NAME + A_REJECT_AGENCY_LINK_REQUEST;
export const PROFILE_GET_UNLINKED_TALENTS =
  MODULE_NAME + A_GET_UNLINKED_TALENTS;
export const PROFILE_CREATE_TALENT_LINK_REQUEST =
  MODULE_NAME + A_CREATE_TALENT_LINK_REQUEST;

/* Define Reset State Name */
export const PROFILE_INITIAL_UPDATE_DISPLAY_INFORMATION_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_DISPLAY_INFORMATION_STATE;
export const PROFILE_INITIAL_GET_BLOCKED_USERS_STATE =
  MODULE_NAME + A_INITIAL_GET_BLOCKED_USERS_STATE;
export const PROFILE_INITIAL_GET_ADMIN_LIST_STATE =
  MODULE_NAME + A_INITIAL_GET_ADMIN_LIST_STATE;
export const PROFILE_INITIAL_RESEND_INVITATION_STATE =
  MODULE_NAME + A_INITIAL_RESEND_INVITATION_STATE;
export const PROFILE_INITIAL_REMOVE_ADMIN_STATE =
  MODULE_NAME + A_INITIAL_REMOVE_ADMIN_STATE;
export const PROFILE_INITIAL_CREATE_ADMIN_STATE =
  MODULE_NAME + A_INITIAL_CREATE_ADMIN_STATE;
export const PROFILE_INITIAL_TRANSFER_OWNERSHIP_STATE =
  MODULE_NAME + A_INITIAL_TRANSFER_OWNERSHIP_STATE;
export const PROFILE_INITIAL_GET_LINKED_AGENCY_STATE =
  MODULE_NAME + A_INITIAL_GET_LINKED_AGENCY_STATE;
export const PROFILE_INITIAL_UPDATE_BIO_INFO_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_BIO_INFO_STATE;
export const PROFILE_INITIAL_UPDATE_INTEREST_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_INTEREST_STATE;
export const PROFILE_INITIAL_UPDATE_GUARDIAN_INFO_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_GUARDIAN_INFO_STATE;
export const PROFILE_INITIAL_GET_PENDING_TALENTS_STATE =
  MODULE_NAME + A_INITIAL_GET_PENDING_TALENTS_STATE;
export const PROFILE_INITIAL_CREATE_AGENCY_LINK_REQUEST_STATE =
  MODULE_NAME + A_INITIAL_CREATE_AGENCY_LINK_REQUEST_STATE;
export const PROFILE_INITIAL_GET_AGENCY_STATE =
  MODULE_NAME + A_INITIAL_GET_AGENCY_STATE;
export const PROFILE_INITIAL_GET_AGENCY_PENDING_REQUEST_STATE =
  MODULE_NAME + A_INITIAL_GET_AGENCY_PENDING_REQUEST_STATE;
export const PROFILE_INITIAL_APPROVE_AGENCY_LINK_REQUEST_STATE =
  MODULE_NAME + A_INITIAL_APPROVE_AGENCY_LINK_REQUEST_STATE;
export const PROFILE_INITIAL_REJECT_AGENCY_LINK_REQUEST_STATE =
  MODULE_NAME + A_INITIAL_REJECT_AGENCY_LINK_REQUEST_STATE;
export const PROFILE_INITIAL_GET_UNLINKED_TALENTS_STATE =
  MODULE_NAME + A_INITIAL_GET_UNLINKED_TALENTS_STATE;
export const PROFILE_INITIAL_CREATE_TALENT_LINK_REQUEST_STATE =
  MODULE_NAME + A_INITIAL_CREATE_TALENT_LINK_REQUEST_STATE;

//State
const state = {
  updateDisplayInformation: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  blockedUsers: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  adminList: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  resendInvitation: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  removeAdmin: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  createAdmin: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  transferOwnership: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  linkedAgency: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  agencyPendingRequest: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  updateBioInfo: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  updateInterest: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  updateGuardianInfo: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  getPendingTalents: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  createAgencyLinkRequest: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  createTalentLinkRequest: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  agency: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  approveAgencyLinkRequest: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  rejectAgencyLinkRequest: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  unlinkedTalents: {
    code: 0,
    complete: false,
    data: {},
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_UPDATE_DISPLAY_INFORMATION]({ dispatch, commit }, { data }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PROFILE.updateDisplayInformation(data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_DISPLAY_INFORMATION, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_DISPLAY_INFORMATION, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_BLOCKED_USERS]({ dispatch, commit }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PROFILE.getBlockedUsers()
      .then((response) => {
        result = response;
        commit(M_GET_BLOCKED_USERS, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_BLOCKED_USERS, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_ADMIN_LIST]({ dispatch, commit }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PROFILE.getAdminList()
      .then((response) => {
        result = response;
        commit(M_GET_ADMIN_LIST, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_ADMIN_LIST, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_RESEND_INVITATION]({ dispatch, commit }, { data }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PROFILE.resendInvitation(data)
      .then((response) => {
        result = response;
        commit(M_RESEND_INVITATION, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_RESEND_INVITATION, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_REMOVE_ADMIN]({ dispatch, commit }, { data }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PROFILE.removeAdmin(data)
      .then((response) => {
        result = response;
        commit(M_REMOVE_ADMIN, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_REMOVE_ADMIN, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_CREATE_ADMIN]({ dispatch, commit }, { data }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PROFILE.createAdmin(data)
      .then((response) => {
        result = response;
        commit(M_CREATE_ADMIN, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_CREATE_ADMIN, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_TRANSFER_OWNERSHIP]({ dispatch, commit }, { id }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PROFILE.transferOwnership(id)
      .then((response) => {
        result = response;
        commit(M_TRANSFER_OWNERSHIP, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_TRANSFER_OWNERSHIP, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_LINKED_AGENCY]({ dispatch, commit }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PROFILE.getLinkedAgency()
      .then((response) => {
        result = response;
        commit(M_GET_LINKED_AGENCY, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_LINKED_AGENCY, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_BIO_INFO]({ dispatch, commit }, { data }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PROFILE.updateBioInfo(data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_BIO_INFO, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_BIO_INFO, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_INTEREST]({ dispatch, commit }, { data }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PROFILE.updateInterest(data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_INTEREST, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_INTEREST, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_GUARDIAN_INFO]({ dispatch, commit }, { data }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PROFILE.updateGuardianInfo(data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_GUARDIAN_INFO, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_GUARDIAN_INFO, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_GET_PENDING_TALENTS]({ dispatch, commit }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await PROFILE.getPendingTalents()
      .then((response) => {
        result = response;
        commit(M_GET_PENDING_TALENTS, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_PENDING_TALENTS, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_CREATE_AGENCY_LINK_REQUEST]({ dispatch, commit }, { data }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await PROFILE.createAgencyLinkRequest(data)
      .then((response) => {
        result = response;
        commit(M_CREATE_AGENCY_LINK_REQUEST, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_CREATE_AGENCY_LINK_REQUEST, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_GET_AGENCY]({ dispatch, commit }, { query }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await PROFILE.getAgency(query)
      .then((response) => {
        result = response;
        commit(M_GET_AGENCY, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_AGENCY, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_GET_AGENCY_PENDING_REQUEST]({ dispatch, commit }, { query }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await PROFILE.getAgencyPendingRequest(query)
      .then((response) => {
        result = response;
        commit(M_GET_AGENCY_PENDING_REQUEST, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_AGENCY_PENDING_REQUEST, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_APPROVE_AGENCY_LINK_REQUEST]({ dispatch, commit }, { data }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await PROFILE.approveAgencyLinkRequest(data)
      .then((response) => {
        result = response;
        commit(M_APPROVE_AGENCY_LINK_REQUEST, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_APPROVE_AGENCY_LINK_REQUEST, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_REJECT_AGENCY_LINK_REQUEST]({ dispatch, commit }, { data }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await PROFILE.rejectAgencyLinkRequest(data)
      .then((response) => {
        result = response;
        commit(M_REJECT_AGENCY_LINK_REQUEST, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_REJECT_AGENCY_LINK_REQUEST, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_GET_UNLINKED_TALENTS]({ dispatch, commit }, { query, firstLoad }) {
    if (firstLoad) dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await PROFILE.getUnlinkedTalents(query)
      .then((response) => {
        result = response;
        commit(M_GET_UNLINKED_TALENTS, result);
        if (firstLoad)
          dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_REJECT_AGENCY_LINK_REQUEST, result);
        if (firstLoad)
          dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_CREATE_TALENT_LINK_REQUEST]({ dispatch, commit }, { data }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await PROFILE.createTalentLinkRequest(data)
      .then((response) => {
        result = response;
        commit(M_CREATE_TALENT_LINK_REQUEST, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_CREATE_TALENT_LINK_REQUEST, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },

  [A_INITIAL_UPDATE_DISPLAY_INFORMATION_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_DISPLAY_INFORMATION_STATE);
  },
  [A_INITIAL_GET_BLOCKED_USERS_STATE]({ commit }) {
    commit(M_INITIAL_GET_BLOCKED_USERS_STATE);
  },
  [A_INITIAL_GET_ADMIN_LIST_STATE]({ commit }) {
    commit(M_INITIAL_GET_ADMIN_LIST_STATE);
  },
  [A_INITIAL_RESEND_INVITATION_STATE]({ commit }) {
    commit(M_INITIAL_RESEND_INVITATION_STATE);
  },
  [A_INITIAL_REMOVE_ADMIN_STATE]({ commit }) {
    commit(M_INITIAL_REMOVE_ADMIN_STATE);
  },
  [A_INITIAL_CREATE_ADMIN_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_ADMIN_STATE);
  },
  [A_INITIAL_TRANSFER_OWNERSHIP_STATE]({ commit }) {
    commit(M_INITIAL_TRANSFER_OWNERSHIP_STATE);
  },
  [A_INITIAL_GET_LINKED_AGENCY_STATE]({ commit }) {
    commit(M_INITIAL_GET_LINKED_AGENCY_STATE);
  },
  [A_INITIAL_UPDATE_BIO_INFO_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_BIO_INFO_STATE);
  },
  [A_INITIAL_UPDATE_INTEREST_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_INTEREST_STATE);
  },
  [A_INITIAL_UPDATE_GUARDIAN_INFO_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_GUARDIAN_INFO_STATE);
  },
  [A_INITIAL_GET_PENDING_TALENTS_STATE]({ commit }) {
    commit(M_INITIAL_GET_PENDING_TALENTS_STATE);
  },
  [A_INITIAL_CREATE_AGENCY_LINK_REQUEST_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_AGENCY_LINK_REQUEST_STATE);
  },
  [A_INITIAL_GET_AGENCY_STATE]({ commit }) {
    commit(M_INITIAL_GET_AGENCY_STATE);
  },
  [A_INITIAL_GET_AGENCY_PENDING_REQUEST_STATE]({ commit }) {
    commit(M_INITIAL_GET_AGENCY_PENDING_REQUEST_STATE);
  },
  [A_INITIAL_APPROVE_AGENCY_LINK_REQUEST_STATE]({ commit }) {
    commit(M_INITIAL_APPROVE_AGENCY_LINK_REQUEST_STATE);
  },
  [A_INITIAL_REJECT_AGENCY_LINK_REQUEST_STATE]({ commit }) {
    commit(M_INITIAL_REJECT_AGENCY_LINK_REQUEST_STATE);
  },
  [A_INITIAL_GET_UNLINKED_TALENTS_STATE]({ commit }) {
    commit(M_INITIAL_GET_UNLINKED_TALENTS_STATE);
  },
  [A_INITIAL_CREATE_TALENT_LINK_REQUEST_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_TALENT_LINK_REQUEST_STATE);
  }
};

// Mutations
const mutations = {
  [M_UPDATE_DISPLAY_INFORMATION](state, result) {
    state.updateDisplayInformation = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_GET_BLOCKED_USERS](state, result) {
    state.blockedUsers = {
      code: result.code,
      complete: true,
      data: result.data,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_GET_ADMIN_LIST](state, result) {
    state.adminList = {
      code: result.code,
      complete: true,
      data: result.data,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_RESEND_INVITATION](state, result) {
    state.resendInvitation = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_REMOVE_ADMIN](state, result) {
    state.removeAdmin = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_CREATE_ADMIN](state, result) {
    state.createAdmin = {
      code: result.code,
      complete: true,
      data: null,
      message: result.code == 200 ? null : apiHelper.sortOutMessage(result.error_message)
    };
  },
  [M_TRANSFER_OWNERSHIP](state, result) {
    state.transferOwnership = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_GET_LINKED_AGENCY](state, result) {
    state.linkedAgency = {
      code: result.code,
      complete: true,
      data: result.data,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_BIO_INFO](state, result) {
    state.updateBioInfo = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_INTEREST](state, result) {
    state.updateInterest = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_GUARDIAN_INFO](state, result) {
    state.updateGuardianInfo = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_GET_PENDING_TALENTS](state, result) {
    state.getPendingTalents = {
      code: result.code,
      complete: true,
      data: result.data,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_CREATE_AGENCY_LINK_REQUEST](state, result) {
    state.createAgencyLinkRequest = {
      code: result.code,
      complete: true,
      data: result.data,
      message: apiHelper.sortOutMessage(result.message || result.error_message)
    };
  },
  [M_GET_AGENCY](state, result) {
    state.agency = {
      code: result.code,
      complete: true,
      data: result.data.map((x) => {
        return {
          agencyCategory: x.agency_category,
          agencyId: x.agency_id,
          companyCode: x.company_code,
          companyName: x.company_name
        };
      }),
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_GET_AGENCY_PENDING_REQUEST](state, result) {
    state.agencyPendingRequest = {
      code: result.code,
      complete: true,
      data: result.data,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_APPROVE_AGENCY_LINK_REQUEST](state, result) {
    state.approveAgencyLinkRequest = {
      code: result.code,
      complete: true,
      data: result.data,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_REJECT_AGENCY_LINK_REQUEST](state, result) {
    state.rejectAgencyLinkRequest = {
      code: result.code,
      complete: true,
      data: result.data,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_GET_UNLINKED_TALENTS](state, result) {
    state.unlinkedTalents = {
      code: result.code,
      complete: true,
      data: result.data,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_CREATE_TALENT_LINK_REQUEST](state, result) {
    state.createTalentLinkRequest = {
      code: result.code,
      complete: true,
      data: result.data,
      message: apiHelper.sortOutMessage(result.message)
    };
  },

  [M_INITIAL_UPDATE_DISPLAY_INFORMATION_STATE](state) {
    let data = state.updateDisplayInformation.data;

    state.updateDisplayInformation = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_GET_BLOCKED_USERS_STATE](state) {
    let data = state.blockedUsers.data;

    state.blockedUsers = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_GET_ADMIN_LIST_STATE](state) {
    let data = state.adminList.data;

    state.adminList = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_RESEND_INVITATION_STATE](state) {
    let data = state.resendInvitation.data;

    state.resendInvitation = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_REMOVE_ADMIN_STATE](state) {
    let data = state.removeAdmin.data;

    state.removeAdmin = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_CREATE_ADMIN_STATE](state) {
    let data = state.createAdmin.data;

    state.createAdmin = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_TRANSFER_OWNERSHIP_STATE](state) {
    let data = state.transferOwnership.data;

    state.transferOwnership = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_GET_LINKED_AGENCY_STATE](state) {
    let data = state.linkedAgency.data;

    state.linkedAgency = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_UPDATE_BIO_INFO_STATE](state) {
    let data = state.updateBioInfo.data;

    state.updateBioInfo = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_UPDATE_INTEREST_STATE](state) {
    let data = state.updateInterest.data;

    state.updateInterest = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_UPDATE_GUARDIAN_INFO_STATE](state) {
    let data = state.updateGuardianInfo.data;

    state.updateGuardianInfo = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_GET_PENDING_TALENTS_STATE](state) {
    let data = state.getPendingTalents.data;

    state.getPendingTalents = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_CREATE_AGENCY_LINK_REQUEST_STATE](state) {
    let data = state.createAgencyLinkRequest.data;

    state.createAgencyLinkRequest = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_GET_AGENCY_STATE](state) {
    let data = state.agency.data;

    state.agency = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_GET_AGENCY_PENDING_REQUEST_STATE](state) {
    let data = state.agencyPendingRequest.data;

    state.agencyPendingRequest = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_APPROVE_AGENCY_LINK_REQUEST_STATE](state) {
    let data = state.approveAgencyLinkRequest.data;

    state.approveAgencyLinkRequest = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_REJECT_AGENCY_LINK_REQUEST_STATE](state) {
    let data = state.rejectAgencyLinkRequest.data;

    state.rejectAgencyLinkRequest = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_GET_UNLINKED_TALENTS_STATE](state) {
    let data = state.unlinkedTalents.data;

    state.unlinkedTalents = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_CREATE_TALENT_LINK_REQUEST_STATE](state) {
    let data = state.createTalentLinkRequest.data;

    state.createTalentLinkRequest = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
