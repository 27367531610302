import { apiService, getQueryParamString } from '@/services';

export const NEWSROOM = {
  getNewsList
};

const URL = {
  API: 'api',
  V1: 'v1',
  NEWSROOM: 'newsroom',
  NEWS_LIST: 'news_list'
};

function getNewsList(page = 1) {
  const queryString = getQueryParamString({ page });

  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.NEWSROOM}/${
      URL.NEWS_LIST
    }?${queryString.toString()}`
  );
}
