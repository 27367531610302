<template>
  <div class="app-progress-sheet_wrapper">
    <v-bottom-sheet
      v-model="show"
      ref="sheet"
      inset
      hide-overlay
      :max-width="$isMobile() ? '100%' : '40%'"
      content-class="app-progress-sheet"
      persistent
      dark
      eager
      :retain-focus="false"
    >
      <v-sheet
        class="px-4 progress-sheet"
      >
        <v-expansion-panels flat multiple v-model="panel">
           <v-expansion-panel>
            <v-expansion-panel-header expand-icon="mdi-chevron-up">
              <div class="order-last" v-if="allCompressedComplete">
                <v-icon @click="show = !show">
                  mdi-window-close
                </v-icon>
              </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="accordion-content">
                <div class="sheet-content">
                  <div class="row no-gutters mb-2" v-for="item in items" :key="item.id">
                    <div class="col-1">
                      <v-icon dark>
                        mdi-file-video
                      </v-icon>
                    </div>
                    <div class="col-11">
                      <div>
                        {{item.file.name}}
                      </div>
                      <div class="d-flex align-center">
                        <template v-if="item.percentage != 100 && ['compressing'].includes(item.status)">
                          <v-progress-linear 
                            :value="item.percentage" 
                            class="mr-2"
                          ></v-progress-linear>
                          <div style="min-width: 70px">{{ item.percentage.toFixed(2) }}%</div>
                        </template>
                        <span class="grey--text" v-else-if="item.status == 'pending'">Pending...</span>
                        <span class="grey--text text-capitalize" v-else-if="item.status == 'failed'">{{ item.status }}{{ item.message ? `: ${item.message}` : ''}}</span>
                        <span class="grey--text text-capitalize" v-else>{{ item.status }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { SHARED_CLEAR_PUSH_VIDEO_TO_COMPRESS } from '@/store/shared.module';
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg/dist/ffmpeg.min.js';
import { APPLICATION_MANAGEMENT, PROFILE } from '@/api';
import { bus } from '@/main.js';

// import uploadMediaMixin from '@/mixin/upload-media.mixin';

export default {
  name: 'AppProgressSnackbar',
  // mixins: [uploadMediaMixin],
  data() {
    return {
      show: false,
      items: [],
      currentPointer: 0,
      panel: [0]
    }
  },
  computed: {
    compressVideoState() {
      return this.$store.state.shared.pushVideoToCompress;
    },
    itemsLength() {
      return this.items?.length;
    },
    allCompressedComplete() {
      if(!this.itemsLength) return false;
      return this.items?.every((x) => ['completed', 'failed'].includes(x.status));
    }
  },
  watch: {
    show() {
      this.$nextTick(() => {
        this.$refs.sheet.showScroll();
        // either set :retain-focus="false" above or do this:
        // this.$nextTick(() => this.$refs.sheet.unbind());
      });
    },
    compressVideoState(val) {
      if(val) {
        if(val?.file && Array.isArray(val.file)) {
          val?.file?.map((x, index) => {
            if(!x.id) this.items.push({
              file: x.file, 
              id: `p-${val?.extra?.mediaId}-${index}`,
              percentage: 0, 
              status: 'pending', 
              extra: val?.extra
            })
          })
        }
        else {
          this.items.push({...val, percentage: 0, status: 'pending'});
        }
      }
      this.$store.dispatch(SHARED_CLEAR_PUSH_VIDEO_TO_COMPRESS);
    },
    itemsLength(cur, prev) {
      this.show = true;
      if(prev == 0) this.processVideo(this.items[this.currentPointer]);
    },
    allCompressedComplete(cur, prev) {
      console.log(cur, prev)
      if(prev && !cur) {
        this.processVideo(this.items[this.currentPointer]);
      }
    }
  },
  methods: {
    async compressFile(value) {
      const ffmpeg = await createFFmpeg({
        log: true,
        progress: async (params) => {
          const percentage = params.ratio * 100;
          this.items[this.currentPointer].percentage = percentage;
        }
      });

      const transcode = async (file) => {
        const { name } = file;
        await ffmpeg.load();
        ffmpeg.FS('writeFile', name, await fetchFile(file));
        // await ffmpeg.run('-i', name, '-vcodec', 'libx264', '-crf 24', '-preset ultrafast', 'output9.mp4');
        await ffmpeg.run(
          '-i',
          name,
          // '-t', // reduce video time to speed up compress time
          // '5', // reduce video time
          '-ss',
          '2.0',
          '-f',
          'mp4',
          'output9.mp4'
        );
        const data = await ffmpeg.FS('readFile', 'output9.mp4');

        // // Do something with the processed video data, e.g., download it
        if (data) {
          const compressed = new File([data.buffer], name, {
            type: 'video/mp4'
          });
          return compressed;
        }
      };

      // transcode(e);
      let result = await transcode(value.file);
      this.$resetCompressVideoPercentage();
      if (typeof result.name == 'string') {
        result = { file: result, complete: true };
        return result;
      } else {
        result = { file: null, complete: false };
        return result;
      }
    },
    async processVideo(values) {
      const process = (value) => {
        return new Promise((resolve) => {
          // Simulating an asynchronous operation with setTimeout
          setTimeout(() => {
            // Your function logic for each value goes here
  
            // Simulating the condition where the function returns true
            resolve(
              this.compressFile(value)
            );
          }, 2000);
        });
      }
      const processValues = async () => {
          const value = { ...values };
          let item = this.items[this.currentPointer];
          item.status = 'compressing';
          const result = await process(value);
          if (result.complete) {
            // Handle the case where the function return true, return data need to push in array to continue API

            // Handle API when compressed file complete
            console.log('call API');
            
            let data = null;
            
            if(item?.extra?.applicationId) {
              data = await this.uploadVideo(result.file);
            } else if (item?.extra?.mediaId) {
              data = await this.uploadProfileVideo(result.file);
            }
            if(data) {
              this.currentPointer++;
              if (this.currentPointer <= this.itemsLength - 1) {
                this.processVideo(this.items[this.currentPointer]);
              }
            }
            
          } else {
            // Handle the case where the function didn't return true
            console.log(
              'Function did not return true for value :',
              result.complete
            );
          }
      };
      await processValues();
    },

    async uploadVideo(file) {
      let item = this.items[this.currentPointer];
      item.status = 'uploading';
      await APPLICATION_MANAGEMENT.uploadVideo(item.extra.applicationId, {
        video_file: file,
        talent_uid: item.extra.talentUid
      }).then(() => {
        item.status = 'completed';
        bus.$emit('refreshAM');
      }).catch((e) => {
        console.error(e);
        item.status = 'failed';
        item.message = e.error_message || '';
      });
      return true;
    },
    async uploadProfileVideo(file) {
      let item = this.items[this.currentPointer];
      item.status = 'uploading';
      await PROFILE.uploadFile(
        item?.extra?.profileId,
        { file },
        item?.extra?.mediaId
      ).then(() => {
        item.status = 'completed';
        bus.$emit('refreshProfile');
      }).catch((e) => {
        console.error(e);
        item.status = 'failed';
        item.message = e.error_message || '';
      });
      return true;
    },
  },

}
</script>

<style scoped lang="scss">
  ::v-deep.v-dialog__content{
    justify-content: start;
  }

  ::v-deep .v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
    flex: none;
  }

  .item-wrapper {
    display: flex;
    align-items: center;
  }

  td.col_video-icon {
    width: 50px;
  }
  .progress-sheet.v-sheet {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }
  .progress-sheet.v-sheet .sheet-content {
    overflow-y: auto;
  }

  .v-expansion-panel-content.accordion-content {
    max-height: 200px;
    overflow-y: auto;
  }
</style>