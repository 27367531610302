<template>
  <span class="projectFilter">
    <slot></slot>

    <v-select
      v-model="selectedProjectIds"
      id="selectproject"
      name="project_id"
      ref="projectTypesInput"
      label="test"
      type="select"
      :items="projectList"
      :multiple="true"
      :disabled="false"
      item-color="background"
      color="surface"
      outlined
      dark
      @change="onInputChange"
    >
      <template v-slot:prepend-item>
        <v-list-item>
          <v-text-field
            v-model="searchTerm"
            placeholder="Search"
            @input="searchSelect"
          ></v-text-field>
        </v-list-item>
        <v-divider class="my-2"></v-divider>
        <v-list-item ripple @mousedown.prevent @click="toggleSelectAll">
          <v-list-item-action>
            <v-icon style="color: unset">
              {{ icon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> Select All </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
    </v-select>
  </span>
</template>

<script>
  import { defineComponent } from 'vue';
  import { mapGetters } from 'vuex';
  import _ from 'lodash';

  export default defineComponent({
    name: 'CustomProjectSelect',
    data: () => ({
      projectList: [],

      isProjectAll: false,
      selectedProjectIds: [],
      toggleProjectFilter: false,

      searchTerm: ''
    }),
    computed: {
      ...mapGetters({
        project_type_options: 'constants/project_type_options'
      }),
      selectedAll() {
        return this.selectedProjectIds.length === this.projectList.length;
      },
      someSelected() {
        return this.selectedProjectIds.length > 0 && !this.selectedAll;
      },
      icon() {
        if (this.selectedAll) return 'mdi-close-box';
        if (this.someSelected) return 'mdi-minus-box';
        return 'mdi-checkbox-blank-outline';
      }
    },
    mounted() {
      this.projectList = this.project_type_options;
    },
    methods: {
      toggle() {
        document.getElementById('selectproject').click();
        // this.toggleProjectFilter = !this.toggleProjectFilter;
        // this.$refs.projectTypesInput.isMenuActive = this.toggleProjectFilter;
      },
      toggleSelectAll() {
        this.isProjectAll = !this.isProjectAll;

        this.selectedProjectIds = this.isProjectAll ? this.projectList : [];
        this.onInputChange();
      },
      onInputChange() {
        const selectedArray = this.selectedProjectIds.map((i) =>
          i.value ? i.value : i
        );
        this.$emit('onchange', selectedArray);
      },
      searchSelect() {
        this.projectList = (this.project_type_options || []).filter((i) =>
          _.includes(i.text.toLowerCase(), this.searchTerm.toLowerCase())
        );

        return this.projectList;
      }
    }
  });
</script>

<style scoped>
  .projectFilter > .v-input {
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    visibility: hidden;
  }

  .projectFilterToggle {
    cursor: pointer;
  }
</style>
