import constantsCountry from '@/filters/constants-country.filter';
import constantsProjectTypes from '@/filters/constants-project-types.filter';
import { HOME_SCREENS } from '@/api';
import { apiHelper } from '@/utils';
import {
  SHARED_OPEN_LOADING,
  SHARED_CLOSE_LOADING
} from '@/store/shared.module';
// import dateTimeFormat from '@/filters/dateTimeFormat.filter';

/* Define Module Name */
const MODULE_NAME = 'homeScreen/';

/* Define Action Name */
const A_GET_HOME_SCREENS = 'getHomeScreens';
const A_GET_HOME_SCREEN_PENDING_INVITATIONS = 'getHomeScreenPendingInvitations';
const A_GET_HOME_SCREEN_PENDING_SUBMISSIONS = 'getHomeScreenPendingSubmissions';
const A_GET_HOME_SCREEN_JOBS = 'getHomeScreenJobs';
const A_GET_HOME_SCREEN_BANNERS = 'getHomeScreenBanners';
const A_GET_OWN_JOBS = 'getOwnJobs';
const A_GET_UNREAD_NOTIFICATIONS = 'getUnreadNotifications';
const A_GET_UNREAD_MESSAGES = 'getUnreadMessages';
const A_GET_NEWSFEED = 'getNewsfeed';
const A_GET_MY_NEWSFEED = 'getMyNewsfeed';
const A_GET_NEWSFEED_BY_ID = 'getNewsfeedById';
const A_CREATE_NEWSFEED = 'createNewsfeed';
const A_UPDATE_NEWSFEED_BY_ID = 'updateNewsfeedById';
const A_DELETE_NEWSFEED = 'deleteNewsfeed';
const A_CREATE_NEWSFEED_COMMENT = 'createNewsfeedComment';
const A_GET_LIKED_NEWSFEED_USER = 'getLikedNewsfeedUser';
const A_LIKE_NEWSFEED = 'likeNewsfeed';
const A_CANCEL_LIKE_NEWSFEED = 'cancelLikeNewsfeed';
const A_GET_ADVERTISEMENTS = 'getAdvertisements';

/* Define Reset State Action Name */
const A_INITIAL_GET_HOME_SCREENS_STATE = 'initialGetHomeScreensState';
const A_INITIAL_GET_HOME_SCREEN_PENDING_INVITATIONS_STATE =
  'initialGetHomeScreenPendingInvitationsState';
const A_INITIAL_GET_HOME_SCREEN_PENDING_SUBMISSIONS_STATE =
  'initialGetHomeScreenPendingSubmissionsState';
const A_INITIAL_GET_HOME_SCREEN_JOBS_STATE = 'initialGetHomeScreenJobsState';
const A_INITIAL_GET_HOME_SCREEN_BANNERS_STATE =
  'initialGetHomeScreenBannersState';
const A_INITIAL_GET_OWN_JOBS_STATE = 'initialGetOwnJobsState';
const A_INITIAL_GET_UNREAD_NOTIFICATIONS_STATE =
  'initialGetUnreadNotificationsState';
const A_INITIAL_GET_UNREAD_MESSAGES_STATE = 'initialGetUnreadMessagesState';
const A_INITIAL_GET_NEWSFEED_STATE = 'initialGetNewsfeedState';
const A_INITIAL_GET_MY_NEWSFEED_STATE = 'initialGetMyNewsfeedState';
const A_INITIAL_GET_NEWSFEED_BY_ID_STATE = 'initialGetNewsfeedByIdState';
const A_INITIAL_CREATE_NEWSFEED_STATE = 'initialCreateNewsfeedState';
const A_INITIAL_UPDATE_NEWSFEED_BY_ID_STATE = 'initialUpdateNewsfeedByIdState';
const A_INITIAL_DELETE_NEWSFEED_STATE = 'initialDeleteNewsfeedState';
const A_INITIAL_CREATE_NEWSFEED_COMMENT_STATE =
  'initialCreateNewsfeedCommentState';
const A_INITIAL_GET_LIKED_NEWSFEED_USER_STATE =
  'initialGetLikedNewsfeedUserState';
const A_INITIAL_LIKE_NEWSFEED_STATE = 'initialLikeNewsfeedState';
const A_INITIAL_CANCEL_LIKE_NEWSFEED_STATE = 'initialCancelLikeNewsfeedState';
const A_INITIAL_GET_ADVERTISEMENTS_STATE = 'initialGetAdvertisementsState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_HOME_SCREENS = 'setGetHomeScreens';
const M_GET_HOME_SCREEN_PENDING_INVITATIONS =
  'setGetHomeScreenPendingInvitations';
const M_GET_HOME_SCREEN_PENDING_SUBMISSIONS =
  'setGetHomeScreenPendingSubmissions';
const M_GET_HOME_SCREEN_JOBS = 'setGetHomeScreenJobs';
const M_GET_HOME_SCREEN_BANNERS = 'setGetHomeScreenBanners';
const M_GET_OWN_JOBS = 'setGetOwnJobs';
const M_GET_UNREAD_NOTIFICATIONS = 'setGetUnreadNotifications';
const M_GET_UNREAD_MESSAGES = 'setGetUnreadMessages';
const M_GET_NEWSFEED = 'setGetNewsfeed';
const M_GET_MY_NEWSFEED = 'setGetMyNewsfeed';
const M_GET_NEWSFEED_BY_ID = 'setGetNewsfeedById';
const M_CREATE_NEWSFEED = 'setCreateNewsfeed';
const M_UPDATE_NEWSFEED_BY_ID = 'setUpdateNewsfeedById';
const M_DELETE_NEWSFEED = 'setDeleteNewsfeed';
const M_CREATE_NEWSFEED_COMMENT = 'setCreateNewsfeedComment';
const M_GET_LIKED_NEWSFEED_USER = 'setGetLikedNewsfeedUser';
const M_LIKE_NEWSFEED = 'setLikeNewsfeed';
const M_CANCEL_LIKE_NEWSFEED = 'setCancelLikeNewsfeed';
const M_GET_ADVERTISEMENTS = 'setGetAdvertisements';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_HOME_SCREENS_STATE = 'setInitialGetHomeScreensState';
const M_INITIAL_GET_HOME_SCREEN_PENDING_INVITATIONS_STATE =
  'setInitialGetHomeScreenPendingInvitationsState';
const M_INITIAL_GET_HOME_SCREEN_PENDING_SUBMISSIONS_STATE =
  'setInitialGetHomeScreenPendingSubmissionsState';
const M_INITIAL_GET_HOME_SCREEN_JOBS_STATE = 'setInitialGetHomeScreenJobsState';
const M_INITIAL_GET_HOME_SCREEN_BANNERS_STATE =
  'setInitialGetHomeScreenBannersState';
const M_INITIAL_GET_OWN_JOBS_STATE = 'setInitialGetOwnJobsState';
const M_INITIAL_GET_UNREAD_NOTIFICATIONS_STATE =
  'setInitialGetUnreadNotificationsState';
const M_INITIAL_GET_UNREAD_MESSAGES_STATE = 'setInitialGetUnreadMessagesState';
const M_INITIAL_GET_NEWSFEED_STATE = 'setInitialGetNewsfeedState';
const M_INITIAL_GET_MY_NEWSFEED_STATE = 'setInitialGetMyNewsfeedState';
const M_INITIAL_GET_NEWSFEED_BY_ID_STATE = 'setInitialGetNewsfeedByIdState';
const M_INITIAL_CREATE_NEWSFEED_STATE = 'setInitialCreateNewsfeedState';
const M_INITIAL_UPDATE_NEWSFEED_BY_ID_STATE =
  'setInitialUpdateNewsfeedByIdState';
const M_INITIAL_DELETE_NEWSFEED_STATE = 'setInitialDeleteNewsfeedState';
const M_INITIAL_CREATE_NEWSFEED_COMMENT_STATE =
  'setInitialCreateNewsfeedCommentState';
const M_INITIAL_GET_LIKED_NEWSFEED_USER_STATE =
  'setInitialGetLikedNewsfeedUserState';
const M_INITIAL_LIKE_NEWSFEED_STATE = 'setInitialLikeNewsfeedState';
const M_INITIAL_CANCEL_LIKE_NEWSFEED_STATE =
  'setInitialCancelLikeNewsfeedState';
const M_INITIAL_GET_ADVERTISEMENTS_STATE = 'setInitialGetAdvertisementsState';

/* Define Export Name */
export const HOME_SCREENS_GET_HOME_SCREENS = MODULE_NAME + A_GET_HOME_SCREENS;
export const HOME_SCREENS_GET_HOME_SCREEN_PENDING_INVITATIONS =
  MODULE_NAME + A_GET_HOME_SCREEN_PENDING_INVITATIONS;
export const HOME_SCREENS_GET_HOME_SCREEN_PENDING_SUBMISSIONS =
  MODULE_NAME + A_GET_HOME_SCREEN_PENDING_SUBMISSIONS;
export const HOME_SCREENS_GET_HOME_SCREEN_JOBS =
  MODULE_NAME + A_GET_HOME_SCREEN_JOBS;
export const HOME_SCREENS_GET_HOME_SCREEN_BANNERS =
  MODULE_NAME + A_GET_HOME_SCREEN_BANNERS;
export const HOME_SCREENS_GET_OWN_JOBS = MODULE_NAME + A_GET_OWN_JOBS;
export const HOME_SCREENS_GET_UNREAD_NOTIFICATIONS =
  MODULE_NAME + A_GET_UNREAD_NOTIFICATIONS;
export const HOME_SCREENS_GET_UNREAD_MESSAGES =
  MODULE_NAME + A_GET_UNREAD_MESSAGES;
export const HOME_SCREENS_GET_NEWSFEED = MODULE_NAME + A_GET_NEWSFEED;
export const HOME_SCREENS_GET_MY_NEWSFEED = MODULE_NAME + A_GET_MY_NEWSFEED;
export const HOME_SCREENS_GET_NEWSFEED_BY_ID =
  MODULE_NAME + A_GET_NEWSFEED_BY_ID;
export const HOME_SCREENS_CREATE_NEWSFEED = MODULE_NAME + A_CREATE_NEWSFEED;
export const HOME_SCREENS_UPDATE_NEWSFEED_BY_ID =
  MODULE_NAME + A_UPDATE_NEWSFEED_BY_ID;
export const HOME_SCREENS_DELETE_NEWSFEED = MODULE_NAME + A_DELETE_NEWSFEED;
export const HOME_SCREENS_CREATE_NEWSFEED_COMMENT =
  MODULE_NAME + A_CREATE_NEWSFEED_COMMENT;
export const HOME_SCREENS_GET_LIKED_NEWSFEED_USER =
  MODULE_NAME + A_GET_LIKED_NEWSFEED_USER;
export const HOME_SCREENS_LIKE_NEWSFEED = MODULE_NAME + A_LIKE_NEWSFEED;
export const HOME_SCREENS_CANCEL_LIKE_NEWSFEED =
  MODULE_NAME + A_CANCEL_LIKE_NEWSFEED;
export const HOME_SCREENS_GET_ADVERTISEMENTS =
  MODULE_NAME + A_GET_ADVERTISEMENTS;

/* Define Reset State Name */
export const HOME_SCREENS_INITIAL_GET_HOME_SCREENS_STATE =
  MODULE_NAME + A_INITIAL_GET_HOME_SCREENS_STATE;
export const HOME_SCREENS_INITIAL_GET_HOME_SCREEN_PENDING_INVITATIONS_STATE =
  MODULE_NAME + A_INITIAL_GET_HOME_SCREEN_PENDING_INVITATIONS_STATE;
export const HOME_SCREENS_INITIAL_GET_HOME_SCREEN_PENDING_SUBMISSIONS_STATE =
  MODULE_NAME + A_INITIAL_GET_HOME_SCREEN_PENDING_SUBMISSIONS_STATE;
export const HOME_SCREENS_INITIAL_GET_HOME_SCREEN_JOBS_STATE =
  MODULE_NAME + A_INITIAL_GET_HOME_SCREEN_JOBS_STATE;
export const HOME_SCREENS_INITIAL_GET_HOME_SCREEN_BANNERS_STATE =
  MODULE_NAME + A_INITIAL_GET_HOME_SCREEN_BANNERS_STATE;
export const HOME_SCREENS_INITIAL_GET_OWN_JOBS_STATE =
  MODULE_NAME + A_INITIAL_GET_OWN_JOBS_STATE;
export const HOME_SCREENS_INITIAL_GET_UNREAD_NOTIFICATIONS_STATE =
  MODULE_NAME + A_INITIAL_GET_UNREAD_NOTIFICATIONS_STATE;
export const HOME_SCREENS_INITIAL_GET_UNREAD_MESSAGES_STATE =
  MODULE_NAME + A_INITIAL_GET_UNREAD_MESSAGES_STATE;
export const HOME_SCREENS_INITIAL_GET_NEWSFEED_STATE =
  MODULE_NAME + A_INITIAL_GET_NEWSFEED_STATE;
export const HOME_SCREENS_INITIAL_GET_MY_NEWSFEED_STATE =
  MODULE_NAME + A_INITIAL_GET_MY_NEWSFEED_STATE;
export const HOME_SCREENS_INITIAL_GET_NEWSFEED_BY_ID_STATE =
  MODULE_NAME + A_INITIAL_GET_NEWSFEED_BY_ID_STATE;
export const HOME_SCREENS_INITIAL_CREATE_NEWSFEED_STATE =
  MODULE_NAME + A_INITIAL_CREATE_NEWSFEED_STATE;
export const HOME_SCREENS_INITIAL_UPDATE_NEWSFEED_BY_ID_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_NEWSFEED_BY_ID_STATE;
export const HOME_SCREENS_INITIAL_DELETE_NEWSFEED_STATE =
  MODULE_NAME + A_INITIAL_DELETE_NEWSFEED_STATE;
export const HOME_SCREENS_INITIAL_CREATE_NEWSFEED_COMMENT_STATE =
  MODULE_NAME + A_INITIAL_CREATE_NEWSFEED_COMMENT_STATE;
export const HOME_SCREENS_INITIAL_GET_LIKED_NEWSFEED_USER_STATE =
  MODULE_NAME + A_INITIAL_GET_LIKED_NEWSFEED_USER_STATE;
export const HOME_SCREENS_INITIAL_LIKE_NEWSFEED_STATE =
  MODULE_NAME + A_INITIAL_LIKE_NEWSFEED_STATE;
export const HOME_SCREENS_INITIAL_CANCEL_LIKE_NEWSFEED_STATE =
  MODULE_NAME + A_INITIAL_CANCEL_LIKE_NEWSFEED_STATE;
export const HOME_SCREENS_INITIAL_GET_ADVERTISEMENTS_STATE =
  MODULE_NAME + A_INITIAL_GET_ADVERTISEMENTS_STATE;

const state = {
  homeScreens: {
    code: 0,
    complete: false,
    data: {},
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  homeScreenPendingInvitations: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  homeScreenPendingSubmissions: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  homeScreenJobs: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },

  homeScreenBanners: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  ownJobs: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  unreadNotifications: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  unreadMessages: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },

  newsfeed: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  myNewsfeed: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  newsfeedById: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  createNewsfeed: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  updateNewsfeedById: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  deleteNewsfeed: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  createNewsfeedComment: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  likedNewsfeedUser: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  likeNewsfeed: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  cancelLikeNewsfeed: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  advertisements: {
    code: 0,
    complete: false,
    data: {},
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_HOME_SCREENS]({ dispatch, commit }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    HOME_SCREENS.getHomeScreens()
      .then((response) => {
        result = response;
        commit(M_GET_HOME_SCREENS, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_HOME_SCREENS, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_HOME_SCREEN_PENDING_INVITATIONS]({ dispatch, commit }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    HOME_SCREENS.getHomeScreenPendingInvitations()
      .then((response) => {
        result = response;
        commit(M_GET_HOME_SCREEN_PENDING_INVITATIONS, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_HOME_SCREEN_PENDING_INVITATIONS, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_HOME_SCREEN_PENDING_SUBMISSIONS]({ dispatch, commit }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    HOME_SCREENS.getHomeScreenPendingSubmissions()
      .then((response) => {
        result = response;
        commit(M_GET_HOME_SCREEN_PENDING_SUBMISSIONS, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_HOME_SCREEN_PENDING_SUBMISSIONS, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_HOME_SCREEN_JOBS]({ dispatch, commit }, { data }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    HOME_SCREENS.getHomeScreenJobs(data)
      .then((response) => {
        result = response;
        commit(M_GET_HOME_SCREEN_JOBS, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_HOME_SCREEN_JOBS, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_HOME_SCREEN_BANNERS]({ dispatch, commit }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    HOME_SCREENS.getHomeScreenBanners()
      .then((response) => {
        result = response;
        commit(M_GET_HOME_SCREEN_BANNERS, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_HOME_SCREEN_BANNERS, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_GET_OWN_JOBS]({ dispatch, commit }, { data }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await HOME_SCREENS.getOwnJobs(data)
      .then((response) => {
        result = response;
        commit(M_GET_OWN_JOBS, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_OWN_JOBS, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_GET_UNREAD_NOTIFICATIONS]({ commit }) {
    let result = null;
    await HOME_SCREENS.getUnreadNotifications()
      .then((response) => {
        result = response;
        commit(M_GET_UNREAD_NOTIFICATIONS, result);
      })
      .catch((error) => {
        result = error;
        commit(M_GET_UNREAD_NOTIFICATIONS, result);
      });
  },
  async [A_GET_UNREAD_MESSAGES]({ commit }) {
    let result = null;
    await HOME_SCREENS.getUnreadMessages()
      .then((response) => {
        result = response;
        commit(M_GET_UNREAD_MESSAGES, result);
      })
      .catch((error) => {
        result = error;
        commit(M_GET_UNREAD_MESSAGES, result);
      });
  },
  async [A_GET_NEWSFEED]({ dispatch, commit }, { data }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await HOME_SCREENS.getNewsfeed(data)
      .then((response) => {
        result = response;
        commit(M_GET_NEWSFEED, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_NEWSFEED, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_GET_MY_NEWSFEED]({ dispatch, commit }, { data }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await HOME_SCREENS.getMyNewsfeed(data)
      .then((response) => {
        result = response;
        commit(M_GET_MY_NEWSFEED, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_MY_NEWSFEED, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_GET_NEWSFEED_BY_ID]({ dispatch, commit }, { id, likeOnly }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await HOME_SCREENS.getNewsfeedById(id)
      .then((response) => {
        result = response;
        commit(M_GET_NEWSFEED_BY_ID, { ...result, likeOnly });
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_NEWSFEED_BY_ID, { ...result, likeOnly });
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_CREATE_NEWSFEED]({ dispatch, commit }, { data }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await HOME_SCREENS.createNewsfeed(data)
      .then((response) => {
        result = response;
        commit(M_CREATE_NEWSFEED, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_CREATE_NEWSFEED, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_UPDATE_NEWSFEED_BY_ID]({ dispatch, commit }, { id, data }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await HOME_SCREENS.updateNewsfeedById(id, data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_NEWSFEED_BY_ID, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_NEWSFEED_BY_ID, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_DELETE_NEWSFEED]({ dispatch, commit }, { id }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await HOME_SCREENS.deleteNewsfeed(id)
      .then((response) => {
        result = response;
        commit(M_DELETE_NEWSFEED, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_DELETE_NEWSFEED, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_CREATE_NEWSFEED_COMMENT]({ dispatch, commit }, { id, data }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await HOME_SCREENS.createNewsfeedComment(id, data)
      .then((response) => {
        result = response;
        commit(M_CREATE_NEWSFEED_COMMENT, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_CREATE_NEWSFEED_COMMENT, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_GET_LIKED_NEWSFEED_USER]({ dispatch, commit }, { id, ownerId }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await HOME_SCREENS.getLikedNewsfeedUser(id)
      .then((response) => {
        result = response;
        commit(M_GET_LIKED_NEWSFEED_USER, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_LIKED_NEWSFEED_USER, { ...result, ownerId });
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_LIKE_NEWSFEED]({ commit }, { id }) {
    // dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await HOME_SCREENS.likeNewsfeed(id)
      .then((response) => {
        result = response;
        commit(M_LIKE_NEWSFEED, { ...result, id });
        // dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_LIKE_NEWSFEED, result);
        // dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_CANCEL_LIKE_NEWSFEED]({ commit }, { id }) {
    // dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await HOME_SCREENS.cancelLikeNewsfeed(id)
      .then((response) => {
        result = response;
        commit(M_CANCEL_LIKE_NEWSFEED, { ...result, id });
        // dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_CANCEL_LIKE_NEWSFEED, result);
        // dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_GET_ADVERTISEMENTS]({ commit }, { data }) {
    // dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await HOME_SCREENS.getAdvertisements(data)
      .then((response) => {
        result = response;
        commit(M_GET_ADVERTISEMENTS, result);
        // dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_ADVERTISEMENTS, result);
        // dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },

  [A_INITIAL_GET_HOME_SCREENS_STATE]({ commit }) {
    commit(M_INITIAL_GET_HOME_SCREENS_STATE);
  },
  [A_INITIAL_GET_HOME_SCREEN_PENDING_INVITATIONS_STATE]({ commit }) {
    commit(M_INITIAL_GET_HOME_SCREEN_PENDING_INVITATIONS_STATE);
  },
  [A_INITIAL_GET_HOME_SCREEN_PENDING_SUBMISSIONS_STATE]({ commit }) {
    commit(M_INITIAL_GET_HOME_SCREEN_PENDING_SUBMISSIONS_STATE);
  },
  [A_INITIAL_GET_HOME_SCREEN_JOBS_STATE]({ commit }) {
    commit(M_INITIAL_GET_HOME_SCREEN_JOBS_STATE);
  },
  [A_INITIAL_GET_HOME_SCREEN_BANNERS_STATE]({ commit }) {
    commit(M_INITIAL_GET_HOME_SCREEN_BANNERS_STATE);
  },
  [A_INITIAL_GET_OWN_JOBS_STATE]({ commit }) {
    commit(M_INITIAL_GET_OWN_JOBS_STATE);
  },
  [A_INITIAL_GET_UNREAD_NOTIFICATIONS_STATE]({ commit }) {
    commit(M_INITIAL_GET_UNREAD_NOTIFICATIONS_STATE);
  },
  [A_INITIAL_GET_UNREAD_MESSAGES_STATE]({ commit }) {
    commit(M_INITIAL_GET_UNREAD_MESSAGES_STATE);
  },
  [A_INITIAL_GET_NEWSFEED_STATE]({ commit }) {
    commit(M_INITIAL_GET_NEWSFEED_STATE);
  },
  [A_INITIAL_GET_MY_NEWSFEED_STATE]({ commit }) {
    commit(M_INITIAL_GET_MY_NEWSFEED_STATE);
  },
  [A_INITIAL_GET_NEWSFEED_BY_ID_STATE]({ commit }) {
    commit(M_INITIAL_GET_NEWSFEED_BY_ID_STATE);
  },
  [A_INITIAL_CREATE_NEWSFEED_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_NEWSFEED_STATE);
  },
  [A_INITIAL_UPDATE_NEWSFEED_BY_ID_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_NEWSFEED_BY_ID_STATE);
  },
  [A_INITIAL_DELETE_NEWSFEED_STATE]({ commit }) {
    commit(M_INITIAL_DELETE_NEWSFEED_STATE);
  },
  [A_INITIAL_CREATE_NEWSFEED_COMMENT_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_NEWSFEED_COMMENT_STATE);
  },
  [A_INITIAL_GET_LIKED_NEWSFEED_USER_STATE]({ commit }) {
    commit(M_INITIAL_GET_LIKED_NEWSFEED_USER_STATE);
  },
  [A_INITIAL_LIKE_NEWSFEED_STATE]({ commit }) {
    commit(M_INITIAL_LIKE_NEWSFEED_STATE);
  },
  [A_INITIAL_CANCEL_LIKE_NEWSFEED_STATE]({ commit }) {
    commit(M_INITIAL_CANCEL_LIKE_NEWSFEED_STATE);
  },
  [A_INITIAL_GET_ADVERTISEMENTS_STATE]({ commit }) {
    commit(M_INITIAL_GET_ADVERTISEMENTS_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_HOME_SCREENS](state, result) {
    if (result.code === 200) {
      let d = result.data;
      state.homeScreens = {
        code: result.code,
        complete: true,
        data: d,
        message: null
      };
    } else {
      state.homeScreens = {
        code: result.code,
        complete: true,
        data: {},
        message: apiHelper.sortOutMessage(result.error_message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_GET_HOME_SCREEN_PENDING_INVITATIONS](state, result) {
    if (result.code === 200) {
      let d = result.data.pending_invitation_data;
      state.homeScreenPendingInvitations = {
        code: result.code,
        complete: true,
        data: d,
        message: null
      };
    } else {
      state.homeScreenPendingInvitations = {
        code: result.code,
        complete: true,
        data: {},
        message: apiHelper.sortOutMessage(result.error_message)
      };
    }
  },
  [M_GET_HOME_SCREEN_PENDING_SUBMISSIONS](state, result) {
    if (result.code === 200) {
      let d = result.data.pending_submission_data;
      state.homeScreenPendingSubmissions = {
        code: result.code,
        complete: true,
        data: d,
        message: null
      };
    } else {
      state.homeScreenPendingSubmissions = {
        code: result.code,
        complete: true,
        data: {},
        message: apiHelper.sortOutMessage(result.error_message)
      };
    }
  },
  [M_GET_HOME_SCREEN_JOBS](state, result) {
    if (result.code === 200) {
      let d = result.data.items;
      let job = [];

      d.forEach((x) => {
        let castingRoles = [];
        if (x.casting_roles) {
          x.casting_roles.forEach((z) => {
            castingRoles.push({
              ageRange: z.age_range,
              character: z.character,
              characterWriteUp: z.character_write_up
            });
          });
        }
        if (x.producer_company_logo) {
          let y = x.producer_company_logo;
          x.producer_company_logo = {
            id: y.id,
            mediaPath: y.media_path,
            mimeType: y.mime_type
          };
        }
        if (x.job_cover) {
          let z = x.job_cover;
          x.job_cover = {
            id: z.id,
            mediaPath: z.media_path,
            mimeType: z.mime_type
          };
        }

        job.push({
          id: x.id,
          applicantCountryId: constantsCountry(x.applicant_country_id),
          castingRoles: castingRoles,
          closeDate: x.close_date,
          createOwnFields: x.create_own_fields,
          currency: x.currency,
          isPaid: x.is_paid,
          isWfh: x.is_wfh,
          isExpired: x.is_expired,
          jobCountryId: constantsCountry(x.job_country_id),
          jobCover: x.job_cover || {
            mediaPath: null
          },
          jobType: x.job_type,
          liveDate: x.live_date,
          producer: x.producer,
          producerCompanyLogo: x.producer_company_logo || {
            mediaPath: null
          },
          producerIsVerified: x.producer_is_verified || false,
          producerUid: x.producer_uid,
          projectRoleId: x.project_role_id,
          projectTypeId: constantsProjectTypes(x.project_type_id),
          synopsis: x.synopsis,
          title: x.title,
          videoDue: x.video_due
        });
      });
      state.homeScreenJobs = {
        code: result.code,
        complete: true,
        data: job,
        message: null
      };
    } else {
      state.homeScreenJobs = {
        code: result.code,
        complete: true,
        data: {},
        message: apiHelper.sortOutMessage(result.error_message)
      };
    }
  },
  [M_GET_HOME_SCREEN_BANNERS](state, result) {
    if (result.code === 200) {
      let d = result.data;

      state.homeScreenBanners = {
        code: result.code,
        complete: true,
        data: d,
        message: null
      };
    } else {
      state.homeScreenBanners = {
        code: result.code,
        complete: true,
        data: {},
        message: apiHelper.sortOutMessage(result.error_message)
      };
    }
  },
  [M_GET_OWN_JOBS](state, result) {
    if (result.code === 200) {
      let d = result.data.items;
      let job = [];

      d.forEach((x) => {
        let castingRoles = [];
        if (x.casting_roles) {
          x.casting_roles.forEach((z) => {
            castingRoles.push({
              ageRange: z.age_range,
              character: z.character,
              characterWriteUp: z.character_write_up
            });
          });
        }
        if (x.producer_company_logo) {
          let y = x.producer_company_logo;
          x.producer_company_logo = {
            id: y.id,
            mediaPath: y.media_path,
            mimeType: y.mime_type
          };
        }
        if (x.job_cover) {
          let z = x.job_cover;
          x.job_cover = {
            id: z.id,
            mediaPath: z.media_path,
            mimeType: z.mime_type
          };
        }

        job.push({
          id: x.id,
          applicantCountryId: constantsCountry(x.applicant_country_id),
          castingRoles: castingRoles,
          closeDate: x.close_date,
          createOwnFields: x.create_own_fields,
          currency: x.currency,
          isPaid: x.is_paid,
          isWfh: x.is_wfh,
          jobCountryId: constantsCountry(x.job_country_id),
          jobCover: x.job_cover || {
            mediaPath: null
          },
          jobType: x.job_type,
          liveDate: x.live_date,
          producer: x.producer,
          producerCompanyLogo: x.producer_company_logo || {
            mediaPath: null
          },
          producerIsVerified: x.producer_is_verified || false,
          projectRoleId: x.project_role_id,
          projectTypeId: constantsProjectTypes(x.project_type_id),
          synopsis: x.synopsis,
          title: x.title,
          videoDue: x.video_due
        });
      });
      state.ownJobs = {
        code: result.code,
        complete: true,
        data: job,
        message: null
      };
    } else {
      state.ownJobs = {
        code: result.code,
        complete: true,
        data: {},
        message: apiHelper.sortOutMessage(result.error_message)
      };
    }
  },
  [M_GET_UNREAD_NOTIFICATIONS](state, result) {
    state.unreadNotifications = {
      code: result.code,
      complete: true,
      data: result.data,
      message:
        result.code != '200'
          ? apiHelper.sortOutMessage(result.error_message)
          : null
    };
  },
  [M_GET_UNREAD_MESSAGES](state, result) {
    state.unreadMessages = {
      code: result.code,
      complete: true,
      data: result.data,
      message:
        result.code != '200'
          ? apiHelper.sortOutMessage(result.error_message)
          : null
    };
  },
  [M_GET_NEWSFEED](state, result) {
    state.newsfeed = {
      code: result.code,
      complete: true,
      data: result.data,
      message:
        result.code != '200'
          ? apiHelper.sortOutMessage(result.error_message)
          : null
    };
  },
  [M_GET_MY_NEWSFEED](state, result) {
    state.myNewsfeed = {
      code: result.code,
      complete: true,
      data: result.data,
      message:
        result.code != '200'
          ? apiHelper.sortOutMessage(result.error_message)
          : null
    };
  },
  [M_GET_NEWSFEED_BY_ID](state, result) {
    state.newsfeedById = {
      code: result.code,
      complete: true,
      data: {
        ...result.data,
        likeOnly: result.likeOnly,
        ownerId: result.data?.user_id || null
      },
      message:
        result.code != '200'
          ? apiHelper.sortOutMessage(result.error_message)
          : null
    };
  },
  [M_CREATE_NEWSFEED](state, result) {
    state.createNewsfeed = {
      code: result.code,
      complete: true,
      data: result.data,
      message:
        result.code != '200'
          ? apiHelper.sortOutMessage(result.error_message)
          : null
    };
  },
  [M_UPDATE_NEWSFEED_BY_ID](state, result) {
    state.updateNewsfeedById = {
      code: result.code,
      complete: true,
      data: result.data,
      message:
        result.code != '200'
          ? apiHelper.sortOutMessage(result.error_message)
          : null
    };
  },
  [M_DELETE_NEWSFEED](state, result) {
    state.deleteNewsfeed = {
      code: result.code,
      complete: true,
      data: result.data,
      message:
        result.code != '200'
          ? apiHelper.sortOutMessage(result.error_message)
          : null
    };
  },
  [M_CREATE_NEWSFEED_COMMENT](state, result) {
    state.createNewsfeedComment = {
      code: result.code,
      complete: true,
      data: result.data,
      message:
        result.code != '200'
          ? apiHelper.sortOutMessage(result.error_message)
          : null
    };
  },
  [M_GET_LIKED_NEWSFEED_USER](state, result) {
    state.likedNewsfeedUser = {
      code: result.code,
      complete: true,
      data: {
        ...result.data,
        ownerId: result.ownerId || null
      },
      message:
        result.code != '200'
          ? apiHelper.sortOutMessage(result.error_message)
          : null
    };
  },
  [M_LIKE_NEWSFEED](state, result) {
    state.likeNewsfeed = {
      code: result.code,
      complete: true,
      data: {
        ownerId: result.data?.user_id || null,
        id: result?.id || null
      },
      message:
        result.code != '200'
          ? apiHelper.sortOutMessage(result.error_message)
          : null
    };
  },
  [M_CANCEL_LIKE_NEWSFEED](state, result) {
    state.cancelLikeNewsfeed = {
      code: result.code,
      complete: true,
      data: {
        id: result?.id || null
      },
      message:
        result.code != '200'
          ? apiHelper.sortOutMessage(result.error_message)
          : null
    };
  },
  [M_GET_ADVERTISEMENTS](state, result) {
    state.advertisements = {
      code: result.code,
      complete: true,
      data: result.data,
      message:
        result.code != '200'
          ? apiHelper.sortOutMessage(result.error_message)
          : null
    };
  },
  [M_INITIAL_GET_HOME_SCREENS_STATE](state) {
    let data = state.homeScreens.data;

    state.homeScreens = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_HOME_SCREEN_PENDING_INVITATIONS_STATE](state) {
    let data = state.homeScreenPendingInvitations.data;

    state.homeScreenPendingInvitations = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_GET_HOME_SCREEN_PENDING_SUBMISSIONS_STATE](state) {
    let data = state.homeScreenPendingSubmissions.data;

    state.homeScreenPendingSubmissions = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_GET_HOME_SCREEN_JOBS_STATE](state) {
    let data = state.homeScreenJobs.data;

    state.homeScreenJobs = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_GET_HOME_SCREEN_BANNERS_STATE](state) {
    let data = state.homeScreenBanners.data;

    state.homeScreenBanners = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_GET_OWN_JOBS_STATE](state) {
    let data = state.ownJobs.data;

    state.ownJobs = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_GET_UNREAD_NOTIFICATIONS_STATE](state) {
    let data = state.unreadNotifications.data;

    state.unreadNotifications = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_GET_UNREAD_MESSAGES_STATE](state) {
    let data = state.unreadMessages.data;

    state.unreadMessages = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_GET_NEWSFEED_STATE](state) {
    let data = state.newsfeed.data;

    state.newsfeed = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_GET_MY_NEWSFEED_STATE](state) {
    let data = state.myNewsfeed.data;

    state.myNewsfeed = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_GET_NEWSFEED_BY_ID_STATE](state) {
    let data = state.newsfeedById.data;

    state.newsfeedById = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_CREATE_NEWSFEED_STATE](state) {
    let data = state.createNewsfeed.data;

    state.createNewsfeed = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_UPDATE_NEWSFEED_BY_ID_STATE](state) {
    let data = state.updateNewsfeedById.data;

    state.updateNewsfeedById = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_DELETE_NEWSFEED_STATE](state) {
    let data = state.deleteNewsfeed.data;

    state.deleteNewsfeed = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_CREATE_NEWSFEED_COMMENT_STATE](state) {
    let data = state.createNewsfeedComment.data;

    state.createNewsfeedComment = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_GET_LIKED_NEWSFEED_USER_STATE](state) {
    let data = state.likedNewsfeedUser.data;

    state.likedNewsfeedUser = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_LIKE_NEWSFEED_STATE](state) {
    let data = state.likeNewsfeed.data;

    state.likeNewsfeed = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_CANCEL_LIKE_NEWSFEED_STATE](state) {
    let data = state.cancelLikeNewsfeed.data;

    state.cancelLikeNewsfeed = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_GET_ADVERTISEMENTS_STATE](state) {
    let data = state.advertisements.data;

    state.advertisements = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
