import { formValidationHelper } from '@/utils';

const formValidationMixin = {
  methods: {
    concatRequiredRules(rules) {
      return rules.concat(formValidationHelper.requiredRules());
    }
  }
};

export default formValidationMixin;
