<template>
  <div class="wh-100">
    <Header withBorderBottom></Header>
    <v-main class="profileBody">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>
    <Footer></Footer>
    <NotAllowModal />
    <!-- <v-navigation-drawer
      v-model="openNavBar"
      absolute
      temporary
      right
      color="black"
      :width="$isMobile()? 'auto' :350"
    >
      <div class="navbarContent text-left pt-5">
        <div v-for="(item, index) in navBarContents" :key="index" class="py-2">
          <div class="mb-2">{{ item.section }}</div>
          <div
            v-for="(child, idx) in item.children"
            class="text-uppercase d-flex justify-space-between font-weight-bold py-2 px-2 navBarContent"
            :key="idx"
            @click="child.action"
          >
            <div>
              {{ child.title }}
            </div>
            <div>
              <v-icon color="white" class="icon"> mdi-chevron-right </v-icon>
            </div>
          </div>
        </div>
        <hr class="divider my-2" />
        <div
          class="text-uppercase font-weight-bold mt-5 mb-3 py-2 px-2 navBarContent"
          @click="logout()"
        >
          Logout
        </div>
        <div
          class="text-uppercase font-weight-bold py-2 px-2 navBarContent"
          @click="$router.push('/delete-account')"
        >
          Delete Account
        </div>
      </div>
    </v-navigation-drawer> -->
  </div>
</template>

<script>
  import Header from '@/components/layout/Header';
  import Footer from '@/components/layout/Footer';
  import NotAllowModal from '@/components/base/NotAllowModal';
  import {
    CONSTANTS_GET_CONSTANTS,
    CONSTANTS_INITIAL_CONSTANTS_STATE
  } from '@/store/constants.module';
  import { AUTH_ME, AUTH_LOGOUT } from '@/store/auth.module';
  // import { bus } from '@/main.js';

  export default {
    name: 'ProfileLayoutWithoutSideBar',
    props: {
      noSideBar: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      openNavBar: false
    }),
    components: {
      Header,
      Footer,
      NotAllowModal
    },
    computed: {
      constantsComplete() {
        return this.$store.state.constants.constants.complete;
      },
      me() {
        return this.$store.state.auth.me.data;
      },
      navBarContents() {
        let contents = [
          {
            section: 'Privacy',
            children: [
              {
                title: 'Display Information',
                action: () => this.$router.push('/display-informations')
              }
              // {
              //   title: 'Blocked Account',
              //   action: () => this.$router.push('/blocked-account'),
              // },
            ]
          },
          {
            section: 'Settings',
            children: [
              {
                title: 'Push Notification',
                action: () => this.$router.push('/push-notifications')
              },
              {
                title: 'Payment',
                action: () => this.$router.push('/payment')
              }
              // {
              //   title: 'Language',
              //   action: () => this.openMessageModal(),
              //   // action: () => this.$router.push('/language'),
              // },
            ]
          }
        ];
        ['talent'].includes(this.me.role)
          ? contents.splice(0, 0, {
              section: 'Profile',
              children: [
                {
                  title: 'Profile Info',
                  action: () => this.$router.push('/profile-info')
                },
                {
                  title: 'Linked Agency',
                  action: () => this.$router.push('/linked-agency')
                }
              ]
            })
          : contents.splice(
              0,
              0,
              {
                section: 'Profile',
                children: [
                  {
                    title: 'Company Info',
                    action: () => this.$router.push('/company-info')
                  }
                ]
              },
              {
                section: 'Other Accounts',
                children: [
                  {
                    title: 'Admin Account',
                    action: () => this.$router.push('/admin-account')
                  }
                ]
              }
            );

        if (this.me.role === 'agency') {
          contents[0].children.splice(1, 0, {
            title: 'Company Code',
            action: () => this.$router.push('/company-code')
          });

          contents[1].children.splice(1, 0, {
            title: 'Talent Account',
            action: () => this.$router.push('/talent-account')
          });
        }
        return contents;
      }
    },
    watch: {
      constantsComplete() {
        let response = this.$store.state.constants.constants;

        if (response.complete) {
          this.constantsCompleteAction(response);
        }
      }
    },
    async created() {
      await this.initLayout();
    },
    methods: {
      constantsCompleteAction(response) {
        if (response.code != 0) {
          // console.log(response);
        } else {
          this.initialGetConstantsState();
        }
      },
      async getConstants() {
        await this.$store.dispatch(CONSTANTS_GET_CONSTANTS);
      },
      initialGetConstantsState() {
        this.$store.dispatch(CONSTANTS_INITIAL_CONSTANTS_STATE);
      },
      async getMe() {
        await this.$store.dispatch(AUTH_ME);
      },
      async initLayout() {
        await this.getMe();
        await this.getConstants();
      },
      logout() {
        this.$store.dispatch(AUTH_LOGOUT, { isSessionExpired: false });
      },
      openMessageModal() {
        this.$openModal('messageModal', 'The feature will be available soon');
      }
    }
  };
</script>

<style scoped lang="scss">
  .profileBody {
    background: url('../../../public/assets/image/icons/defaultBackground.png');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .v-navigation-drawer--temporary:not(.v-navigation-drawer--close) {
    box-shadow: 0px 16px 24px 2px rgb(255 51 246);
  }
  .navbarContent {
    width: 90%;
  }
  ::v-deep .v-navigation-drawer__content {
    text-align: -webkit-center;
  }

  .navBarContent:hover {
    background-color: #242424;
    cursor: pointer;
  }
  // @media (max-width: 720px) {
  //   ::v-deep .v-app-bar.v-app-bar--fixed {
  //     position: unset;
  //   }
  // }
  .menuDrawer {
    position: fixed;
    overflow: auto;
    width: 100%;
    z-index: 10000;
    right: -100%;
    height: 100%;
    background-color: black;
    color: white;
    padding: 30px 50px;
    // display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & p {
      font-weight: bold;
      font-size: 1rem;
      // text-align: center;
      margin-bottom: 50px;
    }
    &.opened {
      animation: swipeIn ease-in 0.5s forwards;
    }
  }
</style>
