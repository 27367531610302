import { LOOKUP } from '@/api';
import { apiHelper } from '@/utils';
import {
  SHARED_OPEN_LOADING,
  SHARED_CLOSE_LOADING
} from '@/store/shared.module';

/* Define Module Name */
const MODULE_NAME = 'lookup/';

/* Define Action Name */
const A_GET_SKILLS = 'getSkills';

/* Define Reset State Action Name */
const A_INITIAL_GET_SKILLS_STATE = 'initialGetSkillsState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_SKILLS = 'setGetSkills';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_SKILLS_STATE = 'setInitialGetSkillsState';

/* Define Export Name */
export const LOOKUP_GET_SKILLS = MODULE_NAME + A_GET_SKILLS;

/* Define Reset State Name */
export const LOOKUP_INITIAL_GET_SKILLS_STATE =
  MODULE_NAME + A_INITIAL_GET_SKILLS_STATE;

const state = {
  skills: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_SKILLS]({ dispatch, commit }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    LOOKUP.getSkills()
      .then((response) => {
        result = response;
        commit(M_GET_SKILLS, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_SKILLS, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_SKILLS_STATE]({ commit }) {
    commit(M_INITIAL_GET_SKILLS_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_SKILLS](state, result) {
    const d = result.data
      ? result.data.map((x) => {
          return {
            value: x.id,
            description: x.name,
            isDeleted: x.is_deleted,
            type: x.type
          };
        })
      : [];
    state.skills = {
      code: result.code,
      complete: true,
      data: d,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_INITIAL_GET_SKILLS_STATE](state) {
    let data = state.skills.data;

    state.skills = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
