<template>
  <div>
    <template v-if="type == 'select'">
      <v-select
        v-model="view_model"
        :items="selectItems"
        :multiple="config.multiple"
        :clearable="config.clearable"
        :hide-details="config.hideDetails"
        :id="id"
        :name="name"
        :placeholder="placeholder"
        :counter="counter"
        :rules="rules"
        :label="label"
        :type="type"
        :required="required"
        dark
        v-bind="$attrs"
      >
        <template v-slot:prepend-item>
          <v-list-item>
            <v-list-item-content>
              <v-text-field
                v-model="searchTerm"
                placeholder="Search"
                @input="searchSelect"
              ></v-text-field>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
      </v-select>
    </template>
    <template v-if="type == 'radio'">
      <div class="w-100">
        <p>{{ label }}</p>
        <v-radio-group v-model="view_model" row>
          <template v-for="item in config.items">
            <v-radio :value="item.value" :key="item.key" dark color="#FF2FF2">
              <template v-slot:label>
                <span class="white--text">{{ item.description }}</span>
              </template>
            </v-radio>
          </template>
        </v-radio-group>
      </div>
    </template>
    <template v-if="type == 'text' || type == 'password'">
      <v-text-field
        v-model="view_model"
        :id="id"
        :name="name"
        :placeholder="placeholder"
        :counter="counter"
        :rules="rules"
        :label="label"
        :type="type"
        :required="required"
        :hide-details="config.hideDetails"
        v-bind="$attrs"
      >
      </v-text-field>
    </template>
    <template v-if="type == 'text-select'">
      <v-text-field
        :value="value.value"
        :id="id"
        :name="name"
        :placeholder="placeholder"
        :hide-details="config.hideDetails"
        :counter="counter"
        :rules="rules"
        :label="label"
        :type="config.type"
        :required="required"
        v-bind="$attrs"
        @input="(e) => $emit('input', { ...value, value: e })"
      >
        <template v-slot:prepend-inner>
          <div class="mt-n3 mr-2" style="height: 30px; width: 70px">
            <CustomSelect
              :options="config.items"
              placeholder="SGD"
              :value="value.key"
              @input="(e) => $emit('input', { ...value, key: e })"
            />
          </div>
        </template>
      </v-text-field>
    </template>
    <template v-if="type == 'tag-input'">
      <div>
        <v-text-field
          :value="getTagInput"
          :id="id"
          :name="name"
          :placeholder="placeholder"
          :counter="counter"
          :rules="rules"
          :label="label"
          :type="config.type"
          :required="required"
          :hide-details="config.hideDetails"
          @input="(e) => $emit('input', { ...value, text: e })"
          v-bind="$attrs"
        >
          <template v-slot:append>
            <div
              v-if="getTagInput"
              class="pickBtn mr-1 mt-n2 mb-1"
              @click="
                $emit('input', {
                  ...value,
                  text: '',
                  tags: (value.tags || []).concat([value.text])
                })
              "
            >
              Add
            </div>
          </template>
        </v-text-field>
        <div class="d-flex">
          <div class="pickBtn mr-1 mt-2" v-for="tag in getTagList" :key="tag">
            {{ tag }}
            <v-icon
              dark
              @click="
                $emit('input', {
                  ...value,
                  text: '',
                  tags: (value.tags || []).filter((i) => i != tag)
                })
              "
              >mdi-close</v-icon
            >
          </div>
        </div>
      </div>
    </template>
    <template v-if="type == 'number'">
      <v-text-field
        v-model="view_model"
        :id="id"
        :name="name"
        :placeholder="placeholder"
        :counter="counter"
        :rules="rules"
        :label="label"
        min="0"
        type="number"
        :required="required"
        v-bind="$attrs"
        @blur="(e) => $emit('blur', e)"
      ></v-text-field>
    </template>
    <template v-if="type == 'textarea'">
      <v-textarea
        v-model="view_model"
        :id="id"
        :name="name"
        :placeholder="placeholder"
        :counter="counter"
        :rules="rules"
        :label="label"
        :type="type"
        :hide-details="config.hideDetails"
        :required="required"
        v-bind="$attrs"
      ></v-textarea>
    </template>
    <template v-if="type == 'date'">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="value"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="view_model"
            :label="label"
            :id="id"
            :name="name"
            :placeholder="placeholder"
            :rules="rules"
            :required="required"
            :hide-details="config.hideDetails"
            outlined
            dark
            append-icon="chevron-down"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>

        <!--able to select date start for today :Start -->
        <v-date-picker
          v-if="config.allowedDates"
          v-model="view_model"
          :allowed-dates="allowedDates"
          :disabled="$attrs.disabled"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(view_model)">
            OK
          </v-btn>
        </v-date-picker>
        <!--able to select date start for today :End -->
        <!--able to select customize date before selected date :Start -->
        <v-date-picker
          v-else-if="config.customSelectBeforeDate"
          v-model="view_model"
          :allowed-dates="customBeforeAllowedDates"
          no-title
          scrollable
          :disabled="$attrs.disabled"
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(view_model)">
            OK
          </v-btn>
        </v-date-picker>
        <!--able to select customize date before selected date :End -->
        <!--able to select customize date after selected date :Start -->
        <v-date-picker
          v-else-if="config.customSelectAfterDate"
          v-model="view_model"
          :allowed-dates="customAfterAllowedDates"
          no-title
          scrollable
          :disabled="$attrs.disabled"
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(view_model)">
            OK
          </v-btn>
        </v-date-picker>
        <!--able to select customize date after selected date :End -->
        <!--able to select all date :Start -->
        <v-date-picker
          v-else
          v-model="view_model"
          no-title
          scrollable
          :disabled="$attrs.disabled"
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(view_model)">
            OK
          </v-btn>
        </v-date-picker>
        <!--able to select all date :End -->
      </v-menu>
    </template>
    <template v-if="type == 'compressFile'">
      <div>
        <p>{{ label }}</p>
      </div>
      <div class="upload-file-cont">
        <div
          v-if="!value"
          class="w-100 h-100 uploadBtn"
          @click="fileSelect"
          @drop.prevent="drop($event)"
        >
          <div v-if="!progress">
            <v-img src="/assets/image/icons/plus.png" height="50" width="50" />
          </div>
          <div v-else>
            {{ progress }}
          </div>
        </div>
        <div v-else class="w-100 h-100 imageCont">
          <img
            v-if="getIsImage"
            :src="getImage"
            width="100%"
            height="130"
            crossorigin="anonymous"
            style="object-fit: contain"
          />
          <div v-else class="bg-white w-100 h-100 imageCont">
            <div>
              <v-img
                src="/assets/image/icons/doc.png"
                height="50"
                width="50"
                contain
              />
            </div>
          </div>
          <v-img
            src="/assets/image/icons/closeBtn.png"
            height="50"
            width="50"
            class="close"
            @click="clearFile"
          />
        </div>
        <input
          ref="fileInput"
          type="file"
          id="uploader"
          :accept="config.accept"
          :multiple="config.multiple"
          style="display: none"
          @change="compressFile($event)"
        />
      </div>
    </template>

    <template v-if="type == 'file'">
      <div>
        <p>{{ label }}</p>
      </div>
      <div class="upload-file-cont">
        <div
          v-if="!value"
          class="w-100 h-100 uploadBtn"
          @click="fileSelect"
          @drop.prevent="drop($event)"
        >
          <div>
            <v-img src="/assets/image/icons/plus.png" height="50" width="50" />
          </div>
        </div>
        <div v-else class="w-100 h-100 imageCont">
          <img
            v-if="getIsImage"
            :src="getImage"
            width="100%"
            height="130"
            crossorigin="anonymous"
            style="object-fit: contain"
          />
          <v-img
            v-else-if="getIsVideo"
            src="/assets/image/icons/video.svg"
            width="70"
            height="70"
            contain
            @click="previewVideo"
          />
          <div v-else class="bg-white w-100 h-100 imageCont">
            <div>
              <v-img
                src="/assets/image/icons/doc.png"
                height="50"
                width="50"
                contain
              />
            </div>
          </div>
          <v-img
            src="/assets/image/icons/closeBtn.png"
            height="50"
            width="50"
            class="close"
            @click="clearFile"
          />
        </div>
        <input
          ref="fileInput"
          type="file"
          style="display: none"
          :accept="config.accept"
          :multiple="config.multiple"
          @change="drop($event)"
        />
      </div>
    </template>
    <template v-if="type == 'avatar'">
      <div class="upload-avatar-cont">
        <v-avatar
          v-if="!value"
          size="280"
          class="uploadAvatar"
          @click="fileSelect"
          @drop.prevent="drop($event)"
        >
          <v-img src="/assets/image/icons/Profile.png"></v-img>
        </v-avatar>
        <div v-else>
          <v-avatar class="uploadAvatar" :size="$isMobile() ? '200' : '280'">
            <img v-if="getIsImage" :src="getImage" crossorigin="anonymous" />
            <div v-else class="bg-white w-100 h-100 imageCont">
              <div>
                <v-img
                  src="/assets/image/icons/doc.png"
                  height="50"
                  width="50"
                  contain
                />
              </div>
            </div>
          </v-avatar>
          <v-icon dark @click="clearFile" class="closeIcon"> mdi-close </v-icon>
        </div>
        <input
          ref="fileInput"
          type="file"
          style="display: none"
          :accept="config.accept"
          :multiple="config.multiple"
          @change="drop($event)"
        />
      </div>
      <v-btn class="fit outline btn1 mt-3" v-if="!value" @click="fileSelect"
        >Choose a file</v-btn
      >
      <div class="mt-2">
        <p>{{ label }}</p>
      </div>
    </template>
    <div v-html="getFileName" v-if="showFileName" style="color: grey"></div>
    <AppPreviewVideoModal v-if="getIsVideo" :elemId="`modal_${id}`" />
  </div>
</template>
<script>
  import CustomSelect from '@/components/inputs/CustomSelect.vue';
  // import VideoService from '@/services/video.service.js';
  import { ANY } from '@/constants/constants';
  import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg/dist/ffmpeg.min.js';
  import { bus } from '@/main.js';
  import _ from 'lodash';

  export default {
    name: 'custom-input',
    components: { CustomSelect },
    props: {
      id: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        required: true
      },
      type: {
        type: String,
        required: true
      },
      required: {
        type: ANY,
        default: () => false
      },
      rules: {
        type: ANY,
        default: () => false
      },
      label: String,
      placeholder: String,
      counter: {
        type: ANY,
        default: () => false
      },
      value: { type: ANY, default: () => '' },
      config: {
        type: Object,
        default: () => ({})
      },
      errMsg: String,
      isValidated: {
        type: Boolean,
        default: () => false
      },
      showFileName: {
        type: Boolean,
        default: true
      },
      clearMediaConfirmation: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      // able and disable date selection :Start
      allowedDates(val) {
        let currentDate = new Date().toISOString().slice(0, 10);
        return currentDate <= val;
      },
      customBeforeAllowedDates(val) {
        let selectedDate = this.config.dateStart;
        return selectedDate >= val;
      },
      customAfterAllowedDates(val) {
        let selectedDate = this.config.dateStart;
        return selectedDate <= val;
      },
      // able and disable date selection :End
      convertToPercentage(decimalValue) {
        const percentage = decimalValue * 100;
        return percentage.toFixed(2); // Round to 2 decimal places
      },
      async compressFile(e) {
        // function generateRandomFileName(length) {
        //   const characters =
        //     'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        //   let randomText = '';
        //   for (let i = 0; i < length; i++) {
        //     const randomIndex = Math.floor(Math.random() * characters.length);
        //     randomText += characters[randomIndex];
        //   }
        //   return randomText + '.mp4';
        // }

        let result = false;

        const ffmpeg = createFFmpeg({
          log: true,
          progress: (params) => {
            this.progress = this.convertToPercentage(params.ratio) + '%';
          }
        });
        const transcode = async ({ target: { files } }) => {
          const { name } = files[0];
          await ffmpeg.load();
          ffmpeg.FS('writeFile', name, await fetchFile(files[0]));

          await ffmpeg.run(
            '-i',
            name,
            // '-t', // reduce video time to speed up compress time
            // '5', // reduce video time
            '-ss',
            '2.0',
            '-f',
            'mp4',
            'out.mp4'
          );
          const data = ffmpeg.FS('readFile', 'out.mp4');

          // // Do something with the processed video data, e.g., download it
          if (data) {
            const outputBlob = new Blob([data.buffer], { type: 'video/mp4' });
            const downloadUrl = URL.createObjectURL(outputBlob);
            const downloadLink = document.createElement('a');
            downloadLink.href = downloadUrl;
            downloadLink.download = 'processed_video.mp4';
            downloadLink.click();
          }
          return true;
        };

        result = await transcode(e);
        // console.log('result');
        console.log('result', result);
      },

      clearFile() {
        this.fileName = null;
        if (this.clearMediaConfirmation && this.value?.id) {
          this.openAppDialogInfo(
            '',
            'Confirm to remove?',
            '',
            [
              {
                text: 'Cancel',
                action: () => {
                  this.closeAppDialogInfo();
                }
              },
              {
                text: 'OK',
                class: 'btn3',
                action: () => {
                  this.closeAppDialogInfo();
                  this.$emit('clearFile', this.value);
                  this.$emit('input', null);
                }
              }
            ],
            false
          );
        } else {
          this.$emit('clearFile', this.value);
          this.$emit('input', null);
        }
      },
      async drop(e) {
        const file = e?.target?.files[0];

        var fileName = file.name;
        this.fileName = file.name;
        if (!file) {
          return;
        }
        if (/image/.test(file.type)) {
          const reader = new FileReader();
          reader.addEventListener('load', (e) => {
            this.$emit('input', { file, url: e.target.result, fileName });
          });
          reader.readAsDataURL(file);
        } else {
          this.$emit('input', { file, fileName });
        }
        this.$refs.fileInput.value = '';
      },
      fileSelect() {
        this.$refs.fileInput.click();
      },
      groupValue(pos) {
        return this.value[this.config.dataModel[pos]];
      },
      onGroupValue(pos, e) {
        // console.log(e);
        this.value[this.config.dataModel[pos]] =
          e?.target?.value || e?.value || e;
        return this.$emit('input', this.value);
      },
      onSelected(option) {
        this.selected = option.item;
        return this.$emit('input', option?.item);
      },

      onInputChange(value) {
        const { config } = this;
        if (value === '' || value === undefined) {
          return;
        }
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.$apollo
            .query({
              query: config.dataGQL,
              variables: {
                name: value
              }
            })
            .then((data) => {
              const resultData = data?.data[config.GQLname];
              this.suggestions = [
                {
                  data: resultData?.data || []
                }
              ];
            });
        }, this.debounceMilliseconds);
      },
      searchSelect() {
        this.selectItems = (this?.config?.items || []).filter((i) =>
          _.includes(i.text.toLowerCase(), this.searchTerm.toLowerCase())
        );

        return this.selectItems;
      },
      previewVideo() {
        if (!this.value) return;
        bus.$emit('openPreviewVideoModal', {
          videoSrc:
            this?.value?.media?.media_path ||
            this?.value?.media_path ||
            URL.createObjectURL(this?.value?.file),
          videoFile:
            this?.value?.media?.media_path ||
            this?.value?.media_path ||
            this?.value?.file,
          customModalName: `modal_${this.id}`
        });
      }
    },
    computed: {
      autosuggestValue() {
        return this.value.name;
      },
      commonType() {
        return ['text', 'datetime', 'number', 'email', 'password'];
      },
      getId() {
        return this.id || this.name;
      },
      getIsImage() {
        return (
          /image/.test(this?.value?.file?.type) ||
          /image/.test(this?.value?.media?.mime_type) ||
          /image/.test(this?.value?.mime_type)
        );
      },
      getIsVideo() {
        return (
          /video/.test(this?.value?.file?.type) ||
          /video/.test(this?.value?.media?.mime_type) ||
          /video/.test(this?.value?.mime_type) ||
          this?.value?.mime_type == 'application/octet-stream' ||
          this?.value?.media?.mime_type == 'application/octet-stream'
        );
      },
      getImage() {
        return (
          this?.value?.url ||
          this?.value?.media?.media_path ||
          this?.value?.media_path
        );
      },
      getFileName() {
        return this?.value?.fileName;
      },
      getTagList() {
        return this?.value?.tags || [];
      },
      getTagInput() {
        return this?.value?.text || '';
      },
      inputProps() {
        return {
          id: this.id,
          name: this.name,
          placeholder: this.placeholder,
          class: 'form-control custom-autosuggest'
        };
      },
      view_model: {
        get() {
          // console.log(this.name, this.value)
          return this.value;
        },
        set(newVal) {
          // console.log(this.name, newVal)
          this.$emit('input', newVal);
        }
      },
      view_model_selected: {
        get() {
          // console.log(this.value)
          return typeof this.value == 'object'
            ? this.config.options.filter((opt) =>
                (this.value || []).includes(opt?.value)
              )
            : this.config.options.find((i) => i?.value == this.value);
        },
        set(newVal) {
          this.$emit(
            'input',
            newVal?.value || (newVal || []).map((i) => i.value)
          );
        }
      },
      view_model_suggest: {
        get() {
          // console.log(this.value)
          return this.config.options.find((i) => i.value == this.value);
        },
        set(newVal) {
          // console.log(newVal);
          this.$emit('input', newVal.value);
        }
      },
      snowOption() {
        return {
          modules: {
            toolbar: [
              ['bold', 'italic', 'underline', 'strike'], // toggled buttons
              ['blockquote', 'code-block', 'link'] // remove formatting button
            ]
          },
          placeholder: this.placeholder
        };
      }
    },
    data() {
      return {
        suggestions: this?.config?.suggestions || [],
        debounceMilliseconds: 250,
        searchTerm: '',
        selectItems: this?.config?.items || [],
        timeout: null,
        menu: false,
        fileName: null,
        progress: ''
      };
    }
  };
</script>

<style lang="scss" scoped>
  // @import '@core/scss/vue/libs/vue-flatpicker.scss';
  pre {
    min-height: 295px;
    padding: 1.5rem;
    margin-bottom: 0;
    border-radius: 0.5rem;
  }
  .upload-file-cont {
    cursor: pointer;
    border: solid 1px #848484;
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }
  .uploadBtn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .imageCont {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative !important;
  }
  .close {
    position: absolute !important;
    right: 0;
    top: 0;
  }
  .bg-white {
    background-color: white;
    border-radius: 5px;
  }
  .upload-avatar-cont {
    width: 30%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
  .closeIcon {
    position: absolute !important;
  }
</style>
