import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

import shared from './shared.module';
import constants from './constants.module';
import auth from './auth.module';
import platformInfo from './platform-info.module';
import inbox from './inbox.module';
import homeScreen from './home-screen.module';
import payment from './payment.module';
import profile from './profile.module';
import jobOrganizer from './job-organizer.module';
import lookup from './lookup.module';
import applicationManagement from './application-management.module';
import newsroom from './newsroom.module';

export const dataStore = {
  state: {
    auth: []
  }
};

const dataState = createPersistedState({
  paths: ['auth.me']
});

export default new Vuex.Store({
  plugins: [dataState],
  modules: {
    shared,
    constants,
    auth,
    platformInfo,
    inbox,
    homeScreen,
    payment,
    profile,
    jobOrganizer,
    lookup,
    applicationManagement,
    newsroom
  }
});
