<template>
  <v-footer
    padless
    :min-height="$vuetify.breakpoint.mdAndUp ? 250 : 380"
    color="background"
    class="d-block surface--text cFooter"
  >
    <div class="footer__content-container container">
      <v-row
        no-gutters
        justify="space-between"
        class="w-100 pb-6 pt-md-12 pt-3"
      >
        <v-col cols="12" md="4" class="mb-md-0 mb-xs-6">
          <div class="d-flex mb-2">
            <router-link to="/">
              <img
                src="https://iamcasting.oss-ap-southeast-1.aliyuncs.com/web/logo-iamcasting-vertical-white.png"
                alt="iamcasting-logo"
                height="120"
                class="footer__logo mb-4 mb-md-0"
                crossorigin="anonymous"
              />
            </router-link>
            <div class="font-weight-light ml-3 mobile-container">
              <div class="mobile-wrapper">
                {{ platformInfoData.address }}
              </div>
              <div
                class="footer_platform-info-email cursor-pointer mobile-wrapper"
                @click="onCopy"
              >
                {{ platformInfoData.email }}
              </div>
              <div>
                {{ platformInfoData.phone }}
              </div>
            </div>
          </div>
          <div class="footer__social-media-img-container d-none d-md-block">
            <div class="font-weight-bold footer_title-text mb-2 mobile-title">
              {{ $t(`label.connectUs`) }}
            </div>
            <div class="w-100">
              <template v-for="(item, index) in socialMedia">
                <a
                  v-if="platformInfoData[item]"
                  :key="index"
                  :href="platformInfoData[item]"
                  target="_blank"
                  class="me-2"
                >
                  <img
                    :src="`/assets/image/icons/social-media__${
                      item === 'whatsapp' ? 'whatsApp' : item
                    }.png`"
                    class="footer__social-media-img"
                    width="40px"
                  />
                </a>
              </template>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <v-row no-gutters justify="space-between">
            <v-col
              class="mobile-title"
              cols="6"
              md="3"
              v-for="(item, index) in navList"
              :key="index"
            >
              <div class="font-weight-bold footer_title-text mb-2">
                {{ $t(`label.${item.name}`) }}
              </div>
              <div
                v-for="(child, childIndex) in item.children"
                :key="childIndex"
                class="mb-2"
              >
                <router-link
                  class="text-decoration-none surface--text font-weight-light text-subtitle-2"
                  :to="{ name: child.routeName }"
                >
                  {{ $t(`label.${child.name}`) }}
                </router-link>
              </div>
            </v-col>
            <v-col cols="12" md="3" order="first" order-md="last">
              <div class="mb-6 mb-md-0">
                <div
                  class="font-weight-bold footer_title-text mb-2 mobile-title"
                >
                  {{ $t(`label.iAmCastingApp`) }}
                </div>
                <!-- <div>
                  <img class="qr-image " :src="`${qrCodeUrl}`" height="130" />
                </div> -->
                <div class="mb-2">
                  <a
                    @click.stop="openQr = true"
                    class="text-decoration-none surface--text font-weight-light text-subtitle-2 d-none d-md-block"
                  >
                    {{ $t(`label.clickToScanQr`) }}
                  </a>
                </div>
                <a
                  :href="`${androidLink}`"
                  class="wh-100 mobile-app-img"
                  target="_blank"
                >
                  <img src="/assets/image/google-play-store.png" width="150" />
                </a>
                <a
                  :href="`${iosLink}`"
                  class="wh-100 mobile-app-img"
                  target="_blank"
                >
                  <img src="/assets/image/apple-app-store.png" width="150" />
                </a>
                <a
                  :href="`${huaweiLink}`"
                  class="wh-100 mobile-app-img"
                  target="_blank"
                >
                  <img src="/assets/image/huawei-app-gallery.png" width="150" />
                </a>
              </div>
              <div class="w-100 d-md-none mobile-title mb-6">
                <div
                  class="font-weight-bold footer_title-text mb-2 mobile-title"
                >
                  {{ $t(`label.connectUs`) }}
                </div>
                <template v-for="(item, index) in socialMedia">
                  <a
                    v-if="platformInfoData[item]"
                    :key="index"
                    :href="platformInfoData[item]"
                    target="_blank"
                    class="me-2"
                  >
                    <img
                      :src="`/assets/image/icons/social-media__${
                        item === 'whatsapp' ? 'whatsApp' : item
                      }.png`"
                      class="footer__social-media-img"
                      width="40px"
                    />
                  </a>
                </template>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div
        class="d-flex justify-center justify-md-end font-weight-light pb-md-6 text-subtitle-1"
      >
        <div>
          {{ `${$t('label.copyright')} © ${currentYear} IAmCasting` }}
          <!-- <template v-for="(child, childIndex) in bottomNav" >
            <router-link
              :key="childIndex"
              :to="{ name: child.routeName }"
              class="mx-8 text-decoration-none surface--text font-weight-light text-subtitle-2 text-lg-subtitle-1 text-xl-h6"
            >
              {{ $t(`label.${child.name}`) }}
            </router-link>
          </template> -->
        </div>
      </div>
      <v-dialog v-model="openQr" width="300" id="qrcode-modal">
        <!-- <div :style="{
        'background-image': `url(${qrCodeUrl})`,
        height: '200px'
      }">

        </div> -->
        <div>
          <img :src="qrCodeUrl" width="300px" crossorigin="anonymous" />
        </div>
      </v-dialog>
    </div>
  </v-footer>
</template>

<script>
  import { i18nHelper } from '@/utils';
  import { ROUTE_NAME } from '@/constants';

  export default {
    name: 'Footer',
    data: () => ({
      openQr: false,
      socialMedia: [
        'facebook',
        'instagram',
        'youtube',
        'whatsapp',
        'linkedin',
        'tiktok'
      ],
      navList: [
        {
          name: 'about',
          children: [
            {
              name: 'aboutUs',
              routeName: ROUTE_NAME.ABOUT_US
            },
            {
              name: 'termsOfUse',
              routeName: ROUTE_NAME.TERMS_OF_USE
            },
            {
              name: 'privacyPolicy',
              routeName: ROUTE_NAME.PRIVACY_POLICY
            }
          ]
        },
        {
          name: 'quickLinks',
          children: [
            {
              name: 'faq',
              routeName: ROUTE_NAME.FAQ
            },
            {
              name: 'newsroom',
              routeName: ROUTE_NAME.NEWSROOM
            }
          ]
        }
      ]
      // bottomNav: [
      //   {
      //     name: 'termsOfUse',
      //     routeName: ROUTE_NAME.TERMS_OF_USE
      //   },
      //   {
      //     name: 'privacyPolicy',
      //     routeName: ROUTE_NAME.PRIVACY_POLICY
      //   }
      // ]
    }),
    computed: {
      currentYear() {
        return new Date().getFullYear();
      },
      platformInfoData() {
        return this.$store.state.platformInfo.platformInfo.data;
      },
      getAppInfos() {
        return this.$store.getters['constants/getAppInfos'];
      },
      androidLink() {
        return this.getAppInfos.androidLink;
      },
      huaweiLink() {
        return this.getAppInfos.huaweiLink;
      },
      iosLink() {
        return this.getAppInfos.iosLink;
      },
      qrCodeUrl() {
        return this.getAppInfos.qrCodeUrl;
      }
    },
    methods: {
      onCopy() {
        this.$copyText(this.platformInfoData.email).then(
          () => {
            alert(i18nHelper.getMessage('label.copied'));
          },
          () => {
            alert(i18nHelper.getMessage('label.failToCopy'));
          }
        );
      }
    }
  };
</script>

<style lang="scss">
  .cFooter {
    position: static !important;
  }
  .footer_title-text {
    font-size: 1.15rem !important;
    line-height: 1.75rem;
  }
  .footer {
    .footer__content-container {
      height: 100%;
      border-top: 1px solid var(--v-divider-base);
      .footer_platform-info-email {
        &:hover {
          color: var(--v-primary-base);
        }
      }
    }
    .footer__logo {
      width: 100px;
    }

    .footer__social-media-img-container {
      a {
        height: fit-content;
      }
    }

    .footer__social-media-img {
      height: 56px;
      width: 56px;
    }
  }

  @media (max-width: 1903px) {
    .footer {
      .footer__logo {
        width: 95px;
      }
      .footer__social-media-img {
        height: 52px;
        width: 52px;
      }
    }
  }
  @media (max-width: 1599px) {
    .footer {
      .footer__logo {
        width: 90px;
      }
      .footer__social-media-img {
        height: 48px;
        width: 48px;
      }
    }
  }
  @media (max-width: 1535px) {
    .footer {
      .footer__logo {
        width: 85px;
      }
      .footer__social-media-img {
        height: 46px;
        width: 46px;
      }
    }
  }
  @media (max-width: 1439px) {
    .footer {
      .footer__logo {
        width: 80px;
      }
      .footer__social-media-img {
        height: 44px;
        width: 44px;
      }
    }
  }
  @media (max-width: 1335px) {
    .footer {
      .footer__logo {
        width: 75px;
      }
      .footer__social-media-img {
        height: 42px;
        width: 42px;
      }
    }
  }
  @media (max-width: 1263px) {
    .footer {
      .footer__logo {
        width: 70px;
      }
      .footer__social-media-img {
        height: 40px;
        width: 40px;
      }
    }
    .about-wrapper {
      max-width: 150px !important;
    }
  }
  @media (max-width: 959px) {
    .mobile-app-img {
      display: block;
      text-align: center;
    }
    .footer {
      .footer__logo {
        width: 65px;
      }
      .footer__social-media-img {
        height: 40px;
        width: 40px;
      }
    }
  }
  @media (max-width: 599px) {
    .footer {
      .footer__logo {
        width: 60px;
      }
      .footer__social-media-img {
        height: 36px;
        width: 36px;
      }
    }
  }
  @media (max-width: 959px) {
    .mobile-container {
      align-self: center;
    }
    .mobile-title {
      text-align: center;
    }
    .mobile-wrapper {
      margin-bottom: 20px;
    }
    .tablet-view {
      display: none;
    }
  }
  @media (min-width: 959px) {
    .desktop-view {
      display: none;
    }
    .about-us-wrapper {
      flex: 0 0 16.6666666667%;
      max-width: 12%;
    }
  }
</style>
