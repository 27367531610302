<template>
  <div class="custom-select" :tabindex="tabindex" @blur="open = false">
    <div class="selected" :class="{ open: open }" @click="open = !open">
      {{ getValue.title || getValue.description || placeholder }}
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div
        v-for="(option, i) of options"
        :key="i"
        @click="
          selected = option;
          open = false;
          $emit('input', option);
        "
      >
        {{ option.title || option.description }}
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'CustomSelect',
    props: {
      options: {
        type: Array,
        required: true
      },
      value: {
        type: Object,
        required: false,
        default: null
      },
      placeholder: {
        type: String,
        required: false,
        default: () => 'Select a value'
      },
      tabindex: {
        type: Number,
        required: false,
        default: 0
      }
    },
    computed: {
      getValue() {
        return typeof this.value == 'string' && this.value != ''
          ? this.options.find(
              (i) => i.value.toString() == this?.value?.toString()
            )
          : this.value || {};
      }
    },
    data() {
      return {
        open: false
      };
    }
  };
</script>
<style scoped>
  .custom-select {
    position: relative;
    width: 100%;
    text-align: left;
    outline: none;
    height: 47px;
    line-height: 47px;
  }

  .custom-select .selected {
    background-color: transparent;
    /* border-radius: 6px; */
    /* border: 1px solid #666666; */
    color: #fff;
    /* padding-left: 1em; */
    cursor: pointer;
    user-select: none;
  }

  .custom-select .selected.open {
    /* border: 1px solid #ad8225;
    border-radius: 6px 6px 0px 0px; */
  }

  .custom-select .selected:after {
    position: absolute;
    content: '';
    top: calc(50% - 5.66px);
    right: 0.1em;
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  .custom-select .open.selected:after {
    top: calc(50% - (5.66px / 2));
    transform: rotate(225deg);
  }

  .custom-select .items {
    color: #fff;
    border-radius: 0px 0px 6px 6px;
    overflow: hidden;
    /* border-right: 1px solid #ad8225;
    border-left: 1px solid #ad8225;
    border-bottom: 1px solid #ad8225; */
    position: absolute;
    background-color: #0a0a0a;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .custom-select .items div {
    color: #fff;
    padding-left: 1em;
    cursor: pointer;
    user-select: none;
  }

  .custom-select .items div:hover {
    background-color: #ad8225;
  }

  .selectHide {
    display: none;
  }
</style>
