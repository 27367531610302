export const ROUTE_NAME = Object.freeze({
  TEST_ONLY: 'test-only',
  HOME: 'home',
  JOB_ORGANIZER: 'job_organizer',
  APPLICATION_MANAGEMENT: 'application_management',
  JOB_DETAILS: 'job-details',
  CREATE_JOB: 'create_job',
  CREATE_JOB_DONE: 'create_job_done',
  CREATE_JOB_INVITATION: 'create_job_invitation',
  CREATE_JOB_INVITATION_DONE: 'create_job_invitation_done',
  TEASER: 'teaser',
  TEASER_REGISTER_SUCCESS: 'teaser-register-success',
  TERMS_OF_USE: 'terms-of-use',
  FAQ: 'faq',
  NEWSROOM: 'newsroom',
  GUARDIAN_CONSENT: 'guardian-consent',
  PRIVACY_POLICY: 'privacy-policy',
  ABOUT_US: 'about-us',
  SCAN: 'scan',
  SIGNUP: 'signup',
  SIGNUPFORM: 'signup-form',
  CREATEPASSWORD: 'create-password',
  SIGNUPDETAIL: 'signup-detail',
  NOTIFICATION: 'notification',
  PROFILE: 'profile',
  COMPANY_INFO: 'company-info',
  PAYMENT: 'payment',
  PAYMENT_GATEWAY: 'payment-gateway',
  PAYMENT_MEMBER_PLAN: 'payment-member-plan',
  PAYMENT_SUBSCRIPTION: 'payment-subscription',
  PAYMENT_HISTORY: 'payment-history',
  RATING_REVIEWS: 'rating-reviews',
  FOLLOWER_FOLLOWING: 'follower-following',
  CHANGE_PASSWORD: 'change-password',
  COMPANY_CODE: 'company-code',
  TALENT_ACCOUNT: 'talent-account',
  ADMIN_ACCOUNT: 'admin-account',
  DISPLAY_INFORMATIONS: 'display-informations',
  BLOCKED_ACCOUNT: 'blocked-account',
  CREATE_ADMIN: 'create-admin',
  PUSH_NOTIFICATIONS: 'push-notifications',
  DELETE_ACCOUNT: 'delete-account',
  LINKED_AGENCY: 'linked-agency',
  PROFILE_INFO: 'profile-info',
  POSTED_JOB: 'posted-job',
  SEARCH: 'search'
});
