import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-progress-sheet_wrapper"},[_c(VBottomSheet,{ref:"sheet",attrs:{"inset":"","hide-overlay":"","max-width":_vm.$isMobile() ? '100%' : '40%',"content-class":"app-progress-sheet","persistent":"","dark":"","eager":"","retain-focus":false},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VSheet,{staticClass:"px-4 progress-sheet"},[_c(VExpansionPanels,{attrs:{"flat":"","multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{attrs:{"expand-icon":"mdi-chevron-up"}},[(_vm.allCompressedComplete)?_c('div',{staticClass:"order-last"},[_c(VIcon,{on:{"click":function($event){_vm.show = !_vm.show}}},[_vm._v(" mdi-window-close ")])],1):_vm._e()]),_c(VExpansionPanelContent,{staticClass:"accordion-content"},[_c('div',{staticClass:"sheet-content"},_vm._l((_vm.items),function(item){return _c('div',{key:item.id,staticClass:"row no-gutters mb-2"},[_c('div',{staticClass:"col-1"},[_c(VIcon,{attrs:{"dark":""}},[_vm._v(" mdi-file-video ")])],1),_c('div',{staticClass:"col-11"},[_c('div',[_vm._v(" "+_vm._s(item.file.name)+" ")]),_c('div',{staticClass:"d-flex align-center"},[(item.percentage != 100 && ['compressing'].includes(item.status))?[_c(VProgressLinear,{staticClass:"mr-2",attrs:{"value":item.percentage}}),_c('div',{staticStyle:{"min-width":"70px"}},[_vm._v(_vm._s(item.percentage.toFixed(2))+"%")])]:(item.status == 'pending')?_c('span',{staticClass:"grey--text"},[_vm._v("Pending...")]):(item.status == 'failed')?_c('span',{staticClass:"grey--text text-capitalize"},[_vm._v(_vm._s(item.status)+_vm._s(item.message ? (": " + (item.message)) : ''))]):_c('span',{staticClass:"grey--text text-capitalize"},[_vm._v(_vm._s(item.status))])],2)])])}),0)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }