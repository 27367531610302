export const NEWSROOM_DATA = [
  {
    title: '洪爱玲出钱研发选角应用 感叹科技让她抓狂',
    imgTitle: 'Lianhe Zaobao',
    url: 'https://www.zaobao.com.sg/entertainment/story20231207-1454710'
  },
  {
    title:
      'Irene Ang launches revolutionary casting app, shares how no dream is too big',
    imgTitle: 'The New Paper',
    url: 'https://tnp.straitstimes.com/news/singapore/irene-ang-launches-revolutionary-casting-app-shares-how-no-dream-too-big '
  },
  {
    title: '推选角数码化800人参与',
    imgTitle: 'Shin Min Daily News',
    url: 'https://www.facebook.com/shinmindailynewsxinmingribao/posts/pfbid0tX3GSakwfwuP6Z3fdyfZwfjTp6dvJ7gxaWUMb2DSBTvkmB6ufPQCE8VeyFTp2SBql'
  },
  {
    title: 'Singapore-Thailand Co-Production ‘Baby Hero’ Launched at ATF ',
    imgTitle: 'Variety',
    url: 'https://variety.com/2023/film/asia/singapore-thailand-coproduction-baby-hero-atf-1235825400/ '
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'AsiaOne',
    url: 'https://www.asiaone.com/business/iamcasting-revolutionizes-media-industry-launch-new-casting-app-10th-singapore-media'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Yahoo Finance Singapore',
    url: 'https://sg.finance.yahoo.com/news/iamcasting-revolutionizes-media-industry-launch-090000502.html'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Vulcan Post Singapore',
    url: 'https://vulcanpost.com/media-outreach/?release_id=265848'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'SuperAdrianMe',
    url: 'https://www.superadrianme.com/media-outreach/iamcasting-revolutionizes-media-industry-with-launch-of-new-casting-app-at-10th-singapore-media-festival/'
  },
  {
    title:
      'MONEY FM 89.3 IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival, Business and Personal Finance Radio station in Singapore',
    imgTitle: 'Money FM 89.3',
    url: 'https://www.moneyfm893.sg/media-outreach/iamcasting-revolutionizes-media-industry-with-launch-of-new-casting-app-at-10th-singapore-media-festival-13711/'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Singapore Friendly',
    url: 'https://www.singaporefriendly.com/feeds.html?f=media-outreach&i=iamcasting-revolutionizes-media-industry-with-launch-of-new-casting-app-at-th-singapore-media-festival'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Asian Journeys',
    url: 'https://asianjourneys.com.sg/media-outreach?release_id=265848'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Weekender',
    url: 'https://weekender.com.sg/mediaoutreach/?release_id=265848'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Nanyang Post',
    url: 'http://sg.nanyangpost.com/p/mediaoutreach.html?release_id=265848'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Singapore News',
    url: 'https://www.singaporenewsblog.com/feeds.html?f=media-outreach&i=iamcasting-revolutionizes-media-industry-with-launch-of-new-casting-app-at-th-singapore-media-festival'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Singaporean Biz',
    url: 'https://www.singaporeanbiz.com/feeds.html?f=media-outreach&i=iamcasting-revolutionizes-media-industry-with-launch-of-new-casting-app-at-th-singapore-media-festival'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Hpility SG',
    url: 'https://www.hpility.sg/media-outreach/?release_id=265848'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry With Launch Of New Casting App At 10th Singapore Media Festival - Techspace Africa',
    imgTitle: 'TechSpace Africa',
    url: 'https://tech-space.africa/iamcasting-revolutionizes-media-industry-with-launch-of-new-casting-app-at-10th-singapore-media-festival/'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'IT News Africa',
    url: 'https://www.itnewsafrica.com/media-outreach/?release_id=265848'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival | Africa News Circle',
    imgTitle: 'Africa News Circle ',
    url: 'https://www.africanewscircle.com/?p=81161'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Yahoo Finance UK',
    url: 'https://uk.finance.yahoo.com/news/iamcasting-revolutionizes-media-industry-launch-090000502.html'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival - European Business Magazine',
    imgTitle: 'European Business Magazine',
    url: 'https://europeanbusinessmagazine.com/media-outreach/iamcasting-revolutionizes-media-industry-with-launch-of-new-casting-app-at-10th-singapore-media-festival/?utm_source=rss&utm_medium=rss&utm_campaign=iamcasting-revolutionizes-media-industry-with-launch-of-new-casting-app-at-10th-singapore-media-festival'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival : GoDubai.com',
    imgTitle: 'Go Dubai',
    url: 'https://www.godubai.com/2/7288/IAmCasting-Revolutionizes-Media-Industry-with-Launch-of-New-Casting-App-at-10th-Singapore-Media-Festival'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Yahoo Finance',
    url: 'https://finance.yahoo.com/news/iamcasting-revolutionizes-media-industry-launch-090000502.html'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Yahoo News Taiwan',
    url: 'https://tw.news.yahoo.com/news/iamcasting-revolutionizes-media-industry-launch-090000502.html'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Yahoo Finance Australia',
    url: 'https://au.finance.yahoo.com/news/iamcasting-revolutionizes-media-industry-launch-090000502.html'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival - Media OutReach Newswire',
    imgTitle: 'Sinchew Daily',
    url: 'https://www.sinchew.com.my/news/20231207/mysinchew/5180825'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Yahoo Finance Hong Kong',
    url: 'https://hk.finance.yahoo.com/news/iamcasting-revolutionizes-media-industry-launch-090000502.html'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'The Malay Mail',
    url: 'https://www.malaymail.com/news/money/mediaoutreach/2023/12/07/iamcasting-revolutionizes-media-industry-with-launch-of-new-casting-app-at-10th-singapore-media-festival/265848'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Yahoo Finance New Zealand',
    url: 'https://nz.finance.yahoo.com/news/iamcasting-revolutionizes-media-industry-launch-090000502.html'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival | Taiwan News | 2023-12-07 17:00:00',
    imgTitle: 'Taiwan News',
    url: 'https://www.taiwannews.com.tw/en/news/5054725'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'ET Net',
    url: 'https://www.etnet.com.hk/www/tc/news/mediaoutreach_news_detail.php?newsid=265848&page=1'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'The Hans India',
    url: 'https://www.thehansindia.com/mediaoutreach?release_id=265848'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Sina Hong Kong',
    url: 'https://portal.sina.com.hk/finance/finance-mediaoutreach/media-outreach/2023/12/07/660117/iamcasting-revolutionizes-media-industry-with-launch-of-new-casting-app-at-10th-singapore-media-festival/'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Yahoo News Malaysia',
    url: 'https://malaysia.news.yahoo.com/news/iamcasting-revolutionizes-media-industry-launch-090000502.html'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'PinoyTechSaga',
    url: 'https://pinoytechsaga.blogspot.com/p/media-outreach-tech-news.html?release_id=265848'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Focus Malaysia',
    url: 'https://focusmalaysia.my/media-outreach/?release_id=265848'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Viet Nam News',
    url: 'https://vietnamnews.vn/media-outreach/1637671/iamcasting-revolutionizes-media-industry-with-launch-of-new-casting-app-at-10th-singapore-media-festival.html'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Bao Song Ngu',
    url: 'https://baosongngu.net/media-outreach-en/?release_id=265848'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Bizz Buzz',
    url: 'https://www.bizzbuzz.news/mediaoutreach?release_id=265848'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Menit',
    url: 'https://www.menit.co.id/media-outreach?release_id=265848'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Haps Magazine Korea',
    url: 'https://www.hapskorea.com/mediaoutreach/?release_id=265848'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Vietnam Insider',
    url: 'https://vietnaminsider.vn/media-outreach/?release_id=265848'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Branding in Asia',
    url: 'https://www.brandinginasia.com/asia-news-by-media-outreach/?release_id=265848'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival | Macau Business',
    imgTitle: 'Macau Business',
    url: 'https://www.macaubusiness.com/iamcasting-revolutionizes-media-industry-with-launch-of-new-casting-app-at-10th-singapore-media-festival/'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Woah Malaysia',
    url: 'https://www.woah.my/media-outreach/?release_id=265848'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Journal Online',
    url: 'https://journal.com.ph/specials/media-outreach/?release_id=265848'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Modern Australian',
    url: 'https://www.modernaustralian.com/news/news-from-asia/33943-iamcasting-revolutionizes-media-industry-with-launch-of-new-casting-app-at-10th-singapore-media-festival'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Indonews',
    url: 'https://indonews.id/media-outreach.php?release_id=265848'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'The Sun',
    url: 'https://thesun.net.au/business/news-from-asia/3782-iamcasting-revolutionizes-media-industry-with-launch-of-new-casting-app-at-10th-singapore-media-festival'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Our Daily News Online',
    url: 'https://ourdailynewsonline.com/media-outreach/?release_id=265848'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival - HR ASIA',
    imgTitle: 'HR Asia',
    url: 'https://hr.asia/media-outreach/iamcasting-revolutionizes-media-industry-with-launch-of-new-casting-app-at-10th-singapore-media-festival/'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Business News This Week',
    url: 'https://businessnewsthisweek.com/corporate-news-media-outreach/?release_id=265848'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival - Global Business News - 台灣產經新聞網 - Taiwan Business News',
    imgTitle: 'Taiwan Net',
    url: 'https://news.taiwannet.com.tw/c8/115671/IAmCasting-Revolutionizes-Media-Industry-with-Launch-of-New-Casting-App-at-10th-Singapore-Media-Festival.html'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'The Insiders',
    url: 'https://www.theinsiders.com.au/business-news/news-from-asia/12661-iamcasting-revolutionizes-media-industry-with-launch-of-new-casting-app-at-10th-singapore-media-festival'
  },
  {
    title:
      'IT News Online - IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'IT News Online',
    url: 'https://www.itnewsonline.com/news/IAmCasting-Revolutionizes-Media-Industry-with-Launch-of-New-Casting-App-at-10th-Singapore-Media-Festival/19857'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'SME Magazine Online',
    url: 'https://sme.asia/iamcasting-revolutionizes-media-industry-with-launch-of-new-casting-app-at-10th-singapore-media-festival/'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Crypto Insider',
    url: 'https://cryptoinsider.asia/media-outreach/?release_id=265848'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Global Business Magazine',
    url: 'https://thegbm.com/media-outreach/?release_id=265848'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Times Tech',
    url: 'https://timestech.in/media-outreach/?release_id=265848'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'B2B-Cambodia',
    url: 'https://www.b2b-cambodia.com/news/media-outreach/?release_id=265848'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'The Express',
    url: 'https://theexpress.net.au/business/news-from-asia/4381-iamcasting-revolutionizes-media-industry-with-launch-of-new-casting-app-at-10th-singapore-media-festival'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'The Times',
    url: 'https://thetimes.com.au/news/news-from-asia/27368-iamcasting-revolutionizes-media-industry-with-launch-of-new-casting-app-at-10th-singapore-media-festival'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Beauty-Thai',
    url: 'https://www.beauty-thai.com/feeds.html?f=media-outreach&i=iamcasting-revolutionizes-media-industry-with-launch-of-new-casting-app-at-th-singapore-media-festival'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Iwaishin News',
    url: 'https://www.iwaishin.com/media-outreach-news/?release_id=265848'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Bizhub',
    url: 'http://bizhub.vn/media-outreach-newswire/iamcasting-revolutionizes-media-industry-with-launch-of-new-casting-app-at-10th-singapore-media-festival_350805.html'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'The Reporting Today',
    url: 'https://www.thereportingtoday.com/media-outreach/?release_id=265848'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Tec2Asia',
    url: 'https://www.tec2asia.com/feeds.html?f=media-outreach&i=iamcasting-revolutionizes-media-industry-with-launch-of-new-casting-app-at-th-singapore-media-festival'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Morningstar Hong Kong',
    url: 'https://hk.morningstar.com/AP/MEDIAOUTREACH/DEFAULT.ASPX?CLANG=ZH-HK&release_id=265848'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Daily Bulletin',
    url: 'https://dailybulletin.com.au/business/news-from-asia/77662-iamcasting-revolutionizes-media-industry-with-launch-of-new-casting-app-at-10th-singapore-media-festival'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Asia Insiders',
    url: 'https://asiainsiders.net/media-outreach/?release_id=265848'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival - Siam News Network',
    imgTitle: 'Siam News',
    url: 'https://www.siamnews.net/press-releases/65325-iamcasting-revolutionizes-media-industry-with-launch-of-new-casting-app-at-10th-singapore-media-festival/'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'The Chronicle',
    url: 'https://thechronicle.net.au/business/news-from-asia/4341-iamcasting-revolutionizes-media-industry-with-launch-of-new-casting-app-at-10th-singapore-media-festival'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'NZ Times',
    url: 'https://www.nz-times.com/feeds.html?f=media-outreach&i=iamcasting-revolutionizes-media-industry-with-launch-of-new-casting-app-at-th-singapore-media-festival'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival_Xinhua Finance Agency',
    imgTitle: 'Xinhua Finance',
    url: 'http://en.xfafinance.com/html/Companies/2023/945605.shtml'
  },
  {
    title:
      'IAmCasting Revolutionizes Media Industry with Launch of New Casting App at 10th Singapore Media Festival',
    imgTitle: 'Vietnam Star',
    url: 'https://vietnamstar.net/media-outreach/?release_id=265848'
  }
];
