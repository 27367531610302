<template>
  <div class="header">
    <v-app-bar
      app
      flat
      fixed
      :height="isScrollTop ? appBarHeight : $isMobile() ? 100 : 120"
      color="background"
      v-scroll="onScroll"
      id="header"
      style="background-color: white"
      class="border-bottom"
    >
      <!--  :class="withBorderBottom || isHomePage ? 'border-bottom' : ''" -->
      <div class="container">
        <div class="d-flex justify-space-between align-center wh-100">
          <router-link to="/">
            <!-- :class="{ large: isScrollTop && route.name == routeName.HOME }" -->
            <img
              src="https://iamcasting.oss-ap-southeast-1.aliyuncs.com/web/logo-iamcasting-horizontal-white.png"
              alt="iamcasting-logo"
              class="header__logo large"
              crossorigin="anonymous"
            />
          </router-link>
          <v-spacer></v-spacer>
          <div v-if="!$isMobile()" class="d-flex menu align-items-center">
            <template v-for="menu in getMenuItem">
              <div
                v-if="menu.title == 'GET THE APP'"
                :key="menu.title"
                class="cursor-pointer text-center menu-item get-app-btn"
                :class="menu.class"
                :style="menu.icon ? 'min-width:80px' : ''"
                tabindex="2"
                @click="isGetAppOpen = !isGetAppOpen"
              >
                <div>
                  <img
                    v-if="menu.icon"
                    :src="route.path == menu.path ? menu.activeIcon : menu.icon"
                    class="menu-item-icon"
                  />
                </div>
                <span
                  class="font-weight-bold menu-item-text"
                  :class="
                    (menu.icon ? 'sm-font pt-1' : '') +
                    (route.path == menu.path ? ' active' : '')
                  "
                  >{{ menu.title }}</span
                >
                <div
                  :class="'get-app-menu-bg ' + (isGetAppOpen ? 'active' : '')"
                ></div>
                <div
                  :class="[
                    'get-app-menu',
                    { long: isAuthenticated },
                    { active: isGetAppOpen }
                  ]"
                >
                  <h2>GET THE APP VIA</h2>
                  <img
                    src="https://iamcasting.oss-ap-southeast-1.aliyuncs.com/web/logo-iamcasting-solo.png"
                    alt="iamcasting-logo"
                    height="100"
                    class="footer__logo mb-2 mb-md-0 mt-1"
                    crossorigin="anonymous"
                  />
                  <br />
                  <div class="qr-code-container mb-2">
                    <div class="qr-code-wrapper">
                      <QRCode v-if="qr" :value="qr.url" :size="qr.size" />
                    </div>
                  </div>
                  <div>
                    <a :href="`${androidLink}`" target="_blank">
                      <img
                        src="/assets/image/google-play-store.png"
                        width="180"
                      />
                    </a>
                  </div>
                  <div>
                    <a :href="`${iosLink}`" target="_blank">
                      <img
                        src="/assets/image/apple-app-store.png"
                        width="180"
                      />
                    </a>
                  </div>
                  <div>
                    <a :href="`${huaweiLink}`" target="_blank">
                      <img
                        src="/assets/image/huawei-app-gallery.png"
                        width="180"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div
                v-else-if="menu.title == 'JOB POSTING'"
                :key="menu.title"
                class="cursor-pointer text-center menu-item clickable"
                :class="menu.class"
                :style="menu.icon ? 'min-width:50px;max-width:120px;' : ''"
              >
                <v-menu dark left z-index="100" offset-y>
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <div>
                        <img
                          v-if="menu.icon"
                          :src="
                            route.path == menu.path
                              ? menu.activeIcon
                              : menu.icon
                          "
                          class="menu-item-icon"
                        />
                      </div>
                      <span class="job_posting_text">
                        <div
                          class="font-weight-bold menu-item-text"
                          :class="
                            (menu.icon ? 'sm-font' : '') +
                            (route.path == menu.path ? ' active' : '')
                          "
                        >
                          {{ menu.title }}
                        </div>
                        <v-icon dark>mdi-chevron-down</v-icon>
                      </span>
                    </div>
                  </template>
                  <v-list>
                    <v-list-item @click="$router.push('/')">
                      <v-list-item-title class="px-8">
                        Job Posting Feed
                      </v-list-item-title>
                    </v-list-item>
                    <hr class="divider" />
                    <v-list-item @click="$router.push('/posted-job')">
                      <v-list-item-title class="px-8"
                        >My Posted Job</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <div
                v-else
                :key="menu.title"
                class="cursor-pointer text-center menu-item"
                :class="menu.class"
                :style="menu.icon ? 'min-width:50px' : ''"
                @click="menu.action"
              >
                <div>
                  <img
                    v-if="menu.icon"
                    :src="route.path == menu.path ? menu.activeIcon : menu.icon"
                    class="menu-item-icon"
                  />
                  <div
                    class="unreadIcon"
                    v-if="
                      ['notifications', 'messages'].includes(menu.name) &&
                      (menu.name == 'messages'
                        ? unreadMessages
                        : unreadNotifications)
                    "
                  >
                    <v-icon color="primary" class="icon" size="15">
                      mdi-checkbox-blank-circle
                    </v-icon>
                  </div>
                </div>

                <span
                  class="font-weight-bold menu-item-text"
                  :class="
                    (menu.icon ? 'sm-font pt-1' : '') +
                    (route.path == menu.path ? ' active' : '')
                  "
                  >{{ menu.title }}</span
                >
              </div>
            </template>
            <!-- <div
              v-if="isAuthenticated && !(user.role === 'agency')"
              class="mx-0 font-weight-bold sm-font text-center mb-4 profile-btn"
              tabindex="1"
              @blur="isProfileOpen = false"
              @click="isProfileOpen = !isProfileOpen"
            >
              <v-avatar size="50">
                <v-img :src="profileImage" />
              </v-avatar>
              <br />
              Profile
              <div :class="'profile-menu ' + (isProfileOpen ? 'active' : '')">
                <div class="mt-2">{{ user.fname }}</div>
                <div>{{ user.email }}</div>
                <hr class="divider my-3" />
                <div class="cursor-pointer" @click="this.logout">Logout</div>
              </div>
            </div> -->
            <div
              v-if="isAuthenticated"
              class="mx-5 font-weight-bold sm-font text-center profile-btn"
              tabindex="1"
              @blur="isProfileOpen = false"
            >
              <v-avatar
                size="50"
                @click="$router.replace({ path: '/profile' })"
              >
                <img crossorigin="anonymous" :src="profileImage" width="100%" />
              </v-avatar>
              <br />
              <span
                :class="
                  route.path === '/profile' ? ' pink2--text' : 'whiteFont'
                "
                @click="isProfileOpen = !isProfileOpen"
              >
                Profile
                <v-icon dark>mdi-chevron-down</v-icon>
              </span>
              <div :class="'profile-menu ' + (isProfileOpen ? 'active' : '')">
                <div
                  v-for="(item, index) in navBarContents"
                  :key="index"
                  class="py-2 text-left"
                >
                  <div class="mb-2 text-capitalize font-weight-light">
                    {{ item.section }}
                  </div>
                  <template v-for="(child, idx) in item.children">
                    <div
                      v-if="!child.custom"
                      class="text-uppercase d-flex justify-space-between font-weight-bold px-2 navBarContent"
                      :key="idx"
                      @click="child.action"
                    >
                      <div>
                        {{ child.title }}
                      </div>
                    </div>
                    <div
                      v-else-if="child.custom && child.key == 'profile_info'"
                      :key="idx"
                      class="text-uppercase d-flex font-weight-bold px-2 navBarContent"
                    >
                      <div @click="$router.push('/profile')">
                        {{ child.title }}
                      </div>
                      <div class="ps-2">
                        [
                        <span class="pink2--text edit-btn" @click="child.action"
                          >{{ $t('label.edit') }}
                        </span>
                        ]
                      </div>
                    </div>
                  </template>
                </div>
                <hr class="divider my-2" />
                <div
                  class="text-uppercase font-weight-bold mt-5 mb-3 px-2 navBarContent text-left"
                  @click="logout()"
                >
                  Logout
                </div>
                <div
                  v-if="!me.is_admin_login"
                  class="text-uppercase font-weight-bold mb-2 px-2 navBarContent text-left"
                  @click="$router.push('/delete-account')"
                >
                  Delete Account
                </div>
              </div>
            </div>
          </div>
          <div v-else class="mobile-view-navbar">
            <v-icon dark @click="isOpened = true">mdi-menu</v-icon>
            <div
              v-if="isAuthenticated"
              class="mx-0 ml-3 font-weight-bold sm-font text-center profile-btn"
              tabindex="1"
              @blur="isProfileOpen = false"
            >
              <v-avatar
                size="50"
                @click="$router.replace({ path: '/profile' })"
                class="mb-2"
              >
                <img :src="profileImage" crossorigin="anonymous" />
              </v-avatar>
              <br />
              <span
                :class="
                  route.path === '/profile' ? ' pink2--text' : 'whiteFont'
                "
                class="text-center ml-1"
                @click="isProfileOpen = !isProfileOpen"
              >
                Profile
                <v-icon dark>mdi-chevron-down</v-icon>
              </span>
              <div :class="'profile-menu ' + (isProfileOpen ? 'active' : '')">
                <div
                  v-for="(item, index) in navBarContents"
                  :key="index"
                  class="py-2 text-left"
                >
                  <div class="mb-2 text-capitalize font-weight-light">
                    {{ item.section }}
                  </div>
                  <template v-for="(child, idx) in item.children">
                    <div
                      v-if="!child.custom"
                      class="text-uppercase d-flex justify-space-between font-weight-bold px-2 navBarContent"
                      :key="idx"
                      @click="child.action"
                    >
                      <div>
                        {{ child.title }}
                      </div>
                    </div>
                    <div
                      v-else-if="child.custom && child.key == 'profile_info'"
                      :key="idx"
                      class="text-uppercase d-flex font-weight-bold px-2 navBarContent"
                    >
                      <div @click="$router.push('/profile')">
                        {{ child.title }}
                      </div>
                      <div class="ps-2">
                        [
                        <span class="pink2--text edit-btn" @click="child.action"
                          >{{ $t('label.edit') }}
                        </span>
                        ]
                      </div>
                    </div>
                  </template>
                </div>
                <hr class="divider my-2" />
                <div
                  class="text-uppercase font-weight-bold mt-5 mb-3 px-2 navBarContent text-left"
                  @click="logout()"
                >
                  Logout
                </div>
                <div
                  class="text-uppercase font-weight-bold mb-2 px-2 navBarContent text-left"
                  @click="$router.push('/delete-account')"
                >
                  Delete Account
                </div>
              </div>

              <!-- <div :class="'profile-menu ' + (isProfileOpen ? 'active' : '')">
                <div>{{ user.fname }}</div>
                <div>{{ user.email }}</div>
                <hr class="divider my-2" />
                <div class="cursor-pointer" @click="this.logout">Logout</div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </v-app-bar>

    <!-- 12.10.23: hide newsfeed -->
    <template v-if="false">
      <div
        v-if="isHomePage && !$isMobile()"
        class="row text-center topTab sticky no-gutters"
        :style="{
          top: homepageTabHeight
        }"
      >
        <div
          class="col-md-6"
          :class="homePageTab == 0 ? ' pink2--text selected' : ''"
          @click="homePageTab = 0"
        >
          <h1 class="text-uppercase py-2">Job Posting</h1>
        </div>
        <div
          class="col-md-6"
          :class="homePageTab == 1 ? ' pink2--text selected' : ''"
          @click="homePageTab = 1"
        >
          <h1 class="text-uppercase py-2">Newsfeed</h1>
        </div>
      </div>
      <div
        class="d-flex text-center topTab sticky py-3 px-2"
        :style="{
          top: homepageTabHeight,
          'overflow-x': 'auto'
        }"
        v-if="isHomePage && $isMobile()"
      >
        <div
          class="pt-1 px-2"
          :class="homePageTab == 0 ? ' pink2--text selected' : ''"
          @click="homePageTab = 0"
        >
          <h2 class="text-uppercase">Job Posting</h2>
        </div>
        <div
          class="pt-1 px-2 text-right"
          :class="homePageTab == 1 ? ' pink2--text selected' : ''"
          @click="homePageTab = 1"
        >
          <h2 class="text-uppercase">Newsfeed</h2>
        </div>
      </div>
    </template>

    <div
      :class="
        'menuDrawer d-flex justify-space-between ' + (isOpened ? 'opened' : '')
      "
    >
      <div class="w-100 align-self-start">
        <div v-for="menu in getMenuItem" :key="menu.title" @click="menu.action">
          <div v-if="menu.title == 'JOB POSTING'">
            <v-row no-gutters class="mb-2">
              <v-expansion-panels accordion dark flat>
                <v-expansion-panel style="background-color: black">
                  <v-expansion-panel-header class="expansion-header">
                    <p class="text-uppercase">
                      {{ menu.title }}
                    </p>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div @click="jobPostingRedirectTo('/')" class="mb-1">
                      Job Posting Feed
                    </div>
                    <div @click="jobPostingRedirectTo('/posted-job')">
                      My Posted Job
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </div>
          <div
            v-else-if="menu.title == 'GET THE APP'"
            class="get-app-btn"
            tabindex="2"
            @click="isGetAppOpen = !isGetAppOpen"
          >
            <p>{{ menu.title }}</p>
            <div :class="'get-app-menu ' + (isGetAppOpen ? 'active' : '')">
              <h2>GET THE APP VIA</h2>
              <br />
              <div class="qr-code-container mb-2">
                <div class="qr-code-wrapper">
                  <QRCode v-if="qr" :value="qr.url" :size="qr.size" />
                </div>
              </div>
              <div class="d-flex justify-center mb-2">
                <a
                  :href="`${androidLink}`"
                  class="app-btn-link"
                  target="_blank"
                >
                  <img src="/assets/image/google-play-store.png" width="180" />
                </a>
              </div>
              <div class="d-flex justify-center mb-2">
                <a
                  :href="`${androidLink}`"
                  class="app-btn-link"
                  target="_blank"
                >
                  <img src="/assets/image/apple-app-store.png" width="180" />
                </a>
              </div>
              <div class="d-flex justify-center mb-2">
                <a
                  :href="`${androidLink}`"
                  class="app-btn-link"
                  target="_blank"
                >
                  <img src="/assets/image/huawei-app-gallery.png" width="180" />
                </a>
              </div>
            </div>
          </div>
          <p @click="isOpened = false" v-else>{{ menu.title }}</p>
        </div>
      </div>
      <v-divider />
      <div @click="isOpened = false" class="mt-12">
        <p><v-icon dark class="mr-5">mdi-close</v-icon>Close</p>
      </div>
    </div>
    <div
      :class="
        'menuDrawer profileNavBar ' + (isProfileNavBarOpened ? 'opened' : '')
      "
      :style="{
        width: $isMobile() ? '85% !important' : '25% !important'
      }"
    >
      <div class="navbar-wrapper">
        <div @click="isProfileNavBarOpened = false" class="text-right mt-5">
          <span class="font-weight-bold"><v-icon dark>mdi-close</v-icon></span>
        </div>
        <div v-for="(item, index) in navBarContents" :key="index" class="py-2">
          <div class="mb-2">{{ item.section }}</div>
          <template v-for="(child, idx) in item.children">
            <div
              class="text-uppercase d-flex justify-space-between font-weight-bold py-2 px-2 navBarContent"
              :key="idx"
              @click="child.action"
            >
              <div>
                {{ child.title }}
              </div>
              <div>
                <v-icon color="white" class="icon"> mdi-chevron-right </v-icon>
              </div>
            </div>
          </template>
        </div>
        <hr class="divider my-2" />
        <div
          class="text-uppercase font-weight-bold mt-5 mb-3 py-2 px-2 navBarContent"
          @click="logout()"
        >
          Logout
        </div>
        <div
          class="text-uppercase font-weight-bold py-2 px-2 navBarContent"
          @click="
            $router.push('/delete-account');
            isProfileNavBarOpened = false;
          "
        >
          Delete Account
        </div>
      </div>
    </div>

    <ModalBase id="login" @closeModal="isLogin = false"
      ><LoginForm v-if="isLogin" @closeModal="isLogin = false"
    /></ModalBase>
  </div>
</template>

<script>
  import ModalBase from '@/components/base/ModalBase';
  import LoginForm from '@/components/auth/loginForm';
  import { bus } from '@/main.js';
  import { mapGetters } from 'vuex';
  import {
    AUTH_IS_AUTHENTICATED,
    AUTH_LOGOUT,
    AUTH_INITIAL_ME_STATE
  } from '@/store/auth.module';
  import {
    HOME_SCREENS_GET_UNREAD_NOTIFICATIONS,
    // HOME_SCREENS_INITIAL_GET_UNREAD_NOTIFICATIONS_STATE,
    HOME_SCREENS_GET_UNREAD_MESSAGES
    // HOME_SCREENS_INITIAL_GET_UNREAD_MESSAGES_STATE
  } from '@/store/home-screen.module';
  import { ROUTE_NAME } from '@/constants';
  import QRCode from 'qrcode.vue';

  export default {
    name: 'Header',
    components: {
      ModalBase,
      LoginForm,
      QRCode
    },
    props: {
      withBorderBottom: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      isScrollTop: true,
      isOpened: false,
      isLogin: false,
      isJobPostingOpened: false,
      // enum: Login, Sign up
      modalType: 'login',
      isProfileOpen: false,
      isGetAppOpen: false,
      routeName: ROUTE_NAME,
      isProfileNavBarOpened: false,
      unread: {
        notifications: 0,
        messages: 0
      },
      homePageTab: 0
    }),
    computed: {
      ...mapGetters({
        isAuthenticated: [AUTH_IS_AUTHENTICATED],
        me: ['auth/me'],
        getAppInfos: ['constants/getAppInfos']
      }),
      unreadNotifications() {
        return (
          this.$store.state.homeScreen.unreadNotifications.data?.unread_count ||
          0
        );
      },
      unreadMessages() {
        return (
          this.$store.state.homeScreen.unreadMessages.data?.unread_count || 0
        );
      },
      androidLink() {
        return (
          this?.getAppInfos?.androidLink ??
          'https://play.google.com/store/apps/details?id=com.volservers.tadaa'
        );
      },
      huaweiLink() {
        return (
          this?.getAppInfos?.huaweiLink ??
          'https://appgallery.huawei.com/app/C105448671'
        );
      },
      iosLink() {
        return (
          this?.getAppInfos?.iosLink ??
          'https://apps.apple.com/bh/app/tadaa-casting/id1607466245'
        );
      },
      qr() {
        const result = this?.getAppInfos?.qrCodeUrl
          ? {
              url: this?.getAppInfos?.qrCodeUrl,
              size: 100
            }
          : null;
        return result;
      },
      route() {
        return this.$route;
      },
      routePath() {
        return this.$route.fullPath;
      },
      appBarHeight() {
        if (this.$vuetify.breakpoint.xlOnly) {
          return '150px';
        } else if (this.$vuetify.breakpoint.lgAndUp) {
          return '140px';
        } else if (this.$vuetify.breakpoint.mdAndUp) {
          return '130px';
        } else if (this.$vuetify.breakpoint.smAndDown) {
          return !this.$isMobile() && this.isAuthenticated ? '130px' : '100px';
        }

        return '220px';
      },
      homepageTabHeight() {
        return this.isScrollTop
          ? this.appBarHeight
          : this.$isMobile()
          ? '100px'
          : '120px';
      },
      profileImage() {
        return (
          this?.me?.profile?.company_logo_file?.media_path ||
          this?.me?.profile?.headshot_file?.media_path ||
          this?.$store?.getters['constants/avatarImageUrl']
        );
      },
      user() {
        return {
          fname: this?.me?.fname,
          email: this?.me?.email,
          role: this?.me?.role
        };
      },
      isHomePage() {
        return this.isAuthenticated && this.route.name == this.routeName.HOME;
      },
      getMenuItem() {
        if (this.isAuthenticated) {
          const talentRole =
            this.user.role === 'talent' || this.user.role === 'agency';
          const jobPostingMenu = {
            title: 'JOB POSTING',
            icon: '/assets/image/icons/job_posting.png',
            activeIcon: '/assets/image/icons/job_posting.png',
            class: 'align-self-start',
            action: () => {
              this.isJobPostingOpened = !this.isJobPostingOpened;
            }
          };
          const menuItemArray = [
            {
              title: 'GET THE APP',
              icon: '/assets/image/icons/get_the_app.png',
              activeIcon: '/assets/image/icons/get_the_app.png',
              class: 'align-self-start',
              action: () => {}
            },
            // {
            //   title: 'Calendar',
            //   path: '/calendar',
            //   icon: '/assets/image/icons/calendar.png',
            //   activeIcon: '/assets/image/icons/calendarActive.png',
            //   class: 'align-self-start',
            //   action: () => {
            //     this.$router.push('/calendar');
            //     this.isOpened = false;
            //   }
            // },
            {
              title: 'Search',
              name: 'search',
              icon: '/assets/image/icons/search.png',
              activeIcon: '/assets/image/icons/searchActive.png',
              path: '/search',
              class: 'align-self-start',
              action: () => {
                this.$router.push({
                  name: 'search'
                });
                this.isOpened = false;
              }
            },
            {
              title: 'Job Organizer',
              name: 'job_organizer',
              icon: '/assets/image/icons/jobOrganizer.png',
              activeIcon: '/assets/image/icons/jobOrganizerActive.png',
              path: '/job_organizer',
              class: 'align-self-start',
              action: () => {
                this.$router.push({
                  name: 'job_organizer',
                  params: { type: 'on-going' }
                });
                this.isOpened = false;
              }
            },
            {
              title: 'Application Management',
              name: 'application_management',
              path: '/application_management',
              icon: '/assets/image/icons/applicationManagement.png',
              activeIcon: '/assets/image/icons/applicationActive.png',
              class: 'align-self-start',
              action: () => {
                this.$router.push('/application_management');
                this.isOpened = false;
              }
            },
            {
              title: 'Notification',
              name: 'notifications',
              path: '/notification',
              icon: '/assets/image/icons/inbox.png',
              activeIcon: '/assets/image/icons/inboxActive.png',
              class: 'align-self-start',
              action: () => {
                this.$router.push('/notification');
                this.isOpened = false;
              }
            },
            {
              title: 'Chat',
              name: 'messages',
              path: '/chat',
              icon: '/assets/image/icons/chat.png',
              activeIcon: '/assets/image/icons/chatActive.png',
              class: 'align-self-start',
              action: () => {
                this.$openChat();
              }
            }
          ];
          return talentRole
            ? menuItemArray
            : [jobPostingMenu, ...menuItemArray];
        }
        return [
          {
            title: 'GET THE APP',
            action: () => {}
          },
          {
            title: 'SIGN UP',
            action: () => {
              this.$router.push('/signup');
            }
          },
          {
            title: 'LOGIN',
            action: () => {
              this.login();
              this.isOpened = false;
            }
          }
        ];
      },
      navBarContents() {
        let contents = [
          {
            section: 'Privacy',
            children: [
              {
                title: 'Display Information',
                action: () => {
                  this.$router.push('/display-informations');
                  this.isProfileNavBarOpened = false;
                }
              }
            ]
          },
          {
            section: 'Settings',
            children: [
              {
                title: 'Push Notification',
                action: () => {
                  this.$router.push('/push-notifications');
                  this.isProfileNavBarOpened = false;
                }
              }
            ]
          }
        ];
        if (!this.me.is_admin_login) {
          const paymentSection = contents.find((x) => x.section == 'Settings');
          paymentSection.children.push({
            title: 'My Subscriptions',
            action: () => {
              this.$router.push('/payment');
              this.isProfileNavBarOpened = false;
            }
          });
        }
        ['talent'].includes(this.me.role)
          ? contents.splice(0, 0, {
              section: 'Profile',
              children: [
                {
                  title: 'Profile Info',
                  custom: true,
                  key: 'profile_info',
                  action: () => {
                    this.$router.push('/profile-info');
                    this.isProfileNavBarOpened = false;
                  }
                },
                {
                  title: 'Linked Agency',
                  action: () => {
                    this.$router.push('/linked-agency');
                    this.isProfileNavBarOpened = false;
                  }
                }
              ]
            })
          : contents.splice(0, 0, {
              section: 'Profile',
              children: [
                {
                  title: ['freelancer'].includes(this.me.role)
                    ? 'Profile Info'
                    : 'Company Info',
                  custom: true,
                  key: 'profile_info',
                  action: () => {
                    this.$router.push('/company-info');
                    this.isProfileNavBarOpened = false;
                  }
                }
              ]
            });

        if (
          ['production_house', 'agency'].includes(this.me.role) &&
          !this.me.is_admin_login
        ) {
          contents.splice(1, 0, {
            section: 'Other Accounts',
            children: [
              {
                title: 'Admin Account',
                action: () => {
                  this.$router.push('/admin-account');
                  this.isProfileNavBarOpened = false;
                }
              }
            ]
          });
        }

        if (this.me.role === 'agency') {
          contents[0].children.splice(1, 0, {
            title: 'Company Code',
            action: () => {
              this.$router.push('/company-code');
              this.isProfileNavBarOpened = false;
            }
          });

          contents[1].children.splice(1, 0, {
            title: 'Talent Account',
            action: () => {
              this.$router.push('/talent-account');
              this.isProfileNavBarOpened = false;
            }
          });
        }
        return contents;
      }
    },
    watch: {
      $route() {
        this.isGetAppOpen = false;
      },
      isProfileNavBarOpened(val) {
        if (!val) {
          bus.$emit('closeNavBar', val);
        }
      },
      homePageTab(val) {
        bus.$emit('changeHomePageTabBar', val);
      },
      isAuthenticated(val) {
        if (val) this.getUnreads();
      },
      routePath() {
        this.isProfileNavBarOpened = false;
      },
      appBarHeight(newVal, oldVal) {
        if (oldVal === '100px') this.isOpened = false;
      }
    },
    methods: {
      onScroll() {
        let minOverLapHeight = 80;

        this.isScrollTop = window.scrollY < minOverLapHeight;
        this.isScrollTop = window.scrollY < 80;
      },
      async getUnreads() {
        await this.$store.dispatch(HOME_SCREENS_GET_UNREAD_NOTIFICATIONS);
        await this.$store.dispatch(HOME_SCREENS_GET_UNREAD_MESSAGES);
      },
      login() {
        this.isLogin = true;
        this.$openModal('login');
      },
      logout() {
        this.isProfileNavBarOpened = false;
        this.$store.dispatch(AUTH_LOGOUT, { isSessionExpired: false });
        this.$nextTick(() => {
          this.$store.dispatch(AUTH_INITIAL_ME_STATE);
        });
      },
      jobPostingRedirectTo(path) {
        this.$router.push(path);
        this.isJobPostingOpened = false;
        this.isOpened = false;
      }
    },
    mounted() {
      if (this.isAuthenticated) {
        this.getUnreads();
        setInterval(() => {
          this.getUnreads();
        }, 60000);
      }
    },
    created() {
      bus.$on('openNavBar', (data) => {
        this.isProfileNavBarOpened = data;
      });
    }
  };
</script>

<style lang="scss" scoped>
  .topTab {
    cursor: pointer;
    background-color: black;
    width: 100%;
    align-items: end;
    z-index: 4;
    justify-content: space-evenly;
    &.sticky {
      position: fixed;
    }
  }

  .header__logo {
    transition: all 0.3s ease-in-out;
    width: 180px;
    &.large {
      width: 200px;
    }
  }
  .menu {
    color: white;
    text-transform: uppercase;
    align-items: center;
  }
  .menu-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100px;
    margin: 0 8px;
    font-size: 0.8rem;
    & .active {
      color: #ff2ff2;
    }
  }
  .menu-item-icon {
    --size: 2.75rem;
    height: var(--size);
    width: var(--size);
    object-fit: contain;
  }
  .menu-item-text {
    font-size: 1em;
  }
  .menuDrawer {
    position: fixed;
    overflow: auto;
    width: 100%;
    z-index: 10000;
    right: -100%;
    height: 100%;
    background-color: black;
    color: white;
    padding: 2rem;
    // display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & p {
      font-weight: bold;
      font-size: 1rem;
      // text-align: center;
    }
    &.profileNavBar {
      box-shadow: 0px 16px 24px 2px rgb(255 51 246);
      padding: 10px 5px;
      .navbar-wrapper {
        margin: auto;
        width: 90%;
      }
    }
    &.opened {
      animation: swipeIn ease-in-out 0.5s forwards;
    }
  }

  @keyframes swipeIn {
    from {
      right: -100%;
    }
    to {
      right: 0;
    }
  }
  .job_posting_text {
    display: flex;
    align-items: center;
  }
  .profile-btn {
    position: relative;
    & .profile-menu {
      color: white;
      position: absolute;
      background-color: #242424;
      border: 1px outset #707070;
      border-radius: 4%;
      padding: 15px 20px;
      width: 250px;
      right: 0;
      top: 90px;
      display: none;
      &.active {
        display: block;
        z-index: 1000 !important;
      }

      .edit-btn:hover {
        text-shadow: 0 0 #ff2ff2;
        text-decoration: underline;
      }
    }
  }
  .get-app-btn {
    position: relative;
    cursor: pointer;
    & .get-app-menu-bg {
      z-index: 99;
      position: fixed;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.5);
      &.active {
        width: 100vw;
        height: 100vh;
      }
    }
    & .get-app-menu {
      --menu-height: 460px;
      z-index: 100;
      color: white;
      position: absolute;
      background-color: #242424;
      padding: 15px 20px;
      width: 400px;
      height: var(--menu-height);
      right: 0;
      bottom: calc(var(--menu-height) * -1);
      text-align: center;
      display: none;
      &.active {
        display: block;
      }
    }
  }
  .mobile-view-navbar {
    display: flex;
    align-items: center;
  }
  .app-btn-link {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .v-expansion-panel-content__wrap > * {
    cursor: pointer;
  }

  button.expansion-header {
    min-height: auto;
    margin-bottom: 16px;
    p {
      margin-bottom: 0;
    }

    &.v-expansion-panel-header--active {
      margin-bottom: 1rem;
    }
  }

  ::v-deep .v-expansion-panel-header {
    padding: 0px !important;
    align-items: start;
  }

  .navBarContent {
    cursor: pointer;

    &:hover {
      background-color: #202020;
    }
  }

  .whiteFont {
    color: white;
  }

  .unreadIcon {
    position: absolute;
    transform: translate(200%, -220%);
  }

  .clickable {
    cursor: pointer;
  }

  @media (max-width: 1903px) {
    .header__logo.large {
      width: 235px;
    }
  }
  @media (max-width: 1599px) {
    .header__logo.large {
      width: 220px;
    }
  }
  @media (max-width: 1535px) {
    .header__logo.large {
      width: 205px;
    }
  }
  @media (max-width: 1439px) {
    .header__logo.large {
      width: 190px;
    }
  }
  @media (max-width: 1335px) {
    .header__logo.large {
      width: 180px;
    }
  }
  @media (max-width: 1263px) {
    .header__logo.large {
      width: 165px;
    }
    .get-app-btn {
      & .get-app-menu.long {
        right: unset !important;
        left: 0;
      }
    }
    .job_posting_text {
      padding-top: 4px;
      display: block;
      align-items: unset;
    }
    .menu-item {
      font-size: 0.7rem;
    }
  }
  @media (max-width: 959px) {
    .header__logo.large {
      width: 140px;
    }
    .menu-item {
      margin: 0 4px;
      font-size: 0.6rem;
    }
  }
  @media (max-width: 719px) {
    .get-app-btn {
      & .get-app-menu {
        --menu-height: 380px;
        width: 100% !important;
        right: unset;
        top: 30px;
      }
    }
  }
  @media (max-width: 599px) {
    .header__logo.large {
      width: 125px;
    }
  }

  .qr-code-container {
    display: flex;
    justify-content: center;
  }
  .qr-code-wrapper {
    padding: 0.5rem;
    padding-bottom: 0.2rem;
    background-color: white;
    border-radius: 0.25rem;
  }
</style>
