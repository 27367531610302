import axios from 'axios';
import { jwtHelper, apiHelper, Configuration } from '@/utils';
import _ from 'lodash';
//Reference https://github.com/axios/axios

var baseApiUrl = Configuration.value('baseApiUrl');

export function apiService(
  URL = null,
  isAuth = true,
  withMedia = false,
  withBlob = false
) {
  let baseURL = URL === null ? baseApiUrl : URL;
  let jwtToken = jwtHelper.getBearerToken();
  let header = isAuth
    ? {
        Authorization: jwtToken,
        'Content-Type': withMedia ? 'multipart/form-data' : 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    : {};

  let data = {
    baseURL,
    headers: header,
    timeout: 15 * 60 * 1000,
    withCredentials: false
  };

  if (withBlob) data.responseType = 'blob';

  let instance = axios.create(data);

  instance.interceptors.request.use(
    function (config) {
      // Do something before request is sent

      //Remove empty value params
      config.params = apiHelper.removeParamsWithoutValue(config.params);

      //Update activity time when fire API
      jwtHelper.setLastActivityTime();
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      let res = response.data;

      return res;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      let err = error.response ? error.response.data : error;
      return Promise.reject(err);
    }
  );

  return instance;
}

export function getQueryParamString(query) {
  const queryParam = new URLSearchParams(
    _(query)
      .omitBy(_.isUndefined)
      .omitBy(_.isNull)
      .omitBy((i) => {
        return i == '';
      })
      .value()
  );
  return queryParam.toString();
}
export function getQueryParamStringFromArray(query) {
  const qry = Object.entries(
    _(query)
      .omitBy(_.isUndefined)
      .omitBy(_.isNull)
      .omitBy((i) => {
        return i == '';
      })
      .omitBy((i) => {
        return i.length <= 0;
      })
      .value()
  );
  const queryParam = new URLSearchParams();
  qry.forEach(([key, value]) => {
    if (typeof value == 'object') {
      (value || []).forEach((newValue) => {
        queryParam.append(key, newValue);
      });
    } else {
      queryParam.append(key, value);
    }
  });
  return queryParam.toString();
}
export function mapObjectToFormData(query) {
  const body = Object.entries(
    _(query).omitBy(_.isUndefined).omitBy(_.isNull).value()
  );
  let formData = new FormData();
  body.forEach(([key, value]) => {
    if (typeof value == 'boolean') {
      formData.append(key, value ? 1 : 0);
    } else if (_.isArray(value)) {
      (value || []).forEach((newValue) => {
        formData.append(key, newValue);
      });
    } else {
      formData.append(key, value);
    }
  });
  return formData;
}
