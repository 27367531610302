import { LOCAL } from '@/constants';
import { uiHelper } from '@/utils';

const getMethod = {
  getConstants,
  getConstantsByPhoneCode
};

export default function constantsCountry(value, methodName = 'getConstants') {
  let header = 'all_country';
  let description;
  let newArray = [];
  if (header && !['', undefined, null].includes(value)) {
    if (Array.isArray(value)) {
      if (value.length > 0) {
        value.forEach((x) => {
          newArray.push(getMethod[methodName](header, x));
        });
        description = newArray;
      } else {
        description = '-';
      }
    } else {
      description = getMethod[methodName](header, value);
    }
  } else {
    description = '-';
  }
  return description;
}

function getConstants(header, value) {
  let constantsData = JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS));
  let data = {
    abbr: constantsData[header].find((x) => x.id == value).abbreviation,
    name: constantsData[header].find((x) => x.id == value).name,
    img: constantsData[header].find((x) => x.id == value).image_url,
    imgCircle: constantsData[header].find((x) => x.id == value).image_url_circle
  };
  return data;
}

function getConstantsByPhoneCode(header, value) {
  let constantsData = JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS));

  let data = {
    abbr: constantsData[header].find((x) => x.phone_code == value).abbreviation,
    name: constantsData[header].find((x) => x.phone_code == value).name,
    img: constantsData[header].find((x) => x.phone_code == value).image_url,
    imgCircle: constantsData[header].find((x) => x.phone_code == value)
      .image_url_circle
  };
  return data;
}
