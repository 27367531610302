import Vue from 'vue';
import Moment from 'moment';
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from '@/constants/constants';
import { startCase, camelCase } from 'lodash';
Vue.filter('defaultDate', function (value) {
  // console.log(value)
  return Moment(value).format(DEFAULT_DATE_FORMAT);
});
Vue.filter('defaultDateTime', function (value) {
  // console.log(value)
  return Moment(value).format(`${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`);
});
Vue.filter('capitalize', function (value) {
  return startCase(camelCase(value));
});
