import {
  SHARED_OPEN_DIALOG_INFO,
  SHARED_CLOSE_DIALOG_INFO,
  SHARED_OPEN_MODAL,
  SHARED_CLOSE_MODAL,
  SHARED_VIDEO_LOADING,
  SHARED_RESET_VIDEO_LOADING,
  SHARED_COMPRESS_VIDEO_PERCENTAGE,
  SHARED_RESET_COMPRESS_VIDEO_PERCENTAGE,
  SHARED_PUSH_VIDEO_TO_COMPRESS,
  SHARED_OPEN_LOADING,
  SHARED_CLOSE_LOADING,
  SHARED_OPEN_CHAT,
  SHARED_CLOSE_CHAT
} from '@/store/shared.module';
import { uiHelper } from '@/utils/ui.helper';
import _ from 'lodash';

var common = {
  methods: {
    openAppDialogInfo(type, title, description, buttons, isPersistent = true) {
      this.$store.dispatch(SHARED_OPEN_DIALOG_INFO, {
        type,
        title,
        description,
        buttons,
        isPersistent
      });
    },
    closeAppDialogInfo() {
      this.$store.dispatch(SHARED_CLOSE_DIALOG_INFO);
    },
    $openModal(id, message, action, cancelButton) {
      this.$store.dispatch(SHARED_OPEN_MODAL, {
        id,
        message,
        action,
        cancelButton
      });
    },
    $closeModal() {
      this.$store.dispatch(SHARED_CLOSE_MODAL);
    },
    $videoLoading() {
      this.$store.dispatch(SHARED_VIDEO_LOADING);
    },
    $resetVideoLoading() {
      this.$store.dispatch(SHARED_RESET_VIDEO_LOADING);
    },
    $compressVideoPercentage(videoCompressPercentage = 0) {
      this.$store.dispatch(SHARED_COMPRESS_VIDEO_PERCENTAGE, {
        show: true,
        progress: videoCompressPercentage
      });
    },
    $resetCompressVideoPercentage() {
      this.$store.dispatch(SHARED_RESET_COMPRESS_VIDEO_PERCENTAGE);
    },
    $startLoading() {
      this.$store.dispatch(SHARED_OPEN_LOADING);
    },
    $stopLoading() {
      this.$store.dispatch(SHARED_CLOSE_LOADING);
    },
    $pushVideoToCompress(video) {
      this.$store.dispatch(SHARED_PUSH_VIDEO_TO_COMPRESS, { video });
    },
    $openChat(id = null) {
      this.$store.dispatch(SHARED_OPEN_CHAT, { id });
    },
    $closeChat() {
      this.$store.dispatch(SHARED_CLOSE_CHAT);
    },
    $saveLocalStorage(key, value) {
      uiHelper.setLocalStorage(key, value);
    },
    $getLocalStorage(key) {
      return uiHelper.getLocalStorage(key);
    },
    $deleteLocalStorage(key) {
      uiHelper.removeLocalStorage(key);
    },
    $scrollToTop() {
      window.scrollTo(0, 0);
    },
    $isMobile() {
      return this?.$store?.state?.shared?.screen.width < 720;
    },
    $clearJobPost() {
      uiHelper.removeLocalStorage('job');
      uiHelper.removeLocalStorage('jobCover');
      uiHelper.removeLocalStorage('character');
    },
    $debounceHandler: _.debounce((action) => {
      action();
    }, 800),
    $get: _.get
  }
};

export default common;
