/* Define Module Name */
const MODULE_NAME = 'shared/';

/* Define Action Name */
const A_VIDEO_LOADING = 'videoLoading';
const A_RESET_VIDEO_LOADING = 'resetVideoLoading';
const A_COMPRESS_VIDEO_PERCENTAGE = 'compressVideoPercentage';
const A_RESET_COMPRESS_VIDEO_PERCENTAGE = 'resetCompressVideoPercentage';
const A_PUSH_VIDEO_TO_COMPRESS = 'pushVideoToCompress';
const A_CLEAR_PUSH_VIDEO_TO_COMPRESS = 'clearPushVideoToCompress';
const A_OPEN_LOADING = 'openLoading';
const A_CLOSE_LOADING = 'closeLoading';
const A_OPEN_CHAT = 'openChat';
const A_CLOSE_CHAT = 'closeChat';
const A_OPEN_DIALOG_INFO = 'openDialogInfo';
const A_CLOSE_DIALOG_INFO = 'closeDialogInfo';
const A_HTTP_REQUEST_LOADING = 'httpRequestLoading';
const A_OPEN_MODAL = 'openModal';
const A_CLOSE_MODAL = 'closeModal';
const A_UPDATE_SCREEN_SIZE = 'updateScreenSize';

/* Define Getter Name */

/* Define Mutation Name */
const M_VIDEO_LOADING = 'setVideoLoading';
const M_RESET_VIDEO_LOADING = 'setResetVideoLoading';
const M_COMPRESS_VIDEO_PERCENTAGE = 'setCompressVideoPercentage';
const M_RESET_COMPRESS_VIDEO_PERCENTAGE = 'setResetCompressVideoPercentage';
const M_PUSH_VIDEO_TO_COMPRESS = 'setPushVideoToCompress';
const M_CLEAR_PUSH_VIDEO_TO_COMPRESS = 'setClearPushVideoToCompress';
const M_OPEN_LOADING = 'setOpenLoading';
const M_CLOSE_LOADING = 'setCloseLoading';
const M_OPEN_CHAT = 'setOpenChat';
const M_CLOSE_CHAT = 'setCloseChat';
const M_OPEN_DIALOG_INFO = 'setOpenDialogInfo';
const M_CLOSE_DIALOG_INFO = 'setCloseDialogInfo';
const M_UPDATE_HTTP_REQUEST_LOADING_STATE = 'setHttpLoadingState';
const M_OPEN_MODAL = 'setOpenModal';
const M_CLOSE_MODAL = 'setCloseModal';
const M_UPDATE_SCREEN_SIZE = 'setScreenSize';

/* Define Export Name */
export const SHARED_VIDEO_LOADING = MODULE_NAME + A_VIDEO_LOADING;
export const SHARED_RESET_VIDEO_LOADING = MODULE_NAME + A_RESET_VIDEO_LOADING;
export const SHARED_COMPRESS_VIDEO_PERCENTAGE = MODULE_NAME + A_COMPRESS_VIDEO_PERCENTAGE;
export const SHARED_RESET_COMPRESS_VIDEO_PERCENTAGE = MODULE_NAME + A_RESET_COMPRESS_VIDEO_PERCENTAGE;
export const SHARED_PUSH_VIDEO_TO_COMPRESS = MODULE_NAME + A_PUSH_VIDEO_TO_COMPRESS;
export const SHARED_CLEAR_PUSH_VIDEO_TO_COMPRESS= MODULE_NAME + A_CLEAR_PUSH_VIDEO_TO_COMPRESS;
export const SHARED_OPEN_LOADING = MODULE_NAME + A_OPEN_LOADING;
export const SHARED_CLOSE_LOADING = MODULE_NAME + A_CLOSE_LOADING;
export const SHARED_OPEN_CHAT = MODULE_NAME + A_OPEN_CHAT;
export const SHARED_CLOSE_CHAT = MODULE_NAME + A_CLOSE_CHAT;
export const SHARED_OPEN_DIALOG_INFO = MODULE_NAME + A_OPEN_DIALOG_INFO;
export const SHARED_CLOSE_DIALOG_INFO = MODULE_NAME + A_CLOSE_DIALOG_INFO;
export const SHARED_HTTP_REQUEST_LOADING = MODULE_NAME + A_HTTP_REQUEST_LOADING;
export const SHARED_OPEN_MODAL = MODULE_NAME + A_OPEN_MODAL;
export const SHARED_CLOSE_MODAL = MODULE_NAME + A_CLOSE_MODAL;
export const SHARED_UPDATE_SCREEN_SIZE = MODULE_NAME + A_UPDATE_SCREEN_SIZE;

const state = {
  screen: {
    width: '',
    height: ''
  },
  isLoading: false,
  isVideoLoading: false,
  videoCompressPercentage: {
    show: false,
    progress: 0
  },
  pushVideoToCompress: null,
  openChat: false,
  openChatId: null,
  openChatClass: false,
  openModal: false,
  modalId: '',
  modalMessage: '',
  modalAction: '',
  modalCancelButton: {
    show: false,
    customText: ''
  },
  dialogInfo: {
    show: false,
    type: null,
    title: null,
    description: null,
    buttons: [],
    isPersistent: true
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_VIDEO_LOADING]({ commit }) {
    commit(M_VIDEO_LOADING);
  },
  [A_RESET_VIDEO_LOADING]({ commit }) {
    commit(M_RESET_VIDEO_LOADING);
  },
  [A_COMPRESS_VIDEO_PERCENTAGE]({ commit }, { show, progress }) {
    commit(M_COMPRESS_VIDEO_PERCENTAGE, { show, progress });
  },
  [A_RESET_COMPRESS_VIDEO_PERCENTAGE]({ commit }) {
    commit(M_RESET_COMPRESS_VIDEO_PERCENTAGE);
  },
  [A_PUSH_VIDEO_TO_COMPRESS]({ commit }, { video }) {
    commit(M_PUSH_VIDEO_TO_COMPRESS, { video });
  },
  [A_CLEAR_PUSH_VIDEO_TO_COMPRESS]({ commit }) {
    commit(M_CLEAR_PUSH_VIDEO_TO_COMPRESS);
  },
  [A_OPEN_LOADING]({ commit }) {
    commit(M_OPEN_LOADING);
  },
  [A_CLOSE_LOADING]({ commit }) {
    commit(M_RESET_VIDEO_LOADING);
    commit(M_CLOSE_LOADING);
  },
  [A_OPEN_CHAT]({ commit }, { id }) {
    commit(M_OPEN_CHAT, { id });
  },
  [A_CLOSE_CHAT]({ commit }) {
    commit(M_CLOSE_CHAT);
  },
  [A_OPEN_DIALOG_INFO](
    { commit },
    { type, title, description, buttons, isPersistent }
  ) {
    commit(M_OPEN_DIALOG_INFO, {
      type,
      title,
      description,
      buttons,
      isPersistent
    });
  },
  [A_CLOSE_DIALOG_INFO]({ commit }) {
    commit(M_CLOSE_DIALOG_INFO);
  },
  [A_OPEN_MODAL]({ commit }, id) {
    commit(M_OPEN_MODAL, id);
  },
  [A_CLOSE_MODAL]({ commit }) {
    commit(M_CLOSE_MODAL);
  },
  [A_UPDATE_SCREEN_SIZE]({ commit }, size) {
    commit(M_UPDATE_SCREEN_SIZE, size);
  }
};

// Mutations
const mutations = {
  [M_VIDEO_LOADING](state) {
    state.isVideoLoading = true;
  },
  [M_RESET_VIDEO_LOADING](state) {
    state.isVideoLoading = false;
  },
  [M_COMPRESS_VIDEO_PERCENTAGE](state, { show, progress }) {
    state.videoCompressPercentage = {
      show,
      progress
    };
  },
  [M_RESET_COMPRESS_VIDEO_PERCENTAGE](state) {
    state.videoCompressPercentage = {
      show: false,
      progress: 0
    };
  },
  [M_PUSH_VIDEO_TO_COMPRESS](state, { video }) {
    state.pushVideoToCompress = video;
  },
  [M_CLEAR_PUSH_VIDEO_TO_COMPRESS](state) {
    state.pushVideoToCompress = null;
  },
  [M_OPEN_LOADING](state) {
    state.isLoading = true;
  },
  [M_CLOSE_LOADING](state) {
    state.isLoading = false;
  },
  [M_OPEN_CHAT](state, { id }) {
    state.openChat = true;
    state.openChatId = id;
    state.openChatClass = true;
  },
  [M_CLOSE_CHAT](state) {
    state.openChatId = null;
    state.openChatClass = false;
  },
  [M_UPDATE_HTTP_REQUEST_LOADING_STATE](state) {
    state.isLoading = false;
  },
  [M_OPEN_MODAL](state, { id, message, action, cancelButton }) {
    state.openModal = true;

    state.modalId = id;
    state.modalMessage = message;
    state.modalAction = action;
    state.modalCancelButton = {
      show: cancelButton?.show || false,
      customText: cancelButton?.customText || ''
    };
  },
  [M_CLOSE_MODAL](state) {
    state.openModal = false;
    state.modalId = null;
    state.modalMessage = null;
    state.modalAction = null;
    state.modalCancelButton = {
      show: false,
      customText: ''
    };
  },
  [M_OPEN_DIALOG_INFO](
    state,
    { type, title, description, buttons, isPersistent }
  ) {
    state.dialogInfo = {
      show: true,
      type: type,
      title: title,
      description: description,
      buttons: buttons,
      isPersistent
    };
  },
  [M_CLOSE_DIALOG_INFO](state) {
    state.dialogInfo = {
      show: false,
      type: null,
      title: null,
      description: null,
      buttons: [],
      isPersistent: true
    };
  },
  [M_UPDATE_SCREEN_SIZE](state, size) {
    state.screen = size;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
