<template>
  <div class="wh-100">
    <v-main>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>
    <Footer></Footer>
  </div>
</template>

<script>
  import Footer from '@/components/layout/Footer';
  export default {
    name: 'LayoutWithFooter',
    components: {
      Footer
    }
  };
</script>

<style></style>
