import { APPLICATION_MANAGEMENT } from '@/api';
import { apiHelper } from '@/utils';
import {
  SHARED_OPEN_LOADING,
  SHARED_CLOSE_LOADING
} from '@/store/shared.module';

/* Define Module Name */
const MODULE_NAME = 'applicationManagement/';

/* Define Action Name */
const A_WITHDRAW_APPLICATION = 'withdrawApplication';

/* Define Reset State Action Name */
const A_INITIAL_WITHDRAW_APPLICATION_STATE = 'initialWithdrawApplicationState';

/* Define Getter Name */

/* Define Mutation Name */
const M_WITHDRAW_APPLICATION = 'setWithdrawApplication';

/* Define Reset State Mutation Name */
const M_INITIAL_WITHDRAW_APPLICATION_STATE =
  'setInitialWithdrawApplicationState';

/* Define Export Name */
export const APPLICATION_MANAGEMENT_WITHDRAW_APPLICATION =
  MODULE_NAME + A_WITHDRAW_APPLICATION;

/* Define Reset State Name */
export const APPLICATION_MANAGEMENT_INITIAL_WITHDRAW_APPLICATION_STATE =
  MODULE_NAME + A_INITIAL_WITHDRAW_APPLICATION_STATE;

const state = {
  withdrawApplication: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_WITHDRAW_APPLICATION]({ dispatch, commit }, { id }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    APPLICATION_MANAGEMENT.withdrawApplication(id)
      .then((response) => {
        result = response;
        commit(M_WITHDRAW_APPLICATION, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_WITHDRAW_APPLICATION, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_WITHDRAW_APPLICATION_STATE]({ commit }) {
    commit(M_INITIAL_WITHDRAW_APPLICATION_STATE);
  }
};

// Mutations
const mutations = {
  [M_WITHDRAW_APPLICATION](state, result) {
    const d = result.data
      ? result.data.map((x) => {
          return {
            value: x.id,
            description: x.name,
            isDeleted: x.is_deleted
          };
        })
      : [];
    state.withdrawApplication = {
      code: result.code,
      complete: true,
      data: d,
      message: apiHelper.sortOutMessage(result.error_message)
    };
  },
  [M_INITIAL_WITHDRAW_APPLICATION_STATE](state) {
    let data = state.withdrawApplication.data;

    state.withdrawApplication = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
