import { apiService } from '@/services';

export const CONSTANTS = {
  getConstants
};

const URL = {
  API: 'api',
  CONSTANTS: 'constants'
};

function getConstants() {
  return apiService().get(`/${URL.API}/${URL.CONSTANTS}`);
}
