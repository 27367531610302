<template>
  <div>
    <v-form
      v-if="!isForgotPassword && !isSendOTP"
      ref="form"
      lazy-validation
      v-model="valid"
      @submit="login"
      class="loginForm"
    >
      <h1 class="white--text mb-10">LOGIN</h1>
      <v-spacer></v-spacer>

      <template v-if="formValues.auth_method == ''">
        <div class="d-flex flex-column text-center mt-10">
          <v-btn
            color="error"
            class="mr-4 btn1"
            @click="formValues.auth_method = 'mobile'"
          >
            Mobile No.
          </v-btn>
          <v-btn
            color="error"
            class="mr-4 mt-5 btn1"
            @click="formValues.auth_method = 'email'"
          >
            Email
          </v-btn>
        </div>
      </template>
      <template v-else>
        <v-text-field
          v-if="formValues.auth_method == 'email'"
          v-model="formValues.email"
          :rules="formValidation.emailRules()"
          label="Email"
          required
          outlined
          @input="checkUserExistence"
          @keydown.enter="login"
        ></v-text-field>
        <vue-tel-input-vuetify
          v-else
          :preferredCountries="['SG', 'MY']"
          defaultCountry="SG"
          v-model="formValues.mobile"
          label="Mobile No."
          mode=""
          required
          outlined
          @input="onNumberChange"
          @country-changed="updateCountry"
          @keydown.enter="login"
        ></vue-tel-input-vuetify>

        <v-text-field
          v-if="!isAdminFirstLogin"
          v-model="formValues.password"
          :rules="formValidation.requiredRules()"
          label="Password"
          required
          outlined
          :append-icon="!hide ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="() => (hide = !hide)"
          :type="hide ? 'password' : 'text'"
          @keydown.enter="login"
        ></v-text-field
      ></template>
      <div class="d-flex flex-column text-center mt-10">
        <span class="white--text cursor-pointer" @click="isForgotPassword = true"
          >Forgot Password?</span
        >
        <div v-if="formValues.auth_method != ''">
          <v-btn
            class="mt-5 btn1"
            @click="login"
            v-if="!isAdminFirstLogin"
          >
            Log In
          </v-btn>
          <v-btn color="error" class="mr-4 mt-5 btn1" @click="sendOTP" v-else>
            Send OTP
          </v-btn>
          <a class="d-block mt-5" @click="formValues.auth_method = ''">back</a>
        </div>
      </div>
    </v-form>
    <ForgotPasswordForm v-if="isForgotPassword" @close="closeModal" />
    <AdminSendOTP
      v-if="isAdminFirstLogin && isSendOTP"
      :formValues="formValues"
      @login="
        formValues.password = $event;
        login();
      "
    />
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import {
    AUTH_LOGIN,
    AUTH_IS_AUTHENTICATED,
    AUTH_RESET_LOGIN_STATE,
    AUTH_CHECK_USER_EXISTENCE,
    AUTH_INITIAL_CHECK_USER_EXISTENCE,
    AUTH_REQUEST_OTP,
    AUTH_INITIAL_REQUEST_OTP_STATE
  } from '@/store/auth.module';
  import ForgotPasswordForm from './forgotPasswordForm.vue';
  import AdminSendOTP from './AdminSendOTP.vue';
  import { OTP_TYPE } from '@/constants/enums/otp-type.enum.js';
  import { formValidationHelper } from '@/utils';

  export default {
    name: 'LoginForm',
    components: { ForgotPasswordForm, AdminSendOTP },
    data() {
      return {
        loginCompleteMessage: '',
        isForgotPassword: false,
        formValues: {
          auth_method: '',
          mobile_dial_code: '+65',
          mobile: '',
          email: '',
          password: ''
        },
        hide: true,
        formValid: {},
        isAdminFirstLogin: false,
        isSendOTP: false,
        valid: false,
        formValidation: formValidationHelper
      };
    },
    props: {
      width: {
        type: String,
        default: '600'
      }
    },
    computed: {
      ...mapGetters({
        isAuthenticated: [AUTH_IS_AUTHENTICATED]
      }),
      loginComplete() {
        return this.$store.state.auth.loginResponse.complete;
      },
      dialog() {
        return this.$store.state.shared.openModal;
      },
      checkUserExistComplete() {
        return this.$store.state.auth.checkUserExist.complete;
      },
      requestOTPComplete() {
        return this.$store.state.auth.requestOTP.complete;
      }
    },

    watch: {
      'formValues.auth_method'() {
        this.formValues.mobile = '';
        this.formValues.email = '';
      },
      async loginComplete() {
        let response = this.$store.state.auth.loginResponse;
        if (response.complete) {
          this.loginCompleteAction(response);
        }
      },
      checkUserExistComplete() {
        let response = this.$store.state.auth.checkUserExist;
        if (response.complete) {
          this.checkUserExistCompleteAction(response);
        }
      },
      requestOTPComplete() {
        let response = this.$store.state.auth.requestOTP;
        if (response.complete) {
          this.requestOTPCompleteAction(response);
        }
      }
    },

    methods: {
      onNumberChange(v, v2) {
        this.formValues.mobile = v2.number.significant;
        this.checkUserExistence();
      },
      updateCountry(event) {
        this.formValues.mobile_dial_code = '+' + event.dialCode;
      },
      checkUserExistCompleteAction(response) {
        const data = response.data;
        if (data?.is_registered && !data?.is_activated)
          this.isAdminFirstLogin = true;
        this.$store.dispatch(AUTH_INITIAL_CHECK_USER_EXISTENCE);
      },
      checkUserExistence() {
        this.$debounceHandler(() => {
          const data = {
            auth_method: this.formValues.auth_method,
            mobile_dial_code: this.formValues.mobile_dial_code,
            mobile: this.formValues.mobile,
            email: this.formValues.email
          };
          this.$store.dispatch(AUTH_CHECK_USER_EXISTENCE, { data });
        });
      },
      requestOTPCompleteAction(response) {
        if (response.code == 200) this.isSendOTP = true;
        this.$store.dispatch(AUTH_INITIAL_REQUEST_OTP_STATE);
      },
      async sendOTP() {
        const data = {
          auth_method: this.formValues.auth_method,
          mobileDialCode: this.formValues.mobile_dial_code,
          mobile: this.formValues.mobile,
          email: this.formValues.email,
          otpType: OTP_TYPE.REGISTER
        };
        await this.$store.dispatch(AUTH_REQUEST_OTP, { data });
      },
      closeModal() {
        this.$closeModal();
        this.$emit('closeModal');
      },
      login(e) {
        if (e) e.preventDefault();
        const data = {
          ...this.formValues
        };
        this.$store.dispatch(AUTH_LOGIN, { data });
      },
      async loginCompleteAction(response) {
        if (response.code == 200 && this.isAuthenticated) {
          this.loginCompleteMessage = response.message.toString();
          this.closeModal();
          if (this.$route.name != 'home') {
            window.location.reload();
          }
        }
        // else if (response.code == 200 && response.data.isTalentOrAgency) {
        //   this.$openModal('messageModal', 'Features for Talents and Talent Agencies will be coming soon');
        // }
        else {
          this.openAppDialogInfo(
            'error',
            response.message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.initialLoginState();
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
      },
      initialLoginState() {
        this.$store.dispatch(AUTH_RESET_LOGIN_STATE);
      }
    }
  };
</script>
<style lang="scss">
  .baseModal {
    background-color: #242424;
    width: 100%;
    min-height: 100px;
  }
</style>
