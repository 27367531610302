import { apiService, getQueryParamString } from '@/services';
// import { apiHelper } from '@/core/utils';

export const PAYMENT = {
  getMySubscription,
  getPaymentSubscriptions,
  getPaymentHistory,
  setPaySubscription,
  setPayUploadCredit,
  printPaymentHistory,
  purchaseAdminAccount,
  checkPaymentSubscription
};
const URL = {
  API: 'api',
  V1: 'v1',
  MY: 'my',
  PAYMENT: 'payment',
  PRINT: 'print',
  MEMBER_LIST: 'member-list',
  UPLOAD_CREDIT: 'upload-token',
  SUBSCRIPTIONS: 'subscriptions',
  PAYMENT_HISTORY: 'payment-history',
  MEMBERSHIP_SUBSCRIPTION: 'membership-subscription',
  ADMIN_ACCOUNT: 'admin-account',
  CHECK: 'check',
  STRIPE: 'stripe'
};

function getMySubscription() {
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.SUBSCRIPTIONS}/${URL.MY}`
  );
}

function getPaymentSubscriptions(data) {
  let requestOptions = {
    params: {
      subscription_type: data.role
    }
  };
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.SUBSCRIPTIONS}`,
    requestOptions
  );
}

function getPaymentHistory() {
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.SUBSCRIPTIONS}/${URL.PAYMENT_HISTORY}`
  );
}
function setPaySubscription(data) {
  let requsetBody = {
    subscription_type: data.subscriptionType,
    package_id: data.packageId,
    package_detail_id: data.packageDetailId
  };
  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.PAYMENT}/${URL.STRIPE}/${URL.MEMBERSHIP_SUBSCRIPTION}`,
    requsetBody
  );
}

function setPayUploadCredit() {
  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.PAYMENT}/${URL.STRIPE}/${URL.UPLOAD_CREDIT}`
  );
}
function purchaseAdminAccount(data) {
  const body = {
    type: data.type,
    user_id: data.userId
  };
  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.PAYMENT}/${URL.STRIPE}/${URL.ADMIN_ACCOUNT}`,
    body
  );
}

function printPaymentHistory(query) {
  const queryParam = getQueryParamString({
    ...query
  });
  return apiService(null, true, false, true).get(
    `/${URL.API}/${URL.V1}/${URL.SUBSCRIPTIONS}/${URL.PAYMENT_HISTORY}/${URL.PRINT}?` +
      queryParam
  );
}
function checkPaymentSubscription() {
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.SUBSCRIPTIONS}/${URL.CHECK}`
  );
}
