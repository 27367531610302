<template>
  <ModalBase id="messageModal" width="600" :noCloseButton="!action">
    <div class="text-center">
      <h2 class="msgText text-white my-5">
        {{ $store.state.shared.modalMessage }}
      </h2>
      <v-row no-gutters justify="center">
        <v-col
          cols="12"
          sm="6"
          class="order-sm-last order-md-first"
          v-if="cancelButton.show"
        >
          <v-btn class="btn1 custom outline mt-10" @click="$closeModal">
            {{ cancelButton.customText || $t('label.cancel') }}
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6">
          <v-btn
            v-if="action"
            class="btn1 custom white--text mt-10"
            @click="action.action"
          >
            {{ action.title }}
          </v-btn>
          <v-btn v-else class="btn1 white--text mt-md-10 mt-2" @click="$closeModal">
            OK
          </v-btn>
        </v-col>
      </v-row>
    </div></ModalBase
  >
</template>
<script>
  import ModalBase from './ModalBase.vue';
  export default {
    name: 'MessageModal',
    components: { ModalBase },
    computed: {
      message() {
        return this.$store.state.shared.modalMessage;
      },
      action() {
        return this.$store.state.shared.modalAction;
      },
      cancelButton() {
        return this.$store.state.shared.modalCancelButton;
      }
    }
  };
</script>
<style lang="scss" scoped>
  .msgText {
    text-transform: uppercase;
  }
  .btn1.custom {
    width: 90% !important;
  }
</style>
