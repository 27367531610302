export const uiHelper = {
  /* Cookie */
  getCookie,
  setCookie,
  removeCookie,
  /* Local Storage */
  getLocalStorage,
  setLocalStorage,
  removeLocalStorage,
  /* Session Storage */
  getSessionStorage,
  setSessionStorage,
  removeSessionStorage,
  /* Location */
  reloadPage,
  /* Window */
  openPage,
  /* Variable Transform */
  arrayToTrimmedStringWithSpace,
  mapArrayToObjectData,
  toCapitalize,
  /* Document */
  freezeBodyOverflowY,
  unFreezeBodyOverflowY,
  /* User Agent */
  getOperatingSystem,
  getBrowser,
  /* Other */
  getAge,
  copyTextToClipboardByElementId
};

const DEFAULT_COOKIE_EXPIRY_DAY = 100;

function getCookie(keyName) {
  let name = keyName + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let cookies = decodedCookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) == ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) == 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return null;
}

function setCookie(keyName, keyValue, exDays = DEFAULT_COOKIE_EXPIRY_DAY) {
  let date = new Date();
  date.setTime(date.getTime() + exDays * 24 * 60 * 60 * 1000);
  let expires = 'expires=' + date.toUTCString();
  document.cookie = keyName + '=' + keyValue + ';' + expires + ';path=/';
}

function removeCookie(keyName) {
  document.cookie =
    keyName + '= ' + '; expires = Thu, 01 Jan 1970 00:00:00 GMT';
}

function getLocalStorage(keyName) {
  return localStorage.getItem(keyName);
}

function setLocalStorage(keyName, keyValue) {
  localStorage.setItem(keyName, keyValue);
}

function removeLocalStorage(keyName) {
  localStorage.removeItem(keyName);
}

function getSessionStorage(keyName) {
  return sessionStorage.getItem(keyName);
}

function setSessionStorage(keyName, keyValue) {
  sessionStorage.setItem(keyName, keyValue);
}

function removeSessionStorage(keyName) {
  sessionStorage.removeItem(keyName);
}

function reloadPage() {
  location.reload();
}

function openPage(URL, name, specs) {
  window.open(URL, name, specs);
}

function arrayToTrimmedStringWithSpace(array, trimStart) {
  return array.map((x) => x.substring(trimStart)).join(', ');
}

function freezeBodyOverflowY() {
  document.getElementsByTagName('body')['0'].style.overflowY = 'hidden';
}

function unFreezeBodyOverflowY() {
  document.getElementsByTagName('body')['0'].style.overflowY = 'auto';
}

function getOperatingSystem() {
  let userAgent = navigator.userAgent;

  if (/huawei/i.test(userAgent)) {
    return 'Huawei';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // IOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'IOS';
  }

  if (/win/i.test(userAgent)) {
    return 'Windows';
  }

  if (/linux/i.test(userAgent)) {
    return 'Linux';
  }

  if (/x11/i.test(userAgent)) {
    return 'UNIX';
  }

  if (/macintosh/i.test(userAgent)) {
    return 'Macintosh';
  }

  return 'Unknown';
}

function mapArrayToObjectData(array) {
  let newData = {};

  array.forEach((x) => {
    newData[x.key] = x.value;
  });

  return newData;
}

function toCapitalize(string) {
  let words = string.split(' ');

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }

  return words.join(' ');
}

function getAge(dateString) {
  /**
   * Date of birth
   * @param dateString
   */
  /**
   * Get the Age string by input date of birth
   * @returns {string}
   */
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

function copyTextToClipboardByElementId(elementId) {
  /* Get the text field */
  let input = document.getElementById(elementId);

  /* Select the text field */
  input.select();
  input.setSelectionRange(0, 99999); /* For mobile devices */

  /* Copy the text inside the text field */
  navigator.clipboard.writeText(input.value).then(
    function () {
      /* Alert the copied text */
      alert('Copied!');
    },
    function () {
      /* Alert fail to copy text */
      alert('Fail to copy.');
    }
  );
}

function getBrowser() {
  let userAgent = navigator.userAgent;

  if (/huaweibrowser/i.test(userAgent)) {
    return 'HuaweiBrowser';
  }

  return 'Unknown';
}
