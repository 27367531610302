import {
  apiService,
  getQueryParamString,
  mapObjectToFormData
} from '@/services';

export const APPLICATION_MANAGEMENT = {
  getOneApplication,
  postVideoReminder,
  getApplications,
  getApplicationsHistory,
  requestNewVideo,
  updateApplicationStatus,
  downloadVideo,
  uploadVideo,
  reinvite,
  updateVideoStatus,
  sendInvitationApplicationReminder,
  withdrawApplication
};

const URL = {
  API: 'api',
  V1: 'v1',
  V2: 'v2',
  REINVITE: 'reinvite',
  CASTING: 'casting',
  REMIND: 'remind',
  VIDEO: 'video',
  APPLICATIONS: 'applications',
  APPLICATION: 'application',
  INVITATION: 'invitation',
  APPLICATION_REMINDER: 'application-reminder',
  WITHDRAW: 'withdraw',
  HISTORY: 'history'
};

function getApplications() {
  return apiService().get(
    `/${URL.API}/${URL.V2}/${URL.CASTING}/${URL.APPLICATIONS}`
  );
}
function getApplicationsHistory() {
  return apiService().get(
    `/${URL.API}/${URL.V2}/${URL.CASTING}/${URL.APPLICATIONS}/${URL.HISTORY}`
  );
}
function downloadVideo(url) {
  return apiService()({
    method: 'get',
    url,
    responseType: 'arraybuffer'
  });
}
function getOneApplication(id, query) {
  const queryParam = getQueryParamString({
    ...query,
    status: query?.status?.value,
    channel: query?.channel?.value,
    application: query?.application?.value,
    // is_history: query?.is_history ? 1 : 0
    is_history: 0
  });
  return apiService().get(
    `/${URL.API}/${URL.V2}/${URL.CASTING}/${URL.APPLICATIONS}/${id}?` +
      queryParam
  );
}
function postVideoReminder(id) {
  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.CASTING}/${URL.APPLICATION}/${id}/${URL.REMIND}`
  );
}
function reinvite(payload) {
  const requestBody = {
    casting_job_id: payload.job_id,
    casting_role_id: payload.role_id,
    user_id: [payload.user_id]
  };
  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.CASTING}/${URL.REINVITE}`,
    requestBody
  );
}
function requestNewVideo(id) {
  return apiService().put(
    `/${URL.API}/${URL.V1}/${URL.CASTING}/${URL.APPLICATION}/${id}/${URL.VIDEO}`,
    { video_status: 'declined' }
  );
}
function updateApplicationStatus(id, status) {
  return apiService().put(
    `/${URL.API}/${URL.V1}/${URL.CASTING}/${URL.APPLICATION}/${id}`,
    { status }
  );
}
function uploadVideo(id, data) {
  return apiService(null, true, true).post(
    `/${URL.API}/${URL.V1}/${URL.CASTING}/${URL.APPLICATION}/${id}`,
    mapObjectToFormData({
      ...data
    })
  );
}
function updateVideoStatus(id, data) {
  const requestBody = {
    video_status: data.videoStatus
  };
  return apiService().put(
    `/${URL.API}/${URL.V1}/${URL.CASTING}/${URL.APPLICATION}/${id}/${URL.VIDEO}`,
    requestBody
  );
}
function sendInvitationApplicationReminder(id) {
  const requestBody = {
    invitation_id: id
  };
  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.CASTING}/${URL.INVITATION}/${URL.APPLICATION_REMINDER}`,
    requestBody
  );
}
function withdrawApplication(id) {
  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.CASTING}/${URL.APPLICATION}/${id}/${URL.WITHDRAW}`
  );
}
