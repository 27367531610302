const { SHARED, ROUTE_NAME } = require('../constants');

export const routes = [
  {
    path: '/',
    name: ROUTE_NAME.HOME,
    component: () => import('@/views/Home.vue'),
    meta: {
      layout: SHARED.DEFAULT_LAYOUT,
      sitemap: { lastmod: '2021-10-15', priority: 1.0, changefreq: 'monthly' }
    }
  },
  {
    path: '/notification',
    name: ROUTE_NAME.NOTIFICATION,
    component: () => import('@/views/Inbox/Inbox.vue'),
    meta: { layout: SHARED.PROFILE_LAYOUT, requiresAuth: true }
  },
  {
    path: '/job_organizer',
    name: ROUTE_NAME.JOB_ORGANIZER,
    props: true,
    component: () => import('@/views/JobOrganizers/index.vue'),
    meta: { layout: SHARED.PROFILE_LAYOUT, requiresAuth: true }
  },
  {
    path: '/job-details/:id',
    name: ROUTE_NAME.JOB_DETAILS,
    props: true,
    component: () => import('@/views/JobOrganizers/JobDetails.vue'),
    meta: {
      layout: SHARED.DEFAULT_LAYOUT,
      requiresAuth: true,
      sitemap: { ignoreRoute: true }
    }
  },
  {
    path: '/search',
    name: ROUTE_NAME.SEARCH,
    component: () => import('@/views/Search.vue'),
    meta: { layout: SHARED.PROFILE_LAYOUT, requiresAuth: true, props: true }
  },
  {
    path: '/application_management',
    name: ROUTE_NAME.APPLICATION_MANAGEMENT,
    component: () => import('@/views/ApplicationManagement/index.vue'),
    meta: { layout: SHARED.PROFILE_LAYOUT, requiresAuth: true }
  },
  {
    path: '/profile',
    name: ROUTE_NAME.PROFILE,
    component: () => import('@/views/Profile/index.vue'),
    meta: { layout: SHARED.PROFILE_LAYOUT_WITHOUT_SIDEBAR, requiresAuth: true }
  },
  {
    path: '/posted-job',
    name: ROUTE_NAME.POSTED_JOB,
    component: () => import('@/views/JobOrganizers/PostedJob.vue'),
    meta: {
      layout: SHARED.PROFILE_LAYOUT_WITHOUT_SIDEBAR,
      requiresAuth: true,
      accessRole: ['freelancer', 'production_house']
    }
  },
  {
    path: '/rating-reviews',
    name: ROUTE_NAME.RATING_REVIEWS,
    component: () => import('@/views/Profile/RatingReviews.vue'),
    props: true,
    meta: {
      layout: SHARED.PROFILE_LAYOUT_WITHOUT_SIDEBAR,
      requiresAuth: true
    }
  },
  {
    path: '/test-only',
    name: ROUTE_NAME.TEST_ONLY,
    component: () => import('@/views/TestOnly.vue'),
    meta: {
      layout: SHARED.PROFILE_LAYOUT,
      requiresAuth: true,
      accessRole: ['freelancer', 'production_house', 'agency']
    }
  },
  {
    path: '/company-info',
    name: ROUTE_NAME.COMPANY_INFO,
    component: () => import('@/views/Profile/CompanyInfo.vue'),
    meta: {
      layout: SHARED.PROFILE_LAYOUT,
      requiresAuth: true,
      accessRole: ['freelancer', 'production_house', 'agency']
    }
  },
  {
    path: '/profile-info',
    name: ROUTE_NAME.PROFILE_INFO,
    component: () => import('@/views/Profile/ProfileInfo.vue'),
    meta: {
      layout: SHARED.PROFILE_LAYOUT,
      requiresAuth: true,
      accessRole: ['talent']
    }
  },
  {
    path: '/company-code',
    name: ROUTE_NAME.COMPANY_CODE,
    component: () => import('@/views/Profile/CompanyCode.vue'),
    meta: {
      layout: SHARED.PROFILE_LAYOUT,
      requiresAuth: true,
      accessRole: ['agency']
    }
  },
  {
    path: '/talent-account',
    name: ROUTE_NAME.TALENT_ACCOUNT,
    component: () => import('@/views/Profile/TalentAccount.vue'),
    meta: {
      layout: SHARED.PROFILE_LAYOUT,
      requiresAuth: true,
      accessRole: ['agency']
    }
  },
  {
    path: '/change-password',
    name: ROUTE_NAME.CHANGE_PASSWORD,
    component: () => import('@/views/Profile/ChangePassword.vue'),
    meta: { layout: SHARED.PROFILE_LAYOUT, requiresAuth: true }
  },
  {
    path: '/admin-account',
    name: ROUTE_NAME.ADMIN_ACCOUNT,
    component: () => import('@/views/Profile/AdminAccount.vue'),
    meta: {
      layout: SHARED.PROFILE_LAYOUT,
      requiresAuth: true,
      props: true,
      accessRole: ['agency', 'production_house'],
      disabledForAdmin: true
    }
  },
  {
    path: '/linked-agency',
    name: ROUTE_NAME.LINKED_AGENCY,
    component: () => import('@/views/Profile/LinkedAgency.vue'),
    meta: {
      layout: SHARED.PROFILE_LAYOUT,
      requiresAuth: true,
      props: true,
      accessRole: ['talent']
    }
  },
  {
    path: '/create-admin',
    name: ROUTE_NAME.CREATE_ADMIN,
    component: () => import('@/views/Profile/CreateAdmin.vue'),
    meta: {
      layout: SHARED.PROFILE_LAYOUT,
      requiresAuth: true,
      accessRole: ['agency', 'production_house']
    }
  },
  {
    path: '/blocked-account',
    name: ROUTE_NAME.BLOCKED_ACCOUNT,
    component: () => import('@/views/Profile/BlockedAccount.vue'),
    meta: { layout: SHARED.PROFILE_LAYOUT, requiresAuth: true }
  },
  {
    path: '/display-informations',
    name: ROUTE_NAME.DISPLAY_INFORMATIONS,
    component: () => import('@/views/Profile/DisplayInformations.vue'),
    meta: { layout: SHARED.PROFILE_LAYOUT, requiresAuth: true }
  },
  {
    path: '/push-notifications',
    name: ROUTE_NAME.PUSH_NOTIFICATIONS,
    component: () => import('@/views/Profile/PushNotifications.vue'),
    meta: { layout: SHARED.PROFILE_LAYOUT, requiresAuth: true }
  },
  {
    path: '/delete-account',
    name: ROUTE_NAME.DELETE_ACCOUNT,
    component: () => import('@/views/Profile/DeleteAccount.vue'),
    meta: {
      layout: SHARED.PROFILE_LAYOUT,
      requiresAuth: true,
      disabledForAdmin: true
    }
  },
  {
    path: '/payment',
    name: ROUTE_NAME.PAYMENT,
    component: () => import('@/views/Profile/Payment/Payment.vue'),
    meta: {
      layout: SHARED.PROFILE_LAYOUT,
      requiresAuth: true,
      disabledForAdmin: true
    }
  },
  {
    path: '/payment/top-up',
    name: ROUTE_NAME.PAYMENT_GATEWAY,
    component: () => import('@/views/Profile/Payment/PaymentGateway.vue'),
    meta: { layout: SHARED.PROFILE_LAYOUT, requiresAuth: true }
  },
  {
    path: '/payment/member-plan',
    name: ROUTE_NAME.PAYMENT_MEMBER_PLAN,
    component: () => import('@/views/Profile/Payment/memberPlan.vue'),
    meta: { layout: SHARED.PROFILE_LAYOUT, requiresAuth: true }
  },
  {
    path: '/payment/history',
    name: ROUTE_NAME.PAYMENT_HISTORY,
    component: () => import('@/views/Profile/Payment/history.vue'),
    meta: { layout: SHARED.PROFILE_LAYOUT, requiresAuth: true }
  },
  {
    path: '/follower-following',
    name: ROUTE_NAME.FOLLOWER_FOLLOWING,
    component: () => import('@/views/Profile/FollowerFollowing.vue'),
    meta: { layout: SHARED.PROFILE_LAYOUT, requiresAuth: true, props: true }
  },
  {
    path: '/create_job',
    name: ROUTE_NAME.CREATE_JOB,
    props: true,
    component: () => import('@/views/CreateJob/index.vue'),
    meta: {
      layout: SHARED.PROFILE_LAYOUT,
      requiresAuth: true,
      accessRole: ['production_house', 'freelancer']
    }
  },
  {
    path: '/create_job/done',
    name: ROUTE_NAME.CREATE_JOB_DONE,
    component: () => import('@/views/CreateJob/Done.vue'),
    meta: {
      layout: SHARED.PROFILE_LAYOUT,
      requiresAuth: true,
      accessRole: ['production_house', 'freelancer']
    }
  },
  {
    path: '/create_job/invitation',
    name: ROUTE_NAME.CREATE_JOB_INVITATION,
    component: () => import('@/views/CreateJob/Invitation.vue'),
    meta: {
      layout: SHARED.PROFILE_LAYOUT,
      requiresAuth: true,
      accessRole: ['production_house', 'freelancer']
    }
  },
  {
    path: '/create_job/invitation/done',
    name: ROUTE_NAME.CREATE_JOB_INVITATION_DONE,
    props: true,
    component: () => import('@/views/CreateJob/InvitationPage/Done.vue'),
    meta: {
      layout: SHARED.PROFILE_LAYOUT,
      requiresAuth: true,
      accessRole: ['production_house', 'freelancer']
    }
  },
  {
    path: '/teaser',
    component: () => import('@/views/teaser/Teaser.vue'),
    children: [
      {
        path: '',
        name: ROUTE_NAME.TEASER,
        component: () => import('@/views/teaser/TeaserApplication.vue'),
        meta: {
          layout: SHARED.DEFAULT_LAYOUT,
          sitemap: {
            lastmod: '2021-10-15',
            priority: 0.5,
            changefreq: 'monthly'
          }
        }
      },
      {
        path: 'teaser-register-success',
        name: ROUTE_NAME.TEASER_REGISTER_SUCCESS,
        component: () => import('@/views/teaser/TeaserRegisterSuccess.vue'),
        meta: {
          layout: SHARED.DEFAULT_LAYOUT_WITH_FOOTER,
          sitemap: { ignoreRoute: true }
        }
      }
    ]
  },
  {
    path: '/terms-of-use',
    name: ROUTE_NAME.TERMS_OF_USE,
    component: () => import('@/views/info/TermsOfUse.vue'),
    meta: {
      layout: SHARED.DEFAULT_LAYOUT,
      sitemap: { lastmod: '2021-10-21', priority: 0.5, changefreq: 'monthly' }
    }
  },
  {
    path: '/guardian-consent',
    name: ROUTE_NAME.GUARDIAN_CONSENT,
    component: () => import('@/views/info/GuardianConsent.vue'),
    meta: {
      layout: SHARED.DEFAULT_LAYOUT,
      sitemap: { lastmod: '2021-10-21', priority: 0.5, changefreq: 'monthly' }
    }
  },
  {
    path: '/faq',
    name: ROUTE_NAME.FAQ,
    component: () => import('@/views/info/FAQ.vue'),
    meta: {
      layout: SHARED.DEFAULT_LAYOUT,
      sitemap: { lastmod: '2021-10-21', priority: 0.5, changefreq: 'monthly' }
    }
  },
  {
    path: '/newsroom',
    name: ROUTE_NAME.NEWSROOM,
    component: () => import('@/views/info/Newsroom.vue'),
    meta: {
      layout: SHARED.DEFAULT_LAYOUT,
      sitemap: { lastmod: '2021-10-21', priority: 0.5, changefreq: 'monthly' }
    }
  },
  {
    path: '/privacy-policy',
    name: ROUTE_NAME.PRIVACY_POLICY,
    component: () => import('@/views/info/PrivacyPolicy.vue'),
    meta: {
      layout: SHARED.DEFAULT_LAYOUT,
      sitemap: { lastmod: '2021-10-21', priority: 0.5, changefreq: 'monthly' }
    }
  },
  {
    path: '/about-us',
    name: ROUTE_NAME.ABOUT_US,
    component: () => import('@/views/about/AboutUs.vue'),
    meta: {
      layout: SHARED.DEFAULT_LAYOUT,
      sitemap: { lastmod: '2021-10-21', priority: 0.5, changefreq: 'monthly' }
    }
  },
  {
    path: '/scan',
    name: ROUTE_NAME.SCAN,
    component: () => import('@/views/Scan.vue'),
    meta: {
      layout: SHARED.DEFAULT_LAYOUT
    }
  },
  {
    path: '/signup',
    name: ROUTE_NAME.SIGNUP,
    component: () => import('@/views/Signup/index.vue'),
    meta: {
      layout: SHARED.DEFAULT_LAYOUT,
      beforeLogin: true
    }
  },
  {
    path: '*',
    redirect: { name: ROUTE_NAME.HOME }
  }
];
