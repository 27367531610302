import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VOtpInput } from 'vuetify/lib/components/VOtpInput';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.step == 0)?[_c('p',{staticClass:"font-weight-light text-lg-h6 text-xl-h5"},[_vm._v(" A 6-digit code has been sent via "+_vm._s(_vm.formValues.auth_method === 'mobile' ? 'SMS' : 'email')+" to "+_vm._s(_vm.formValues.auth_method === 'mobile' ? _vm.formValues.mobile_dial_code + '-' + _vm.formValues.mobile : _vm.formValues.email)+" ")]),_c('div',{staticClass:"mb-12 mt-5"},[_c(VOtpInput,{staticClass:"otp-input",attrs:{"dark":"","length":"6","type":"number"},on:{"finish":_vm.verifyOTPAction},model:{value:(_vm.otp),callback:function ($$v) {_vm.otp=$$v},expression:"otp"}})],1)]:(_vm.step == 1)?[_c('h1',{staticClass:"text-uppercase"},[_vm._v("Create Password")]),_c('p',{staticClass:"font-weight-light text-lg-h6 text-xl-h5"},[_vm._v(" Create a high security password ")]),_c(VForm,{model:{value:(_vm.validPassword),callback:function ($$v) {_vm.validPassword=$$v},expression:"validPassword"}},[_c('div',{staticClass:"my-3"},[_c('AppFormField',{staticClass:"password-input",attrs:{"name":"password_id","label":_vm.$t('label.password'),"rules":_vm.formValidation.passwordRules(_vm.form.password),"type":"password","allowShowPassword":_vm.show.password,"required":"","outlined":"","dark":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('AppFormField',{staticClass:"password-input",attrs:{"name":"password_id","label":_vm.$t('label.passwordConfirmation'),"rules":_vm.formValidation.confirmPasswordRules(
              _vm.form.password,
              _vm.form.passwordConfirmation
            ),"type":"password","allowShowPassword":_vm.show.passwordConfirmation,"required":"","outlined":"","dark":""},model:{value:(_vm.form.passwordConfirmation),callback:function ($$v) {_vm.$set(_vm.form, "passwordConfirmation", $$v)},expression:"form.passwordConfirmation"}})],1)]),_c('div',{staticClass:"text-center mt-12 pt-4"},[_c(VBtn,{staticClass:"btn1",attrs:{"disabled":!_vm.validPassword},on:{"click":_vm.createPassword}},[_vm._v(" Create ")])],1)]:(_vm.step == 2)?[_c('h2',{staticClass:"text-uppercase mb-12 mt-2 text-center"},[_vm._v(" First time login is completed. I'm ready to log in to meet people. ")]),_c('div',{staticClass:"text-center pt-5"},[_c(VBtn,{staticClass:"btn1",on:{"click":_vm.login}},[_vm._v(" Log In ")])],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }