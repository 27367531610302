import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VOtpInput } from 'vuetify/lib/components/VOtpInput';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VForm,{ref:"form",model:{value:(_vm.authMethodValid),callback:function ($$v) {_vm.authMethodValid=$$v},expression:"authMethodValid"}},[_c('h1',{staticClass:"white--text mb-10"},[_vm._v("Forgot Password")]),_c(VSpacer),(_vm.formValues.auth_method == '')?[_c('div',{staticClass:"d-flex flex-column text-center mt-10"},[_c(VBtn,{staticClass:"mr-4 btn1",attrs:{"color":"error"},on:{"click":function($event){_vm.formValues.auth_method = 'mobile'}}},[_vm._v(" Mobile No. ")]),_c(VBtn,{staticClass:"mr-4 mt-5 btn1",attrs:{"color":"error"},on:{"click":function($event){_vm.formValues.auth_method = 'email'}}},[_vm._v(" Email ")])],1)]:(_vm.stage == 1)?[_c('p',{staticClass:"font-weight-light text-lg-h6 text-xl-h5"},[_vm._v(" A 6-digit code has been sent via "+_vm._s(_vm.formValues.auth_method === 'mobile' ? 'SMS' : 'email')+" to "+_vm._s(_vm.formValues.auth_method === 'mobile' ? _vm.formValues.mobile_dial_code + '-' + _vm.formValues.mobile : _vm.formValues.email)+" ")]),_c('p',{staticClass:"text-lg-h6 text-xl-h5 pink2--text"},[_vm._v(" "+_vm._s(_vm.$t('label.resendCodeIn'))+" "),_c('span',{attrs:{"id":"timer"}})]),_c(VOtpInput,{attrs:{"dark":"","length":"6","type":"number"},on:{"change":_vm.verifyingOTP}})]:(_vm.stage == 2)?[_c(VTextField,{attrs:{"rules":_vm.concatRequiredRules(
          _vm.formValidation.passwordRules(_vm.formValues.password)
        ),"label":"Create Password","required":"","outlined":"","append-icon":!_vm.hide ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.hide ? 'password' : 'text'},on:{"click:append":function () { return (_vm.hide = !_vm.hide); }},model:{value:(_vm.formValues.password),callback:function ($$v) {_vm.$set(_vm.formValues, "password", $$v)},expression:"formValues.password"}}),_c(VTextField,{attrs:{"rules":_vm.concatRequiredRules(
          _vm.formValidation.confirmPasswordRules(
            _vm.formValues.password,
            _vm.formValues.password_confirmation
          )
        ),"label":"Retype Password","required":"","outlined":"","append-icon":!_vm.hide ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.hide ? 'password' : 'text'},on:{"click:append":function () { return (_vm.hide = !_vm.hide); }},model:{value:(_vm.formValues.password_confirmation),callback:function ($$v) {_vm.$set(_vm.formValues, "password_confirmation", $$v)},expression:"formValues.password_confirmation"}})]:[(_vm.formValues.auth_method == 'email')?_c(VTextField,{attrs:{"rules":_vm.concatRequiredRules(_vm.formValidation.emailRules()),"label":"Email","required":"","outlined":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login.apply(null, arguments)}},model:{value:(_vm.formValues.email),callback:function ($$v) {_vm.$set(_vm.formValues, "email", $$v)},expression:"formValues.email"}}):_c('vue-tel-input-vuetify',{attrs:{"preferredCountries":['SG', 'MY'],"defaultCountry":"SG","label":"Mobile No.","mode":"","required":"","outlined":"","rules":_vm.concatRequiredRules(
          _vm.formValidation.mobileNumberRules(
            _vm.formValues.mobile_dial_code,
            _vm.formValues.mobile
          )
        )},on:{"input":_vm.onNumberChange,"country-changed":_vm.updateCountry,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login.apply(null, arguments)}},model:{value:(_vm.formValues.mobile),callback:function ($$v) {_vm.$set(_vm.formValues, "mobile", $$v)},expression:"formValues.mobile"}})],_c('div',{staticClass:"d-flex flex-column text-center mt-10"},[(_vm.formValues.auth_method != '')?_c('div',{staticClass:"mt-5"},[(_vm.stage == 0)?[_c(VBtn,{staticClass:"btn1",attrs:{"disabled":!_vm.authMethodValid},on:{"click":_vm.requestOTP}},[_vm._v(" Send OTP ")]),_c('a',{staticClass:"d-block mt-5",on:{"click":function($event){_vm.formValues.auth_method = ''}}},[_vm._v("back")])]:_vm._e(),(_vm.stage == 1)?_c(VBtn,{staticClass:"btn1",attrs:{"disabled":_vm.isResendDisabled},on:{"click":_vm.requestOTP}},[_vm._v(" "+_vm._s(_vm.$t('label.resendOTP'))+" ")]):_vm._e(),(_vm.stage == 2)?_c(VBtn,{staticClass:"btn1",attrs:{"disabled":!_vm.isValidPassword},on:{"click":_vm.resetPassword}},[_vm._v(" Reset ")]):_vm._e()],2):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }