import { PLATFORM_INFO } from '@/api';
import { apiHelper } from '@/utils';
import {
  SHARED_OPEN_LOADING,
  SHARED_CLOSE_LOADING
} from '@/store/shared.module';

/* Define Module Name */
const MODULE_NAME = 'platformInfo/';

/* Define Action Name */
const A_GET_PLATFORM_INFO = 'getPlatformInfo';

/* Define Reset State Action Name */
const A_INITIAL_GET_PLATFORM_INFO_STATE = 'initialGetPlatformInfoState';
/* Define Getter Name */

/* Define Mutation Name */
const M_GET_PLATFORM_INFO = 'setGetPlatformInfo';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_PLATFORM_INFO_STATE = 'setInitialGetPlatformInfoState';

/* Define Export Name */
export const PLATFORM_INFO_GET_PLATFORM_INFO =
  MODULE_NAME + A_GET_PLATFORM_INFO;

/* Define Reset State Name */
export const PLATFORM_INFO_INITIAL_GET_PLATFORM_INFO_STATE =
  MODULE_NAME + A_INITIAL_GET_PLATFORM_INFO_STATE;

const state = {
  platformInfo: {
    code: 0,
    complete: false,
    data: {},
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_PLATFORM_INFO]({ dispatch, commit }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PLATFORM_INFO.getPlatformInfo()
      .then((response) => {
        result = response;
        commit(M_GET_PLATFORM_INFO, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_PLATFORM_INFO, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_PLATFORM_INFO_STATE]({ commit }) {
    commit(M_INITIAL_GET_PLATFORM_INFO_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_PLATFORM_INFO](state, result) {
    if (result.code === 200) {
      let d = result.data;

      state.platformInfo = {
        code: result.code,
        complete: true,
        data: {
          name: d.name,
          phone: d.phone,
          email: d.email,
          address: d.address,
          instagram: d.instagram,
          facebook: d.facebook,
          whatsapp: d.whatsapp,
          youtube: d.youtube,
          linkedin: d.linkedin,
          tiktok: d.tiktok,
          terms: d.terms,
          faq: d.faq,
          aboutUs: d.about_us
        },
        message: null
      };
    } else {
      state.platformInfo = {
        code: result.code,
        complete: true,
        data: {},
        message: apiHelper.sortOutMessage(result.error_message)
      };
    }
  },
  [M_INITIAL_GET_PLATFORM_INFO_STATE](state) {
    let data = state.platformInfo.data;

    state.platformInfo = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
