import { apiService } from '@/services';
import { jwtHelper } from '../utils/jwt.helper';

export const AUTH = {
  me,
  checkUserExist,
  login,
  logout,
  refresh,
  checkAvailabilityTeaser,
  requestOTP,
  verifyOTP,
  registerTalent,
  registerAgency,
  registerFreelancer,
  registerProductionHouse,
  updateDetails,
  checkPasswordAge,
  resetPassword
};
const URL = {
  API: 'api',
  V1: 'v1',
  AUTH: 'auth',
  REGISTER: 'register',
  TALENT: 'talent',
  AGENCY: 'agency',
  FREELANCER: 'freelancer',
  PRODUCTION_HOUSE: 'production-house',
  UPDATE: 'update',
  CHECK_PASSWORD_AGE: 'check-password-age',
  CHECK_TEASER: 'check-teaser',
  CHECK: 'check',
  OTP: 'otp',
  REQUEST: 'request',
  VERIFY: 'verify',
  RESET_PASSWORD: 'reset-password',
  ME: 'me',
  PROFILE: 'profile',
  LOGIN: 'login',
  LOGOUT: 'logout',
  REFRESH: 'refresh'
};

function me() {
  return apiService().get(`/${URL.API}/${URL.V1}/${URL.PROFILE}`);
}

function checkUserExist(data) {
  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.AUTH}/${URL.CHECK}`,
    data
  );
}

function login(data) {
  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.AUTH}/${URL.LOGIN}`,
    data
  );
}

function logout() {
  return apiService().post(`/${URL.API}/${URL.V1}/${URL.AUTH}/${URL.LOGOUT}`);
}

function refresh() {
  return apiService().post(`/${URL.API}/${URL.AUTH}/${URL.REFRESH}`);
}

function checkPasswordAge() {
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.AUTH}/${URL.CHECK_PASSWORD_AGE}`
  );
}

function checkAvailabilityTeaser(data) {
  let requestBody = {
    name: data.name,
    mobile_dial_code: data.mobileDialCode,
    mobile: data.mobile,
    role: data.role,
    email: data.email,
    auth_method: data.auth_method
  };

  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.AUTH}/${URL.REGISTER}/${URL.CHECK_TEASER}`,
    requestBody
  );
}

function requestOTP(data) {
  let requestBody = {
    mobile_dial_code: data.mobileDialCode,
    mobile: data.mobile,
    auth_method: data.auth_method,
    email: data.email,
    otp_type: data.otpType
  };

  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.AUTH}/${URL.OTP}/${URL.REQUEST}`,
    requestBody
  );
}

function verifyOTP(data) {
  let requestBody = {
    mobile_dial_code: data.mobileDialCode,
    mobile: data.mobile,
    otp_type: data.otpType,
    auth_method: data.auth_method,
    email: data.email,
    code: data.code
  };

  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.AUTH}/${URL.OTP}/${URL.VERIFY}`,
    requestBody
  );
}

function resetPassword(data) {
  let requestBody = {
    mobile_dial_code: data.mobileDialCode,
    mobile: data.mobile,
    auth_method: data.auth_method,
    email: data.email,
    password: data.password,
    password_confirmation: data.password_confirmation
  };

  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.AUTH}/${URL.RESET_PASSWORD}`,
    requestBody
  );
}

function registerTalent(data) {
  let requestBody = {
    first_name: data.firstName,
    last_name: data.lastName,
    gender: data.gender ?? null,
    gender_other: data.genderOther ?? null,
    pronouns: data.pronouns ?? null,
    pronouns_other: data.pronounsOther ?? null,
    dob: data.dob ?? null,
    country_id: data.countryId ?? null,
    mobile_dial_code: data.mobileDialCode,
    mobile: data.mobile,
    email: data.email,
    guardian_relationship: data.guardianRelationship ?? null,
    guardian_relationship_other: data.guardianRelationshipOther ?? null,
    guardian_first_name: data.guardianFirstName ?? null,
    guardian_last_name: data.guardianLastName ?? null,
    guardian_gender: data.guardianGender ?? null,
    guardian_gender_other: data.guardianGenderOther ?? null,
    guardian_pronouns: data.guardianPronouns ?? null,
    guardian_pronouns_other: data.guardianPronounsOther ?? null,
    guardian_mobile_dial_code: data.guardianMobileDialCode ?? null,
    guardian_mobile: data.guardianMobile ?? null,
    guardian_email: data.guardianEmail ?? null,
    register_type: data.registerType ?? 'user',
    auth_method: data.getotpby,
    password: data.password,
    password_confirmation: data.passwordConfirmation
  };

  return apiService()
    .post(
      `/${URL.API}/${URL.V1}/${URL.AUTH}/${URL.REGISTER}/${URL.TALENT}`,
      requestBody
    )
    .then(setToken);
}

function registerAgency(data) {
  let requestBody = {
    name: data.name,
    first_name: data.firstName,
    last_name: data.lastName,
    description: data.description,
    description_other: data.descriptionOther,
    designation: data.designation,
    country_id: data.countryId,
    mobile_dial_code: data.mobileDialCode,
    mobile: data.mobile,
    email: data.email,
    talents_no: data.talentsNo,
    register_type: data.registerType ?? 'user',
    auth_method: data.getotpby,
    password: data.password,
    password_confirmation: data.passwordConfirmation
  };

  return apiService()
    .post(
      `/${URL.API}/${URL.V1}/${URL.AUTH}/${URL.REGISTER}/${URL.AGENCY}`,
      requestBody
    )
    .then(setToken);
}

function registerFreelancer(data) {
  let requestBody = {
    first_name: data?.firstName,
    last_name: data?.lastName,
    gender: data?.gender,
    gender_other: data?.genderOther,
    pronouns: data?.pronouns || [],
    pronouns_other: data?.pronounsOther,
    description: data?.description,
    description_other: data?.descriptionOther,
    designation: data?.designation,
    dob: data?.dob,
    country_id: data?.countryId || [],
    mobile_dial_code: data?.mobileDialCode,
    mobile: data?.mobile,
    email: data?.email,
    register_type: data?.registerType ?? 'user',
    auth_method: data?.getotpby,
    password: data?.password,
    password_confirmation: data?.passwordConfirmation
  };

  return apiService()
    .post(
      `/${URL.API}/${URL.V1}/${URL.AUTH}/${URL.REGISTER}/${URL.FREELANCER}`,
      requestBody
    )
    .then(setToken);
}

function registerProductionHouse(data) {
  let requestBody = {
    name: data.name,
    first_name: data.firstName,
    last_name: data.lastName,
    description: data.description,
    description_other: data.descriptionOther,
    designation: data.designation,
    country_id: data.countryId,
    mobile_dial_code: data.mobileDialCode,
    mobile: data.mobile,
    email: data.email,
    register_type: data.registerType ?? 'user',
    auth_method: data.getotpby,
    password: data.password,
    password_confirmation: data.passwordConfirmation
  };

  return apiService()
    .post(
      `/${URL.API}/${URL.V1}/${URL.AUTH}/${URL.REGISTER}/${URL.PRODUCTION_HOUSE}`,
      requestBody
    )
    .then(setToken);
}

function setToken(response) {
  jwtHelper.setToken(response.data.access_token);
}

function updateDetails(data) {
  let requestBody = data;

  return apiService(null, true, false, false).post(
    `/${URL.API}/${URL.V1}/${URL.AUTH}/${URL.REGISTER}/${URL.UPDATE}`,
    requestBody
  );
}
