import { apiService } from '@/services';

export const INBOX = {
  getInboxs,
  getInboxDetails,
  deleteInbox
};

const URL = {
  API: 'api',
  V1: 'v1',
  NOTIFICATION: 'notifications',
  UNREAD_COUNT: 'unread-count',
  NOTIFICATION_ID: 'notificationsid',
  DELETE: 'delete'
};

function getInboxs(data) {
  let requestOptions = {
    params: {
      per_page: data.perPage
    }
  };
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.NOTIFICATION}`,
    requestOptions
  );
}

function getInboxDetails(id) {
  return apiService().get(`/${URL.API}/${URL.V1}/${URL.NOTIFICATION}/${id}`);
}

function deleteInbox(id) {
  let requestBody = {
    id: id
  };
  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.NOTIFICATION}/${URL.DELETE}`,
    requestBody
  );
}
