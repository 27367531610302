<template>
  <v-btn
    rounded
    :x-large="xLarge"
    :disabled="disabled"
    :min-height="minHeight"
    :min-width="minWidth"
    :class="[textColor, color, { isFaded: faded }]"
    class="font-weight-medium px-12 text-capitalize app-button"
    @click="$emit('click')"
  >
    <slot />
  </v-btn>
</template>

<script>
  export default {
    name: 'AppButton',
    props: {
      type: {
        type: String,
        default: 'default'
      },
      disabled: {
        type: Boolean,
        default: false
      },
      faded: {
        type: Boolean,
        default: false
      },
      xLarge: {
        type: Boolean,
        default: true
      },
      minHeight: {
        type: String,
        default: ''
      },
      minWidth: {
        type: String,
        default: ''
      }
    },
    computed: {
      color() {
        let color = {
          default: 'default',
          recommended: 'tertiary',
          optional: 'secondary'
        };

        return color[this.type];
      },
      textColor() {
        let color = {
          default: 'surface--text',
          recommended: 'surface--text',
          optional: 'secondary--text'
        };

        return color[this.type];
      },
      outlined() {
        return this.type == 'optional' || this.disabled;
      }
    }
  };
</script>

<style lang="scss">
  .app-button {
    &.default {
      background: linear-gradient(
        90deg,
        var(--v-primary-base) 0%,
        var(--v-secondary-base) 60%,
        var(--v-tertiary-base) 70%,
        var(--v-quaternary-base) 80%,
        var(--v-senary-base) 90%,
        var(--v-septenary-base) 100%
      ) !important;
      &.v-btn.v-btn--disabled {
        opacity: 0.2;
        color: var(--v-surface-base) !important;
      }
    }
    &.isFaded {
      opacity: 0.8;
    }
  }
</style>
