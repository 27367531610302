<template>
  <v-dialog v-model="show" max-width="90%" width="auto" @click:outside="$emit('close')">
    <div class="d-flex">
      <img :src="image" crossorigin="anonymous" class="modal-image"/>
      <!-- <ImageZoom
        :regular="image" 
        :zoom-amount="3"
        click-zoom
        img-class="modal-image"
        lazyload
      >
        
      </ImageZoom> -->
    </div>
  </v-dialog>
</template>

<script>
// import ImageZoom from 'vue-image-zoomer';

export default {
  name: "AppImageModal",
  components: {
    // ImageZoom
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    image: {
      type: String,
    }
  },
}
</script>

<style scoped lang="scss">
  img.modal-image {
    max-width: 100%; 
    width: auto; 
    max-height: 500px;
  }

  @media screen and (max-width: 500px) {
    img.modal-image {
      max-width: 100%; 
      width: auto; 
      max-height: 100%;
    }
  }
</style>