<template>
  <v-form ref="form" v-model="authMethodValid">
    <h1 class="white--text mb-10">Forgot Password</h1>
    <v-spacer></v-spacer>

    <template v-if="formValues.auth_method == ''">
      <div class="d-flex flex-column text-center mt-10">
        <v-btn
          color="error"
          class="mr-4 btn1"
          @click="formValues.auth_method = 'mobile'"
        >
          Mobile No.
        </v-btn>
        <v-btn
          color="error"
          class="mr-4 mt-5 btn1"
          @click="formValues.auth_method = 'email'"
        >
          Email
        </v-btn>
      </div>
    </template>
    <template v-else-if="stage == 1">
      <p class="font-weight-light text-lg-h6 text-xl-h5">
        A 6-digit code has been sent via
        {{ formValues.auth_method === 'mobile' ? 'SMS' : 'email' }} to
        {{
          formValues.auth_method === 'mobile'
            ? formValues.mobile_dial_code + '-' + formValues.mobile
            : formValues.email
        }}
      </p>
      <p class="text-lg-h6 text-xl-h5 pink2--text">
        {{ $t('label.resendCodeIn') }} <span id="timer"></span>
      </p>
      <v-otp-input dark length="6" @change="verifyingOTP" type="number">
      </v-otp-input>
    </template>
    <template v-else-if="stage == 2">
      <v-text-field
        v-model="formValues.password"
        :rules="
          concatRequiredRules(
            formValidation.passwordRules(formValues.password)
          )
        "
        label="Create Password"
        required
        outlined
        :append-icon="!hide ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="() => (hide = !hide)"
        :type="hide ? 'password' : 'text'"
      ></v-text-field>
      <v-text-field
        v-model="formValues.password_confirmation"
        :rules="
          concatRequiredRules(
            formValidation.confirmPasswordRules(
              formValues.password,
              formValues.password_confirmation
            )
          )
        "
        label="Retype Password"
        required
        outlined
        :append-icon="!hide ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="() => (hide = !hide)"
        :type="hide ? 'password' : 'text'"
      ></v-text-field>
    </template>
    <template v-else>
      <v-text-field
        v-if="formValues.auth_method == 'email'"
        v-model="formValues.email"
        :rules="
          concatRequiredRules(formValidation.emailRules())
        "
        label="Email"
        required
        outlined
        @keydown.enter="login"
      ></v-text-field>
      <vue-tel-input-vuetify
        v-else
        :preferredCountries="['SG', 'MY']"
        defaultCountry="SG"
        v-model="formValues.mobile"
        label="Mobile No."
        mode=""
        required
        outlined
        :rules="
          concatRequiredRules(
            formValidation.mobileNumberRules(
              formValues.mobile_dial_code,
              formValues.mobile
            )
          )"
        @input="onNumberChange"
        @country-changed="updateCountry"
        @keydown.enter="login"
      ></vue-tel-input-vuetify>
    </template>
    <div class="d-flex flex-column text-center mt-10">
      <div v-if="formValues.auth_method != ''" class="mt-5">
        <template v-if="stage == 0">
          <v-btn
            class="btn1"
            :disabled="!authMethodValid"
            @click="requestOTP"
          >
            Send OTP
          </v-btn>
          <a class="d-block mt-5" @click="formValues.auth_method = ''">back</a>
        </template>
        <v-btn
          class="btn1"
          v-if="stage == 1"
          :disabled="isResendDisabled"
          @click="requestOTP"
        >
          {{ $t('label.resendOTP') }}
        </v-btn>
        <v-btn
          class="btn1"
          v-if="stage == 2"
          :disabled="!isValidPassword"
          @click="resetPassword"
        >
          Reset
        </v-btn>
      </div>
    </div>
  </v-form>
</template>
<script>
  import { mapGetters } from 'vuex';
  import { AUTH_IS_AUTHENTICATED } from '@/store/auth.module';
  import { AUTH } from '@/api';
  import { OTP_TYPE } from '@/constants/enums/otp-type.enum.js';
  import { formValidationHelper } from '@/utils';
  import customFormValidationMixin from '@/mixin/custom-form-validation.mixin';

  export default {
    name: 'ForgotPasswordForm',
    mixins: [customFormValidationMixin],
    data() {
      return {
        loginCompleteMessage: '',
        formValues: {
          auth_method: '',
          mobile_dial_code: '+65',
          mobile: '',
          email: '',
          password: '',
          password_confirmation: ''
        },
        authMethodValid: true,
        hide: true,
        isResendDisabled: true,
        stage: 0,
        formValidation: formValidationHelper,
        passwordRules: [
          (value) => !!value || 'Required.',
          (value) => (value || '').length > 7 || '8 characters minimum'
        ],
        formValid: {}
      };
    },
    props: {
      width: {
        type: String,
        default: '600'
      }
    },
    methods: {
      onNumberChange(v, v2) {
        this.formValues.mobile = v2.number.significant;
      },
      updateCountry(event) {
        this.formValues.mobile_dial_code = '+' + event.dialCode;
      },
      async requestOTP(e) {
        try {
          e.preventDefault();
          this.isResendDisabled = true;
          const data = {
            ...this.formValues,
            mobileDialCode: this.formValues.mobile_dial_code,
            otpType: OTP_TYPE.RESET_PASSWORD
          };
          this.stage = 1;
          this.$nextTick(() => {
            this.setResendOTPTimer(); 
          });
          
          await AUTH.requestOTP(data);
        } catch (e) {
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                  this.stage = 0;
                }
              }
            ]
          );
        }
      },
      setResendOTPTimer() {
        let vm = this;
        document.getElementById('timer').innerHTML = 1 + ':' + 0;
        const checkSecond = (sec) => {
          if (sec < 10 && sec >= 0) {
            sec = '0' + sec;
          } // add zero in front of numbers < 10
          if (sec < 0) {
            sec = '59';
          }
          return sec;
        }
        const startTimer = () => {
          var presentTime = document.getElementById('timer').innerHTML;
          var timeArray = presentTime.split(/[:]+/);
          var m = timeArray[0];
          var s = checkSecond(timeArray[1] - 1);
          if (s == 59) {
            m = m - 1;
          }
          if (m < 0) {
            return;
          }

          document.getElementById('timer').innerHTML = m + ':' + s;

          if (m == '0' && s == '00') {
            vm.isResendDisabled = false;
          }

          setTimeout(startTimer, 1000);
        };

        startTimer();

      },
      async verifyingOTP(v) {
        try {
          const data = {
            ...this.formValues,
            mobileDialCode: this.formValues.mobile_dial_code,
            otpType: OTP_TYPE.RESET_PASSWORD,
            code: v
          };

          this.isBtnDisable = true;
          this.verifyOTP = true;
          await AUTH.verifyOTP(data);
          this.stage = 2;
        } catch (e) {
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }

        this.isBtnDisable = false;
        this.verifyOTP = false;
      },
      async resetPassword() {
        try {
          const data = {
            ...this.formValues,
            mobileDialCode: this.formValues.mobile_dial_code
          };
          await AUTH.resetPassword(data);

          this.openAppDialogInfo('success', 'Reset Password Successfully', '', [
            {
              text: 'OK',
              action: () => {
                this.closeAppDialogInfo();
                this.$emit('close');
              }
            }
          ]);
        } catch (e) {
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
      }
    },
    computed: {
      ...mapGetters({
        isAuthenticated: [AUTH_IS_AUTHENTICATED]
      }),
      dialog() {
        return this.$store.state.shared.openModal;
      },
      isValidPassword() {
        return (
          this?.formValues?.password != '' &&
          (this?.formValues?.password || '').length > 7 &&
          this?.formValues?.password_confirmation != '' &&
          this?.formValues?.password == this?.formValues?.password_confirmation
        );
      }
    }
  };
</script>
<style lang="scss">
  .baseModal {
    background-color: #242424;
    width: 100%;
    min-height: 100px;
  }
</style>
