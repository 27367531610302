import { LOCAL } from '@/constants';
import { uiHelper } from '@/utils';

export default function constantsProjectTypes(value) {
  let header = 'project_types';
  let description;
  let newArray = [];
  if (header && !['', undefined, null].includes(value)) {
    if (Array.isArray(value)) {
      if (value.length > 0) {
        value.forEach((x) => {
          newArray.push(getConstants(header, x));
        });
        description = newArray;
      } else {
        description = '-';
      }
    } else {
      description = getConstants(header, value);
    }
  } else {
    description = '-';
  }
  return description;
}
function getConstants(header, value) {
  let constantsData = JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS));

  let data = {
    id: constantsData[header].find((x) => x.id == value).id,
    name: constantsData[header].find((x) => x.id == value).name,
    datasetId: constantsData[header].find((x) => x.id == value).dataset_id,
    isDeleted: constantsData[header].find((x) => x.id == value).is_deleted
  };
  return data;
}
