import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":_vm.dialogInfo.isPersistent,"width":"450","content-class":"app-dialog-info","dark":""},model:{value:(_vm.dialogInfo.show),callback:function ($$v) {_vm.$set(_vm.dialogInfo, "show", $$v)},expression:"dialogInfo.show"}},[_c(VCard,{staticClass:"d-flex justify-center align-center pa-10 pa-sm-5",attrs:{"min-height":"300"}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"text-center"},[(_vm.dialogInfo.type == 'info')?_c(VIcon,{staticClass:"icon",attrs:{"color":"primary"}},[_vm._v("mdi-information")]):_vm._e(),(_vm.dialogInfo.type == 'error')?_c(VIcon,{staticClass:"icon",attrs:{"color":"error"}},[_vm._v("mdi-alert-octagon")]):_vm._e(),(_vm.dialogInfo.type == 'warning')?_c(VIcon,{staticClass:"icon",attrs:{"color":"warning"}},[_vm._v("mdi-alert")]):_vm._e(),(_vm.dialogInfo.type == 'success')?_c(VIcon,{staticClass:"icon",attrs:{"color":"success"}},[_vm._v("mdi-check-bold")]):_vm._e()],1),_c('div',{staticClass:"text-h5 text-center text-uppercase font-weight-bold mt-5 mb-2"},[_vm._v(" "+_vm._s(_vm.dialogInfo.title)+" ")]),_c('div',{staticClass:"text-center mb-6"},[_vm._v(" "+_vm._s(_vm.dialogInfo.description)+" ")]),_c(VRow,{staticClass:"d-flex justify-space-around mt-10"},[_vm._l((_vm.dialogInfo.buttons),function(items,index){return [_c(VCol,{key:index,staticClass:"pa-1 pa-md-2",attrs:{"cols":"12","md":"6"}},[_c(VBtn,{class:items.class || '',attrs:{"color":items.color,"outlined":items.outlined,"rounded":"","depressed":"","block":"","min-height":"40"},on:{"click":items.action}},[_vm._v(" "+_vm._s(items.text)+" ")])],1)]})],2)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }