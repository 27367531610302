<template>
  <span class="countryFilter">
    <slot></slot>

    <v-select
      v-model="selectedCountryIds"
      id="selectcountry"
      name="country_id"
      ref="input"
      label="test"
      type="select"
      :items="countryList"
      :multiple="true"
      :disabled="false"
      item-color="background"
      color="surface"
      outlined
      dark
      @change="onInputChange"
    >
      <template v-slot:prepend-item>
        <v-list-item>
          <v-text-field
            v-model="searchTerm"
            placeholder="Search"
            @input="searchSelect"
          ></v-text-field>
        </v-list-item>
        <v-divider class="my-2"></v-divider>
        <v-list-item ripple @mousedown.prevent @click="toggleSelectAll">
          <v-list-item-action>
            <v-icon style="color: unset">
              {{ icon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> Select All </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
    </v-select>
  </span>
</template>

<script>
  import { defineComponent } from 'vue';
  import { uiHelper } from '@/utils';
  import { LOCAL } from '@/constants';
  import { mapGetters } from 'vuex';
  import _ from 'lodash';

  export default defineComponent({
    name: 'CustomCountrySelect',
    data: () => ({
      countryList: [],

      isCountryAll: false,
      selectedCountryIds: [],
      toggleCountryFilter: false,

      searchTerm: ''
    }),
    computed: {
      ...mapGetters({
        allCountries: 'constants/allCountries'
      }),
      constantsData() {
        let constants = uiHelper.getLocalStorage(LOCAL.CONSTANTS);

        return constants
          ? JSON.parse(constants)
          : this.$store.state.constants.constatns.data;
      },
      selectedAll() {
        return this.selectedCountryIds.length === this.countryList.length;
      },
      someSelected() {
        return this.selectedCountryIds.length > 0 && !this.selectedAll;
      },
      icon() {
        if (this.selectedAll) return 'mdi-close-box';
        if (this.someSelected) return 'mdi-minus-box';
        return 'mdi-checkbox-blank-outline';
      }
    },
    mounted() {
      this.countryList = this.allCountries;
    },
    methods: {
      toggle() {
        document.getElementById('selectcountry').click();
        //this.toggleCountryFilter = !this.toggleCountryFilter;
        //this.$refs.input.isMenuActive = this.toggleCountryFilter;
      },
      toggleSelectAll() {
        this.isCountryAll = !this.isCountryAll;

        this.selectedCountryIds = this.isCountryAll ? this.countryList : [];
        this.onInputChange();
      },
      onInputChange() {
        const selectedArray = this.selectedCountryIds.map((i) =>
          i.value ? i.value : i
        );
        this.$emit('onchange', selectedArray);
      },
      searchSelect() {
        this.countryList = (this.allCountries || []).filter((i) =>
          _.includes(i.text.toLowerCase(), this.searchTerm.toLowerCase())
        );

        return this.projectList;
      }
    }
  });
</script>

<style scoped>
  .countryFilter > .v-input {
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    visibility: hidden;
  }

  .countryFilterToggle {
    cursor: pointer;
  }
</style>
