import { JOB_ORGANIZER } from '@/api';
import { apiHelper } from '@/utils';
import {
  SHARED_OPEN_LOADING,
  SHARED_CLOSE_LOADING
} from '@/store/shared.module';

/* Define Module Name */
const MODULE_NAME = 'jobOrganizer/';

/* Define Action Name */
const A_GET_CASTING_JOB_BY_ID = 'getCastingJobById';
const A_GET_TALENT_LIST = 'getTalentList';
const A_APPLY_ROLE = 'applyRole';
const A_GET_DRAFT_JOBS = 'getDraftJobs';
const A_UPDATE_INVITATION_STATUS = 'updateInvitationStatus';
const A_CHECK_REAPPLY = 'checkReapply';

/* Define Reset State Action Name */
const A_INITIAL_GET_CASTING_JOB_BY_ID_STATE = 'initialGetCastingJobByIdState';
const A_INITIAL_GET_TALENT_LIST_STATE = 'initialGetTalentListState';
const A_INITIAL_APPLY_ROLE_STATE = 'initialApplyRoleState';
const A_INITIAL_GET_DRAFT_JOBS_STATE = 'initialGetDraftJobsState';
const A_INITIAL_UPDATE_INVITATION_STATUS_STATE =
  'initialUpdateInvitationStatusState';
const A_INITIAL_CHECK_REAPPLY_STATE =
  'initialCheckReapplyState';

/* Define Mutation Name */
const M_GET_CASTING_JOB_BY_ID = 'setGetCastingJobById';
const M_GET_TALENT_LIST = 'setGetTalentList';
const M_APPLY_ROLE = 'setApplyRole';
const M_GET_DRAFT_JOBS = 'setGetDraftJobs';
const M_UPDATE_INVITATION_STATUS = 'setUpdateInvitationStatus';
const M_CHECK_REAPPLY = 'setCheckReapply';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_CASTING_JOB_BY_ID_STATE =
  'setInitialGetCastingJobByIdState';
const M_INITIAL_GET_TALENT_LIST_STATE = 'setInitialGetTalentListState';
const M_INITIAL_APPLY_ROLE_STATE = 'setInitialApplyRoleState';
const M_INITIAL_GET_DRAFT_JOBS_STATE = 'setInitialGetDraftJobsState';
const M_INITIAL_UPDATE_INVITATION_STATUS_STATE =
  'setInitialUpdateInvitationStatusState';
const M_INITIAL_CHECK_REAPPLY_STATE =
  'setInitialCheckReapplyState';

/* Define Export Name */
export const JOB_ORGANIZER_GET_CASTING_JOB_BY_ID =
  MODULE_NAME + A_GET_CASTING_JOB_BY_ID;
export const JOB_ORGANIZER_GET_TALENT_LIST = MODULE_NAME + A_GET_TALENT_LIST;
export const JOB_ORGANIZER_APPLY_ROLE = MODULE_NAME + A_APPLY_ROLE;
export const JOB_ORGANIZER_GET_DRAFT_JOBS = MODULE_NAME + A_GET_DRAFT_JOBS;
export const JOB_ORGANIZER_UPDATE_INVITATION_STATUS =
  MODULE_NAME + A_UPDATE_INVITATION_STATUS;
export const JOB_ORGANIZER_CHECK_REAPPLY =
  MODULE_NAME + A_CHECK_REAPPLY;

/* Define Reset State Name */
export const JOB_ORGANIZER_INITIAL_GET_CASTING_JOB_BY_ID_STATE =
  MODULE_NAME + A_INITIAL_GET_CASTING_JOB_BY_ID_STATE;
export const JOB_ORGANIZER_INITIAL_GET_TALENT_LIST_STATE =
  MODULE_NAME + A_INITIAL_GET_TALENT_LIST_STATE;
export const JOB_ORGANIZER_INITIAL_APPLY_ROLE_STATE =
  MODULE_NAME + A_INITIAL_APPLY_ROLE_STATE;
export const JOB_ORGANIZER_INITIAL_GET_DRAFT_JOBS_STATE =
  MODULE_NAME + A_INITIAL_GET_DRAFT_JOBS_STATE;
export const JOB_ORGANIZER_INITIAL_UPDATE_INVITATION_STATUS_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_INVITATION_STATUS_STATE;
export const JOB_ORGANIZER_INITIAL_CHECK_REAPPLY_STATE =
  MODULE_NAME + A_INITIAL_CHECK_REAPPLY_STATE;

//State
const state = {
  getCastingJobById: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  getTalentList: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  applyRole: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  getDraftJobs: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  updateInvitationStatus: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  checkReapply: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
};

//Getters
const getters = {};

// Actions
const actions = {
  async [A_GET_CASTING_JOB_BY_ID]({ dispatch, commit }, { id }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await JOB_ORGANIZER.getCastingJobById(id)
      .then((response) => {
        result = response;
        commit(M_GET_CASTING_JOB_BY_ID, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_CASTING_JOB_BY_ID, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_GET_TALENT_LIST]({ dispatch, commit }, { data }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await JOB_ORGANIZER.getTalentList(data)
      .then((response) => {
        result = response;
        commit(M_GET_TALENT_LIST, {...result, ...data});
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_TALENT_LIST, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_APPLY_ROLE]({ dispatch, commit }, { data }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await JOB_ORGANIZER.applyRole(data)
      .then((response) => {
        result = response;
        commit(M_APPLY_ROLE, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_APPLY_ROLE, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_GET_DRAFT_JOBS]({ dispatch, commit }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await JOB_ORGANIZER.getDraftJob()
      .then((response) => {
        result = response;
        commit(M_GET_DRAFT_JOBS, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_DRAFT_JOBS, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_UPDATE_INVITATION_STATUS](
    { dispatch, commit },
    { id, data, notificationId = null, roleId = null }
  ) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await JOB_ORGANIZER.updateInvitationStatus(id, data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_INVITATION_STATUS, {
          ...result,
          extra: {
            status: data.status,
            notificationId,
            roleId
          }
        });
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_INVITATION_STATUS, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_CHECK_REAPPLY](
    { dispatch, commit },
    { data }
  ) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await JOB_ORGANIZER.checkReapply(data)
      .then((response) => {
        result = response;
        commit(M_CHECK_REAPPLY, {
          ...result,
          extra: {
            ...data
          }
        });
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_CHECK_REAPPLY, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },

  [A_INITIAL_GET_CASTING_JOB_BY_ID_STATE]({ commit }) {
    commit(M_INITIAL_GET_CASTING_JOB_BY_ID_STATE);
  },
  [A_INITIAL_GET_TALENT_LIST_STATE]({ commit }) {
    commit(M_INITIAL_GET_TALENT_LIST_STATE);
  },
  [A_INITIAL_APPLY_ROLE_STATE]({ commit }) {
    commit(M_INITIAL_APPLY_ROLE_STATE);
  },
  [A_INITIAL_GET_DRAFT_JOBS_STATE]({ commit }) {
    commit(M_INITIAL_GET_DRAFT_JOBS_STATE);
  },
  [A_INITIAL_UPDATE_INVITATION_STATUS_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_INVITATION_STATUS_STATE);
  },
  [A_INITIAL_CHECK_REAPPLY_STATE]({ commit }) {
    commit(M_INITIAL_CHECK_REAPPLY_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_CASTING_JOB_BY_ID](state, result) {
    state.getCastingJobById = {
      code: result.code,
      complete: true,
      data: result.data,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_GET_TALENT_LIST](state, result) {
    state.getTalentList = {
      code: result.code,
      complete: true,
      data: {
        talents: result.data,
        roleId: result.casting_role_id
      },
      message: result.code == 200 ? null : apiHelper.sortOutMessage(result.message)
    };
  },
  [M_APPLY_ROLE](state, result) {
    state.applyRole = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_GET_DRAFT_JOBS](state, result) {
    state.getDraftJobs = {
      code: result.code,
      complete: true,
      data: result.data,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_INVITATION_STATUS](state, result) {
    state.updateInvitationStatus = {
      code: result.code,
      complete: true,
      data: result.code == 200 ? result.extra : null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_CHECK_REAPPLY](state, result) {
    state.checkReapply = {
      code: result.code,
      complete: true,
      data: result.code == 200 
        ? {
          isExistingApplication: result.data.some(i => i.is_existing_application),
          extra: result.extra
          } 
        : null,
      message: result.code !== 200 ? apiHelper.sortOutMessage(result.error_message) : null
    };
  },

  [M_INITIAL_GET_CASTING_JOB_BY_ID_STATE](state) {
    let data = state.getCastingJobById.data;

    state.getCastingJobById = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_GET_TALENT_LIST_STATE](state) {
    let data = state.getTalentList.data;

    state.getTalentList = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_APPLY_ROLE_STATE](state) {
    let data = state.applyRole.data;

    state.applyRole = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_GET_DRAFT_JOBS_STATE](state) {
    let data = state.getDraftJobs.data;

    state.getDraftJobs = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_UPDATE_INVITATION_STATUS_STATE](state) {
    let data = state.updateInvitationStatus.data;

    state.updateInvitationStatus = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_CHECK_REAPPLY_STATE](state) {
    let data = state.checkReapply.data;

    state.checkReapply = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
