<template>
  <v-overlay opacity="0.8" :value="isLoading" z-index="333" class="wh-100">
    <div style="width: 60%" class="mx-auto my-auto">
      <v-progress-circular
        indeterminate
        size="60"
        width="5"
        color="primary"
      ></v-progress-circular>
      <div v-if="videoCompressPercentage.show" class="mt-5">
        <v-progress-linear
          :value="videoCompressPercentage.progress"
          color="primary"
          height="25"
          class="rounded-sm"
        >
          <template v-slot:default="{ value }">
            <strong>{{$t('label.compressingVideo')}} {{ value }}%</strong>
          </template>
        </v-progress-linear>
      </div>
      <div class="loader--text" v-if="isVideoLoading && !videoCompressPercentage.show"></div>
    </div>
    
  </v-overlay>
</template>

<script>
  export default {
    name: 'AppLoadingLinear',
    computed: {
      isLoading() {
        return this.$store.state.shared.isLoading;
      },
      isVideoLoading() {
        return this.$store.state.shared.isVideoLoading;
      },
      videoCompressPercentage() {
        return this.$store.state.shared.videoCompressPercentage;
      },
    }
  };
</script>

<style lang="scss">
  .v-overlay__content {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
  }
  .loader--text {
    position: relative;
    top: 200%;
    /* left: 50%; */
    /* transform: translateX(-50%); */
    width: -moz-max-content;
    width: max-content;
    margin: auto;
    margin-top: 1rem;

    &::after {
      content: 'Video is uploading, please wait';
      font-weight: bold;
      animation-name: loading-text;
      animation-duration: 3s;
      animation-iteration-count: infinite;
    }
  }
  @keyframes loading-text {
    0% {
      content: 'Video is uploading, please wait';
    }
    25% {
      content: 'Video is uploading, please wait.';
    }
    50% {
      content: 'Video is uploading, please wait..';
    }
    75% {
      content: 'Video is uploading, please wait...';
    }
  }
</style>
