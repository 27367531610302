<template>
  <div class="wh-100">
    <Header></Header>
    <v-main>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>
    <Footer></Footer>
  </div>
</template>

<script>
  import Header from '@/components/layout/Header';
  import Footer from '@/components/layout/Footer';
  import {
    CONSTANTS_GET_CONSTANTS,
    CONSTANTS_INITIAL_CONSTANTS_STATE
  } from '@/store/constants.module';
  import { AUTH_ME, AUTH_INITIAL_ME_STATE } from '@/store/auth.module';
  import { AUTH_IS_AUTHENTICATED } from '@/store/auth.module';
  import { mapGetters } from 'vuex';

  export default {
    name: 'Layout',
    components: {
      Header,
      Footer
    },
    computed: {
      ...mapGetters({
        isAuthenticated: [AUTH_IS_AUTHENTICATED]
      }),
      constantsComplete() {
        return this.$store.state.constants.constants.complete;
      }
    },
    watch: {
      isAuthenticated(val) {
        if (!val) {
          this.$store.dispatch(AUTH_INITIAL_ME_STATE);
        }
      },
      constantsComplete() {
        let response = this.$store.state.constants.constants;

        if (response.complete) {
          this.constantsCompleteAction(response);
        }
      }
    },
    async created() {
      await this.initLayout();
    },
    methods: {
      constantsCompleteAction(response) {
        if (response.code != 200) {
          console.log(response);
        } else {
          this.initialGetConstantsState();
        }
      },
      async getConstants() {
        await this.$store.dispatch(CONSTANTS_GET_CONSTANTS);
      },
      initialGetConstantsState() {
        this.$store.dispatch(CONSTANTS_INITIAL_CONSTANTS_STATE);
      },
      async getMe() {
        await this.$store.dispatch(AUTH_ME);
      },
      async initLayout() {
        await this.getConstants();
        if (!this.isAuthenticated) {
          await this.$store.dispatch(AUTH_INITIAL_ME_STATE);
        } else {
          await this.getMe();
        }
      }
    }
  };
</script>

<style></style>
