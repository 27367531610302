import {
  apiService,
  mapObjectToFormData,
  getQueryParamString
} from '@/services';
import { LOCAL } from '@/constants';
import { uiHelper } from '@/utils';

export const PROFILE = {
  updateLanguage,
  changePassword,
  updateDisplayInformation,
  submitVerification,
  createAgencyLinkRequest,
  createTalentLinkRequest,
  getLinkedAgency,
  getAgencyPendingRequest,
  pushNotificationSetting,
  permanentDeleteAccount,
  getUserRating,
  updateUserInfo,
  updateBusinessInfo,
  updateSocialMedia,
  getUserFollower,
  getUserFollowing,
  followUser,
  unfollowUser,
  getTalents,
  getPendingTalents,
  approveTalentRequest,
  rejectTalentRequest,
  removeTalent,
  getBlockedUsers,
  blockUser,
  unblockUser,
  getAdminList,
  resendInvitation,
  removeAdmin,
  createAdmin,
  transferOwnership,
  updateBioInfo,
  updateInterest,
  updateBioIntroInfo,
  updateGuardianInfo,
  getUserProfileById,
  updateCompanyProfile,
  createProfile,
  uploadFile,
  removeFile,
  sortFile,
  getAgency,
  approveAgencyLinkRequest,
  rejectAgencyLinkRequest,
  getUnlinkedTalents
};
const URL = {
  API: 'api',
  V1: 'v1',
  PROFILE: 'profile',
  LANGUAGE: 'language',
  CHANGE_PASSWORD: 'change-password',
  DISPLAY_INFO: 'display-info',
  VERIFICATION: 'verification',
  LINK_AGENCY: 'link-agency',
  LINK_TALENT: 'link-talent',
  PUSH: 'push',
  PERMANENT: 'permanent',
  USER: 'user',
  RATING: 'rating',
  USER_INFO: 'user-info',
  BUSINESS_INFO: 'business-info',
  SOCIAL_MEDIA: 'social-media',
  FOLLOW: 'follow',
  FOLLOWER: 'follower',
  UNFOLLOW: 'unfollow',
  FOLLOWING: 'following',
  AGENCY: 'agency',
  TALENTS: 'talents',
  PENDING: 'pending',
  APPROVE: 'approve',
  REJECT: 'reject',
  REMOVE: 'remove',
  BLOCKEDLIST: 'blockedlist',
  BLOCK: 'block',
  UNBLOCK: 'unblock',
  LIST: 'list',
  ADMIN: 'admin',
  INVITATION: 'invitation',
  RESEND: 'resend',
  CREATE: 'create',
  OWNERSHIP: 'ownership',
  BIO_INFO: 'bio-info',
  INTEREST: 'interest',
  BIO_INTRO_INFO: 'bio-intro-info',
  COMPANY: 'company',
  ASSET: 'asset',
  SORT: 'sort',
  AGENCIES: 'agencies',
  TALENT: 'talent',
  UNLINK: 'unlink',
  GUARDIAN_INFO: 'guardian-info'
};

function updateLanguage(locale) {
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.PROFILE}/${URL.LANGUAGE}?locale=${locale}`
  );
}

function uploadFile(id, data, typeId) {
  return apiService(null, true, true).post(
    `/${URL.API}/${URL.V1}/${URL.ASSET}/${id}`,
    mapObjectToFormData({
      media_collection: JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS))
        .media_collections[typeId],
      ...data
    })
  );
}
function removeFile(id) {
  return apiService().delete(`/${URL.API}/${URL.V1}/${URL.ASSET}/${id}`);
}
function sortFile(profileId, data, id) {
  let requestBody = {
    ...data,
    media_collection: JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS))
      .media_collections[id]
  };
  return apiService().put(
    `/${URL.API}/${URL.V1}/${URL.ASSET}/${profileId}/${URL.SORT}`,
    requestBody
  );
}

function changePassword(data) {
  let requestBody = {
    old_password: data.oldPassword,
    password: data.password,
    password_confirmation: data.passwordConfirmation
  };
  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.PROFILE}/${URL.CHANGE_PASSWORD}`,
    requestBody
  );
}

function updateDisplayInformation(data) {
  let requestBody = {
    display_information_ids: data
  };
  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.PROFILE}/${URL.DISPLAY_INFO}`,
    requestBody
  );
}

function submitVerification(data) {
  // let requestBody = {
  //   'gesture_id': data.gestureId,
  //   'company_code': data.companyCode,
  //   'verification_file': data.verificationFile
  // }
  return apiService(null, true, true).post(
    `/${URL.API}/${URL.V1}/${URL.PROFILE}/${URL.VERIFICATION}`,
    mapObjectToFormData({ ...data })
  );
}
function createAgencyLinkRequest(data) {
  let requestBody = {
    company_code: data.companyCode
  };
  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.PROFILE}/${URL.LINK_AGENCY}`,
    requestBody
  );
}
function createTalentLinkRequest(data) {
  let requestBody = {
    talent_uid: data
  };
  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.PROFILE}/${URL.LINK_TALENT}`,
    requestBody
  );
}

function getLinkedAgency() {
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.PROFILE}/${URL.LINK_AGENCY}`
  );
}

function getAgencyPendingRequest() {
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.TALENT}/${URL.AGENCIES}/${URL.PENDING}`
  );
}

function pushNotificationSetting(data) {
  return apiService().put(
    `/${URL.API}/${URL.V1}/${URL.PROFILE}/${URL.PUSH}?on=${data}`
  );
}

function permanentDeleteAccount() {
  return apiService().delete(
    `/${URL.API}/${URL.V1}/${URL.PROFILE}/${URL.PERMANENT}`
  );
}

function getUserRating(id) {
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.USER}/${id}/${URL.RATING}`
  );
}

function updateUserInfo(data) {
  let requestBody = {
    first_name: data.firstName || data.first_name,
    last_name: data.lastName || data.last_name
  };
  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.PROFILE}/${URL.USER_INFO}`,
    requestBody
  );
}

function updateCompanyProfile(data) {
  let requestBody = {
    company_write_up: data.company_write_up,
    list_projects: data.list_projects
  };
  return apiService(null, true, true).post(
    `/${URL.API}/${URL.V1}/${URL.PROFILE}/${URL.COMPANY}`,
    mapObjectToFormData(requestBody)
  );
}

function updateBusinessInfo(data) {
  let requestBody = {
    name: data.name,
    designation: data.designation,
    description: data.description,
    description_other: data.descriptionOther || data.description_other,
    talents_no: data.talentsNo || data.talents_no,
    email: data.email,
    country_id: data.countryId || data.country_id
  };
  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.PROFILE}/${URL.BUSINESS_INFO}`,
    requestBody
  );
}

function updateSocialMedia(data) {
  let requestBody = {
    website_link: data.website_link,
    facebook: data.facebook,
    instagram: data.instagram,
    twitter: data.twitter,
    linkedin: data.linkedin,
    youtube: data.youtube,
    tiktok: data.tiktok,
    twitch: data.twitch
  };

  return apiService(null, true, true).post(
    `/${URL.API}/${URL.V1}/${URL.PROFILE}/${URL.SOCIAL_MEDIA}`,
    mapObjectToFormData(requestBody)
  );
}
function followUser(id) {
  return apiService().put(
    `/${URL.API}/${URL.V1}/${URL.USER}/${id}/${URL.FOLLOW}`
  );
}
function unfollowUser(id) {
  return apiService().put(
    `/${URL.API}/${URL.V1}/${URL.USER}/${id}/${URL.UNFOLLOW}`
  );
}

function getUserFollower(id, data = null) {
  const requestOptions = {
    params: {
      per_page: data?.perPage
    }
  };
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.USER}/${id}/${URL.FOLLOWER}`,
    requestOptions
  );
}

function getUserFollowing(id, data = null) {
  const requestOptions = {
    params: {
      per_page: data?.perPage
    }
  };
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.USER}/${id}/${URL.FOLLOWING}`,
    requestOptions
  );
}

function getTalents(query) {
  const queryParam = getQueryParamString({
    ...query
  });
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.AGENCY}/${URL.TALENTS}?` + queryParam
  );
}

function getPendingTalents(query) {
  const queryParam = getQueryParamString({
    ...query
  });
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.AGENCY}/${URL.TALENTS}/${URL.PENDING}?` +
      queryParam
  );
}

function approveTalentRequest(data) {
  const requestBody = {
    request_id: data.requestId
  };
  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.AGENCY}/${URL.TALENTS}/${URL.APPROVE}`,
    requestBody
  );
}

function rejectTalentRequest(data) {
  const requestBody = {
    request_id: data.requestId
  };
  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.AGENCY}/${URL.TALENTS}/${URL.REJECT}`,
    requestBody
  );
}

function removeTalent(data) {
  const requestBody = {
    request_id: data.requestId
  };
  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.AGENCY}/${URL.TALENTS}/${URL.REMOVE}`,
    requestBody
  );
}

function getBlockedUsers() {
  return apiService().get(`/${URL.API}/${URL.V1}/${URL.BLOCKEDLIST}`);
}

function blockUser(id) {
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.BLOCKEDLIST}/:${id}/${URL.BLOCK}`
  );
}

function unblockUser(id) {
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.BLOCKEDLIST}/:${id}/${URL.UNBLOCK}`
  );
}

function getAdminList() {
  return apiService().get(`/${URL.API}/${URL.V1}/${URL.ADMIN}/${URL.LIST}`);
}

function resendInvitation(data) {
  let requestBody = {
    user_id: data
  };
  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.ADMIN}/${URL.INVITATION}/${URL.RESEND}`,
    requestBody
  );
}

function removeAdmin(data) {
  let requestBody = {
    user_id: data
  };
  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.ADMIN}/${URL.REMOVE}`,
    requestBody
  );
}

function createAdmin(data) {
  let requestBody = {
    name: data.name,
    first_name: data.firstName,
    last_name: data.lastName,
    designation: data.designation,
    mobile_dial_code: data.mobileDialCode,
    mobile: data.mobile,
    email: data.email
  };
  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.ADMIN}/${URL.CREATE}`,
    requestBody
  );
}

function transferOwnership(id) {
  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.ADMIN}/${URL.OWNERSHIP}/${id}`
  );
}

function updateBioInfo(data) {
  let requestBody = {
    gender: data.gender,
    gender_other: data.genderOther || data.gender_other,
    pronouns: data.pronouns,
    pronouns_other: data.pronounsOther || data.pronouns_other,
    country_id: data.countryId || data.country_id,
    email: data.email,
    display_name: data.displayName || data.display_name,
    rates: data.rates,
    ethnicity: data.ethnicity,
    race: data.race,
    languages_spoken: data.languagesSpoken || data.languages_spoken || [],
    height: data.height,
    weight: data.weight,
    hair_color: data.hairColor || data.hair_color,
    hair_length: data.hairLength || data.hair_length,
    hair_type: data.hairType || data.hair_type,
    eye_color: data.eyeColor || data.eye_color,
    skin_color: data.skinColor || data.skin_color,
    dob: data.dob
  };
  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.PROFILE}/${URL.BIO_INFO}`,
    requestBody
  );
}

function updateInterest(data) {
  let requestBody = {
    project_role_type_ids: data.project_role_type_ids,
    project_type_ids: [],
    project_role_ids: []
  };
  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.PROFILE}/${URL.INTEREST}`,
    requestBody
  );
}

function updateBioIntroInfo(data, isFreelancer) {
  let requestBody = {
    intro_summary: data.intro_summary,
    bio: data.bio,
    'bio_links[]': data.bio_links.tags
  };

  if (!isFreelancer) {
    requestBody = {
      ...requestBody,
      skills: data.skills,
      'skill_ids[]': data.skill_ids,
      secondary_skills: data.secondary_skills,
      'secondary_skill_ids[]': data.secondary_skill_ids,
      age_to_play: data.age_to_play,
      tv_online_rate: data.tv_online_rate,
      feature_film_rate: data.feature_film_rate,
      commercial_rate: data.commercial_rate,
      variety_rate: data.variety_rate,
      student_project_rate: data.student_project_rate,
      public_service_announcement_rate: data.public_service_announcement_rate,
      corporate_rate: data.corporate_rate,
      voiceover_rate: data.voiceover_rate
    };
  } else {
    requestBody = {
      ...requestBody,
      list_projects: data.list_projects
    };
  }
  return apiService(null, true, true).post(
    `/${URL.API}/${URL.V1}/${URL.PROFILE}/${URL.BIO_INTRO_INFO}`,
    mapObjectToFormData(requestBody)
  );
}

function updateGuardianInfo(data) {
  let requestBody = {
    guardian_relationship: data.guardian_relationship,
    guardian_relationship_other: data.guardian_relationship_other,
    guardian_first_name: data.guardian_first_name,
    guardian_last_name: data.guardian_last_name,
    guardian_gender: data.guardian_gender,
    guardian_gender_other: data.guardian_gender_other,
    guardian_pronouns: data.guardian_pronouns,
    guardian_pronouns_other: data.guardian_pronouns_other,
    guardian_mobile_dial_code: data.guardian_mobile_dial_code,
    guardian_mobile: data.guardian_mobile,
    guardian_email: data.guardian_email
  };

  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.PROFILE}/${URL.GUARDIAN_INFO}`,
    requestBody
  );
}

function getUserProfileById(id) {
  return apiService().get(`/${URL.API}/${URL.V1}/${URL.USER}/${id}`);
}

function createProfile(data, role) {
  let requestBody = {
    first_name: data.firstName || data.first_name,
    last_name: data.lastName || data.last_name,
    website_link: data.website_link,
    facebook: data.facebook,
    instagram: data.instagram,
    twitter: data.twitter,
    linkedin: data.linkedin,
    youtube: data.youtube,
    tiktok: data.tiktok,
    twitch: data.twitch
  };

  if (['agency', 'production_house'].includes(role)) {
    requestBody = {
      ...requestBody,
      designation: data.designation,
      description: data.description,
      description_other: data.descriptionOther || data.description_other,
      talents_no: data.talentsNo || data.talents_no,
      email: data.email,
      country_id: data.countryId || data.country_id,
      company_write_up: data.company_write_up,
      list_projects: data.list_projectstags
    };
  }
  if (['freelancer', 'talent'].includes(role)) {
    requestBody = {
      ...requestBody,
      intro_summary: data?.intro_summary,
      bio: data?.bio,
      bio_links: data?.bio_links?.tags,
      gender: data?.gender,
      gender_other: data?.genderOther || data?.gender_other,
      pronouns: data?.pronouns,
      pronouns_other: data?.pronounsOther || data?.pronouns_other,
      country_id: data?.countryId || data?.country_id,
      email: data?.email,
      display_name: data?.displayName || data?.display_name,
      rates: data?.rates,
      ethnicity: data?.ethnicity,
      race: data?.race,
      languages_spoken: data?.languagesSpoken || data?.languages_spoken || [],
      height: data?.height,
      weight: data?.weight,
      hair_color: data?.hairColor || data?.hair_color,
      hair_length: data?.hairLength || data?.hair_length,
      hair_type: data?.hairType || data?.hair_type,
      eye_color: data?.eyeColor || data?.eye_color,
      skin_color: data?.skinColor || data?.skin_color,
      dob: data?.dob
    };
    if (role == 'freelancer') {
      requestBody = {
        ...requestBody,
        list_projects: data.list_projects
      };
    } else {
      requestBody = {
        ...requestBody,
        project_type_ids: [],
        project_role_ids: [],
        project_role_type_ids:
          data.projectRoleTypeIds || data.project_role_type_ids,
        skills: data.skills,
        skill_ids: data.skill_ids,
        secondary_skills: data.secondary_skills,
        secondary_skill_ids: data.secondary_skill_ids,
        age_to_play: data.age_to_play,
        tv_online_rate: data.tv_online_rate,
        feature_film_rate: data.feature_film_rate,
        commercial_rate: data.commercial_rate,
        variety_rate: data.variety_rate,
        student_project_rate: data.student_project_rate,
        public_service_announcement_rate: data.public_service_announcement_rate,
        corporate_rate: data.corporate_rate,
        voiceover_rate: data.voiceover_rate,
        guardian_relationship: data.guardian_relationship,
        guardian_relationship_other: data.guardian_relationship_other,
        guardian_first_name: data.guardian_first_name,
        guardian_last_name: data.guardian_last_name,
        guardian_gender: data.guardian_gender,
        guardian_gender_other: data.guardian_gender_other,
        guardian_pronouns: data.guardian_pronouns,
        guardian_pronouns_other: data.guardian_pronouns_other,
        guardian_mobile_dial_code: data.guardian_mobile_dial_code,
        guardian_mobile: data.guardian_mobile,
        guardian_email: data.guardian_email
      };
    }
  }

  return apiService().post(`/${URL.API}/${URL.V1}/${URL.PROFILE}`, requestBody);
}

function getAgency(query) {
  const queryParam = getQueryParamString({
    ...query
  });
  return apiService().get(`/${URL.API}/${URL.V1}/${URL.AGENCY}?` + queryParam);
}

function approveAgencyLinkRequest(ids) {
  let data = {
    request_id: ids
  };
  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.TALENT}/${URL.AGENCIES}/${URL.APPROVE}`,
    data
  );
}

function rejectAgencyLinkRequest(ids) {
  let data = {
    request_id: ids
  };
  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.TALENT}/${URL.AGENCIES}/${URL.REJECT}`,
    data
  );
}

function getUnlinkedTalents(query) {
  const queryParam = getQueryParamString({
    ...query
  });
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.AGENCY}/${URL.TALENTS}/${URL.UNLINK}?` +
      queryParam
  );
}
