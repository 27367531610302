<template>
  <div>
    <ModalBase :id="modalName" width="900px" @closeModal="close" :persistent="hasConfirmBtn">
      <div class="pt-5 text-center">
        <video
          controls
          v-if="videoSrc"
          style="width: inherit; max-height: 30vw;"
          crossorigin="anonymous"
        >
          <source
            :src="videoSrc"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <div class="text-center pt-5" v-if="hasConfirmBtn">
        <v-btn
          class="btn1"
          :disabled="!!progress"
          @click="confirm"
        >
          {{ progress ? `Compressing Video...(${progress})`: $t('label.confirm') }}
        </v-btn>
      </div>
    </ModalBase>
  </div>
</template>

<script>
  import ModalBase from '@/components/base/ModalBase';
  import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg/dist/ffmpeg.min.js';
  import { bus } from '@/main.js';
  import uploadMediaMixin from '@/mixin/upload-media.mixin';

  export default {
    name: 'PreviewVideoModal',
    props: {
      elemId: {
        type: String,
        default: ''
      }
    },
    mixins: [uploadMediaMixin],
    components: {
      ModalBase,
    },
    data() {
      return {
        videoFile: null,
        videoSrc: null,
        itemId: null,
        talentUid: null,
        hasConfirmBtn: false,
        progress: ''
      };
    },
    computed: {
      modalName() {
        return this.elemId ? `${this.elemId}` : 'previewVideoModal';
      }
    },
    methods: {
      async compressFile() {
        const ffmpeg = await createFFmpeg({
          log: true,
          progress: async (params) => {
            const percentage = params.ratio * 100;
            this.progress = percentage.toFixed(2) + '%';
          }
        });

        const transcode = async (file) => {
          const { name } = file;
          await ffmpeg.load();
          ffmpeg.FS('writeFile', name, await fetchFile(file));
          // await ffmpeg.run('-i', name, '-vcodec', 'libx264', '-crf 24', '-preset ultrafast', 'output9.mp4');
          await ffmpeg.run(
            '-i',
            name,
            // '-t', // reduce video time to speed up compress time
            // '5', // reduce video time
            '-ss',
            '2.0',
            '-f',
            'mp4',
            'output9.mp4'
          );
          const data = await ffmpeg.FS('readFile', 'output9.mp4');

          // // Do something with the processed video data, e.g., download it
          if (data) {
            const compressed = new File([data.buffer], name, {
              type: 'video/mp4'
            });
            return compressed;
          }
        };

        // transcode(e);
        let result = await transcode(this.videoFile);
        this.$videoLoading();
        if (typeof result.name == 'string') {
          result = { file: result, complete: true };
          return result;
        } else {
          result = { file: null, complete: false };
          return result;
        }
      },
      async confirm() {
        if(this.videoFile.size > 524288000) {
          this.openAppDialogInfo(
            'error',
            'File exceeds the upload file size',
            'Maximum upload file size: 500MB',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
          return;
        }

        const timestamp = new Date().getTime();
        this.$pushVideoToCompress({
          file: this.videoFile,
          id: `am-${this.itemId}_${timestamp}`,
          extra: {
            talentUid: this.talentUid,
            applicationId: this.itemId,
          }
        });
        this.close();
        // const compressed = await this.compressFile();
        // this.$emit('confirm', {
        //   compressed: compressed.file,
        //   itemId: this.itemId,
        //   talentUid: this.talentUid
        // }); 
        // this.clear();
      },
      clear() {
        this.videoFile = null;
        this.videoSrc = null;
        this.itemId = null;
        this.talentUid = null;
        this.hasConfirmBtn = false;
        this.progress = '';
      },
      close() {
        this.$emit('closeModal', this.itemId);
        this.clear();
      }
    },
    created() {
      bus.$on('openPreviewVideoModal', (data) => {
        if(data.customModalName == this.modalName || !data.customModalName) {
          this.videoFile = data.videoFile;
          this.videoSrc = data.videoSrc;
          this.itemId = data.itemId || null;
          this.talentUid = data.talentUid || null;
          this.hasConfirmBtn = data.hasConfirmBtn || false;
          
          this.$openModal(data.customModalName || this.modalName);
        }

      });
    }
  };
</script>

<style></style>
