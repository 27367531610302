import { INBOX } from '@/api';
import { apiHelper } from '@/utils';
import {
  SHARED_OPEN_LOADING,
  SHARED_CLOSE_LOADING
} from '@/store/shared.module';
import dateTimeFormat from '@/filters/dateTimeFormat.filter';

/* Define Module Name */
const MODULE_NAME = 'inbox/';

/* Define Action Name */
const A_GET_INBOXS = 'getInboxs';
const A_GET_INBOX_DETAILS = 'getInboxDetails';
const A_DELETE_INBOX = 'deleteInbox';

/* Define Reset State Action Name */
const A_INITIAL_GET_INBOXS_STATE = 'initialGetInboxsState';
const A_INITIAL_GET_INBOX_DETAILS_STATE = 'initialGetInboxDetailsState';
const A_INITIAL_DELETE_INBOX_STATE = 'initialDeleteInboxState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_INBOXS = 'setGetInboxs';
const M_GET_INBOX_DETAILS = 'setGetInboxDetails';
const M_DELETE_INBOX = 'setDeleteInbox';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_INBOXS_STATE = 'setInitialGetInboxsState';
const M_INITIAL_GET_INBOX_DETAILS_STATE = 'setInitialGetInboxDetailsState';
const M_INITIAL_DELETE_INBOX_STATE = 'setInitialDeleteInboxState';

/* Define Export Name */
export const INBOX_GET_INBOXS = MODULE_NAME + A_GET_INBOXS;
export const INBOX_GET_INBOX_DETAILS = MODULE_NAME + A_GET_INBOX_DETAILS;
export const INBOX_DELETE_INBOX = MODULE_NAME + A_DELETE_INBOX;

/* Define Reset State Name */
export const INBOX_INITIAL_GET_INBOXS_STATE =
  MODULE_NAME + A_INITIAL_GET_INBOXS_STATE;
export const INBOX_INITIAL_GET_INBOX_DETAILS_STATE =
  MODULE_NAME + A_INITIAL_GET_INBOX_DETAILS_STATE;
export const INBOX_INITIAL_DELETE_INBOX_STATE =
  MODULE_NAME + A_INITIAL_DELETE_INBOX_STATE;

const state = {
  inboxsList: {
    code: 0,
    complete: false,
    data: {},
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  inboxDetails: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },
  deleteInbox: {
    code: 0,
    complete: false,
    data: {},
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_INBOXS]({ dispatch, commit }, { data }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    INBOX.getInboxs(data)
      .then((response) => {
        result = response;
        commit(M_GET_INBOXS, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_INBOXS, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_GET_INBOX_DETAILS]({ dispatch, commit }, { id }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await INBOX.getInboxDetails(id)
      .then((response) => {
        result = response;
        commit(M_GET_INBOX_DETAILS, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_INBOX_DETAILS, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_DELETE_INBOX]({ dispatch, commit }, { id }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    INBOX.deleteInbox(id)
      .then((response) => {
        result = response;
        commit(M_DELETE_INBOX, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_DELETE_INBOX, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_INBOXS_STATE]({ commit }) {
    commit(M_INITIAL_GET_INBOXS_STATE);
  },
  [A_INITIAL_GET_INBOX_DETAILS_STATE]({ commit }) {
    commit(M_INITIAL_GET_INBOX_DETAILS_STATE);
  },
  [A_INITIAL_DELETE_INBOX_STATE]({ commit }) {
    commit(M_INITIAL_DELETE_INBOX_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_INBOXS](state, result) {
    if (result.code === 200) {
      let d = result.data.items;
      let p = result.data.pagination;

      let inboxs = [];

      d.forEach((x, index) => {
        let j = x.casting_job;
        let castingJob = null;
        if (x.casting_job) {
          castingJob = {
            id: j.id,
            title: j.title,
            castingRoles: j.casting_roles || j.casting_role || null,
            projectType: j.project_type,
            jobCoverFile: j.job_cover_file
          };
        }

        inboxs.push({
          id: x.id,
          no: index * 10,
          title: x.title,
          description: x.description,
          castingJob: castingJob,
          isRead: x.is_read,
          notificationType: x.notification_type,
          modelId: x?.model_id || null,
          castingInvitation: x.casting_invitation || null,
          newsfeed: x.newsfeed
            ? {
                ...x.newsfeed,
                media: x.newsfeed.media ? x.newsfeed.media[0] : null
              }
            : null,
          createdDatetime: dateTimeFormat(x.created_datetime),
          chatGroupId: x?.chat_group_id || null,
          castingApplicationId: x?.casting_application_id || null
        });
      });
      state.inboxsList = {
        code: result.code,
        complete: true,
        data: inboxs,
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.inboxsList = {
        code: result.code,
        complete: true,
        data: {},
        message: apiHelper.sortOutMessage(result.error_message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_GET_INBOX_DETAILS](state, result) {
    if (result.code === 200) {
      // let d = result.data?.casting_job;
      let data = {
        id: result.data.id
        // jobId: d?.id || null,
        // title: d?.title || null,
        // castingRole: d?.casting_role || null,
        // projectType: d?.project_type || null,
        // jobCoverFile: d?.job_cover_file || null
      };
      state.inboxDetails = {
        code: result.code,
        complete: true,
        data: data,
        message: null
      };
    } else {
      state.inboxDetails = {
        code: result.code,
        complete: true,
        data: {},
        message: apiHelper.sortOutMessage(result.error_message)
      };
    }
  },
  [M_DELETE_INBOX](state, result) {
    state.deleteInbox = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_INITIAL_GET_INBOXS_STATE](state) {
    let data = state.inboxsList.data;

    state.inboxsList = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_INBOX_DETAILS_STATE](state) {
    state.inboxDetails = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_DELETE_INBOX_STATE](state) {
    let data = state.deleteInbox.data;

    state.deleteInbox = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
