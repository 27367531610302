<template>
  <v-app>
    <component :is="layout">
      <router-view />
    </component>
    <AppDialogInfo></AppDialogInfo>
    <AppLoading></AppLoading>
    <AppChat></AppChat>
    <MessageModal />
    <ReportModal />
    <AppProgressSnackbar />
  </v-app>
</template>

<script>
  import Layout from '@/components/layout/Layout';
  import BlankLayout from '@/components/layout/BlankLayout';
  import ProfileLayout from '@/components/layout/ProfileLayout';
  import ProfileLayoutWithoutSideBar from '@/components/layout/ProfileLayoutWithoutSideBar';
  import LayoutWithFooter from '@/components/layout/LayoutWithFooter';
  import AppDialogInfo from '@/components/base/AppDialogInfo';
  import AppLoading from '@/components/base/AppLoading';
  import AppChat from '@/components/base/AppChat';
  import ReportModal from '@/components/base/ReportModal.vue';
  import MessageModal from '@/components/base/MessageModal';
  import { SHARED } from '@/constants';
  import { i18nHelper, jwtHelper } from '@/utils';
  import {
    PLATFORM_INFO_GET_PLATFORM_INFO,
    PLATFORM_INFO_INITIAL_GET_PLATFORM_INFO_STATE
  } from '@/store/platform-info.module';
  import { AUTH_INITIAL_LOGIN_STATE } from '@/store/auth.module';
  import { SHARED_UPDATE_SCREEN_SIZE } from '@/store/shared.module';

  export default {
    name: 'App',
    metaInfo: {
      title: i18nHelper.getMessage(`meta.title.default`),
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: i18nHelper.getMessage(`meta.description.default`)
        }
      ]
    },
    components: {
      Layout,
      BlankLayout,
      ProfileLayout,
      ProfileLayoutWithoutSideBar,
      AppDialogInfo,
      AppLoading,
      AppChat,
      ReportModal,
      MessageModal,
      LayoutWithFooter
    },
    computed: {
      layout() {
        return this.$route.meta.layout || SHARED.DEFAULT_LAYOUT;
      },
      platFormInfoComplete() {
        return this.$store.state.platformInfo.platformInfo.complete;
      }
    },
    watch: {
      '$route.path'() {
        this.$closeModal();
      },
      platFormInfoComplete(value) {
        let response = this.$store.state.platformInfo.platformInfo;
        if (value) {
          this.platFormInfoCompleteAction(response);
        }
      }
    },
    created() {
      console.log('v1.3.4');

      this.initApp();
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize);
    },

    methods: {
      platFormInfoCompleteAction(response) {
        if (response.code != 200) {
          console.log(response);
        }
        this.initialGetPlatFormInfoState();
      },
      initialGetPlatFormInfoState() {
        this.$store.dispatch(PLATFORM_INFO_INITIAL_GET_PLATFORM_INFO_STATE);
      },
      initialAuthState() {
        this.$store.dispatch(AUTH_INITIAL_LOGIN_STATE);
      },
      getPlatFormInfo() {
        this.$store.dispatch(PLATFORM_INFO_GET_PLATFORM_INFO);
      },
      handleResize() {
        this.$store.dispatch(SHARED_UPDATE_SCREEN_SIZE, {
          width: window.innerWidth,
          height: window.innerHeight
        });
      },
      initApp() {
        if (jwtHelper.getToken()) {
          this.initialAuthState();
        }
        this.getPlatFormInfo();
      }
    }
  };
</script>
