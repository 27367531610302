import {
  apiService,
  getQueryParamString,
  mapObjectToFormData
} from '@/services';
// import { apiHelper } from '@/core/utils';

export const HOME_SCREENS = {
  getHomeScreens,
  getHomeScreenPendingInvitations,
  getHomeScreenPendingSubmissions,
  getHomeScreenJobs,
  getHomeScreenBanners,
  getOwnJobs,
  searchJobPost,
  searchUser,
  getNewsfeed,
  getMyNewsfeed,
  getNewsfeedById,
  createNewsfeed,
  updateNewsfeedById,
  deleteNewsfeed,
  createNewsfeedComment,
  getLikedNewsfeedUser,
  likeNewsfeed,
  cancelLikeNewsfeed,
  getUnreadNotifications,
  getUnreadMessages,
  getAdvertisements,
  flag
};

const URL = {
  API: 'api',
  V1: 'v1',
  JOBS: 'jobs',
  USERS: 'users',
  BANNERS: 'banner',
  OWN_JOBS: 'own-jobs',
  SEARCH: 'search',
  CASTING: 'casting',
  USER: 'user',
  NEWSFEED: 'newsfeed',
  MY: 'my',
  COMMENT: 'comment',
  LIKE: 'like',
  NOTIFICATIONS: 'notifications',
  UNREAD_COUNT: 'unread-count',
  CHAT: 'chat',
  ADVERTISEMENT: 'advertisement',
  FLAG: 'flag',
  HOME: 'home',
  PENDING_INVITATION_COUNT: 'pending_invitation_count',
  PENDING_SUBMISSION_COUNT: 'pending_submission_count'
};

function getHomeScreens() {
  return apiService().get(`/${URL.API}/${URL.V1}`);
}
function getHomeScreenPendingInvitations() {
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.HOME}/${URL.PENDING_INVITATION_COUNT}`
  );
}
function getHomeScreenPendingSubmissions() {
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.HOME}/${URL.PENDING_SUBMISSION_COUNT}`
  );
}

function getHomeScreenJobs(data) {
  let requestOptions = {
    params: {
      per_page: data.perPage,
      location: data.location ? data.location : [],
      project_type: data.project_type ? data.project_type : []
    }
  };
  return apiService().get(`/${URL.API}/${URL.V1}/${URL.JOBS}`, requestOptions);
}

function getHomeScreenBanners() {
  return apiService().get(`/${URL.API}/${URL.V1}/${URL.BANNERS}`);
}

function getOwnJobs(data) {
  let requestOptions = {
    params: {
      per_page: data.perPage
    }
  };
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.OWN_JOBS}`,
    requestOptions
  );
}

function searchJobPost(query) {
  let requestOptions = {
    params: query
  };
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.SEARCH}/${URL.CASTING}`,
    requestOptions
  );
}
function searchUser(query) {
  const queryParam = getQueryParamString({
    ...query
  });
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.SEARCH}/${URL.USER}?` + queryParam
  );
}
function getNewsfeed(data) {
  let requestOptions = {
    params: {
      per_page: data.perPage
    }
  };
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.NEWSFEED}`,
    requestOptions
  );
}
function getMyNewsfeed(data) {
  let requestOptions = {
    params: {
      per_page: data.perPage
    }
  };
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.NEWSFEED}/${URL.MY}`,
    requestOptions
  );
}
function getNewsfeedById(id) {
  return apiService().get(`/${URL.API}/${URL.V1}/${URL.NEWSFEED}/${id}`);
}
function createNewsfeed(data) {
  return apiService().post(`/${URL.API}/${URL.V1}/${URL.NEWSFEED}`, data);
}
function updateNewsfeedById(id, data) {
  return apiService().put(`/${URL.API}/${URL.V1}/${URL.NEWSFEED}/${id}`, data);
}
function deleteNewsfeed(id) {
  return apiService().delete(`/${URL.API}/${URL.V1}/${URL.NEWSFEED}/${id}`);
}
function createNewsfeedComment(id, data) {
  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.NEWSFEED}/${id}/${URL.COMMENT}`,
    data
  );
}
function getLikedNewsfeedUser(id) {
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.NEWSFEED}/${id}/${URL.LIKE}`
  );
}
function likeNewsfeed(id) {
  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.NEWSFEED}/${id}/${URL.LIKE}`
  );
}
function cancelLikeNewsfeed(id) {
  return apiService().delete(
    `/${URL.API}/${URL.V1}/${URL.NEWSFEED}/${id}/${URL.LIKE}`
  );
}
function getAdvertisements(query) {
  const queryParam = getQueryParamString({
    ...query
  });
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.ADVERTISEMENT}?` + queryParam
  );
}
function getUnreadNotifications() {
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.NOTIFICATIONS}/${URL.UNREAD_COUNT}`
  );
}
function getUnreadMessages() {
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.CHAT}/${URL.UNREAD_COUNT}`
  );
}
function flag(data, type) {
  let requestOptions = {
    type: data.type,
    type_other: data.typeOther,
    comment: data.comment,
    'proof[]': data.proof,
    id: data.id
  };
  return apiService(null, true, true).post(
    `/${URL.API}/${URL.V1}/${URL.FLAG}/${type}`,
    mapObjectToFormData(requestOptions)
  );
}
