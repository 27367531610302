import { PAYMENT } from '@/api';
import { apiHelper } from '@/utils';
import {
  SHARED_OPEN_LOADING,
  SHARED_CLOSE_LOADING
} from '@/store/shared.module';
// import dateTimeFormat from '@/filters/dateTimeFormat.filter';

/* Define Module Name */
const MODULE_NAME = 'payment/';

/* Define Action Name */
const A_GET_PAYMENT_HISTORY = 'getPaymentHistory';
const A_GET_PAYMENT_SUBSCRIPTIONS = 'getPaymentSubscriptions';
const A_GET_MY_SUBSCRIPTIONS = 'getMySubscription';
const A_SET_PAY_UPLOAD_CREDIT = 'setPayUploadCradit';
const A_SET_PAY_SUBSCRIPTION = 'setPaySubscription';
const A_PURCHASE_ADMIN_ACCOUNT = 'purchaseAdminAccount';
const A_CHECK_PAYMENT_SUBSCRIPTION = 'checkPaymentSubscription';

/* Define Reset State Action Name */
const A_INITIAL_GET_PAYMENT_HISTORY_STATE = 'initialGetPaymentHistoryState';
const A_INITIAL_GET_PAYMENT_SUBSCRIPTIONS_STATE =
  'initialGetPaymentSubscriptionsState';
const A_INITIAL_GET_MY_SUBSCRIPTIONS_STATE = 'initialGetMySubscriptionState';
const A_INITIAL_SET_PAY_UPLOAD_CREDIT_STATE = 'initialSetPayUploadCraditState';
const A_INITIAL_SET_PAY_SUBSCRIPTION_STATE = 'initialSetPaySubscriptionState';
const A_INITIAL_PURCHASE_ADMIN_ACCOUNT_STATE =
  'initialPurchaseAdminAccountState';
const A_INITIAL_CHECK_PAYMENT_SUBSCRIPTION_STATE =
  'initialCheckPaymentSubscriptionState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_PAYMENT_HISTORY = 'setGetPaymentHistory';
const M_GET_PAYMENT_SUBSCRIPTIONS = 'setGetPaymentSubscriptions';
const M_GET_MY_SUBSCRIPTIONS = 'setGetMySubscription';
const M_SET_PAY_UPLOAD_CREDIT = 'setSetPayUploadCradit';
const M_SET_PAY_SUBSCRIPTION = 'setSetPaySubscription';
const M_PURCHASE_ADMIN_ACCOUNT = 'setPurchaseAdminAccount';
const M_CHECK_PAYMENT_SUBSCRIPTION = 'setCheckPaymentSubscription';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_PAYMENT_HISTORY_STATE = 'setInitialGetPaymentHistoryState';
const M_INITIAL_GET_PAYMENT_SUBSCRIPTIONS_STATE =
  'setInitialGetPaymentSubscriptionsState';
const M_INITIAL_GET_MY_SUBSCRIPTIONS_STATE = 'setInitialGetMySubscriptionState';
const M_INITIAL_SET_PAY_UPLOAD_CREDIT_STATE =
  'setInitialSetPayUploadCraditState';
const M_INITIAL_SET_PAY_SUBSCRIPTION_STATE =
  'setInitialSetPaySubscriptionState';
const M_INITIAL_PURCHASE_ADMIN_ACCOUNT_STATE =
  'setInitialPurchaseAdminAccountState';
const M_INITIAL_CHECK_PAYMENT_SUBSCRIPTION_STATE =
  'setInitialCheckPaymentSubscriptionState';

/* Define Export Name */
export const PAYMENT_GET_PAYMENT_HISTORY = MODULE_NAME + A_GET_PAYMENT_HISTORY;
export const PAYMENT_GET_PAYMENT_SUBSCRIPTIONS =
  MODULE_NAME + A_GET_PAYMENT_SUBSCRIPTIONS;
export const PAYMENT_GET_MY_SUBSCRIPTIONS =
  MODULE_NAME + A_GET_MY_SUBSCRIPTIONS;
export const PAYMENT_SET_PAY_UPLOAD_CREDIT =
  MODULE_NAME + A_SET_PAY_UPLOAD_CREDIT;
export const PAYMENT_SET_PAY_SUBSCRIPTION =
  MODULE_NAME + A_SET_PAY_SUBSCRIPTION;
export const PAYMENT_PURCHASE_ADMIN_ACCOUNT =
  MODULE_NAME + A_PURCHASE_ADMIN_ACCOUNT;
export const PAYMENT_CHECK_PAYMENT_SUBSCRIPTION =
  MODULE_NAME + A_CHECK_PAYMENT_SUBSCRIPTION;

/* Define Reset State Name */
export const PAYMENT_INITIAL_GET_PAYMENT_HISTORY_STATE =
  MODULE_NAME + A_INITIAL_GET_PAYMENT_HISTORY_STATE;
export const PAYMENT_INITIAL_GET_PAYMENT_SUBSCRIPTIONS_STATE =
  MODULE_NAME + A_INITIAL_GET_PAYMENT_SUBSCRIPTIONS_STATE;
export const PAYMENT_INITIAL_GET_MY_SUBSCRIPTIONS_STATE =
  MODULE_NAME + A_INITIAL_GET_MY_SUBSCRIPTIONS_STATE;
export const PAYMENT_INITIAL_SET_PAY_UPLOAD_CREDIT_STATE =
  MODULE_NAME + A_INITIAL_SET_PAY_UPLOAD_CREDIT_STATE;
export const PAYMENT_INITIAL_SET_PAY_SUBSCRIPTION_STATE =
  MODULE_NAME + A_INITIAL_SET_PAY_SUBSCRIPTION_STATE;
export const PAYMENT_INITIAL_PURCHASE_ADMIN_ACCOUNT_STATE =
  MODULE_NAME + A_INITIAL_PURCHASE_ADMIN_ACCOUNT_STATE;
export const PAYMENT_INITIAL_CHECK_PAYMENT_SUBSCRIPTION_STATE =
  MODULE_NAME + A_INITIAL_CHECK_PAYMENT_SUBSCRIPTION_STATE;

const state = {
  paymentHistory: {
    code: 0,
    complete: false,
    data: {},
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  paymentSubscriptions: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },

  mySubscriptions: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },

  payUploadCredit: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },

  paySubscriptions: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },

  purchaseAdminAccount: {
    code: 0,
    complete: false,
    data: {},
    message: null
  },

  checkPaymentSubscription: {
    code: 0,
    complete: false,
    data: {},
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_PAYMENT_HISTORY]({ dispatch, commit }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PAYMENT.getPaymentHistory()
      .then((response) => {
        result = response;
        commit(M_GET_PAYMENT_HISTORY, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_PAYMENT_HISTORY, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_PAYMENT_SUBSCRIPTIONS]({ dispatch, commit }, { data, userRole }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PAYMENT.getPaymentSubscriptions(data)
      .then((response) => {
        result = response;
        commit(M_GET_PAYMENT_SUBSCRIPTIONS, { ...result, userRole });
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_PAYMENT_SUBSCRIPTIONS, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_GET_MY_SUBSCRIPTIONS]({ dispatch, commit }, { role }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await PAYMENT.getMySubscription()
      .then((response) => {
        result = response;
        commit(M_GET_MY_SUBSCRIPTIONS, { ...result, role });
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_MY_SUBSCRIPTIONS, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_SET_PAY_UPLOAD_CREDIT]({ dispatch, commit }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PAYMENT.setPayUploadCredit()
      .then((response) => {
        result = response;
        commit(M_SET_PAY_UPLOAD_CREDIT, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_SET_PAY_UPLOAD_CREDIT, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_SET_PAY_SUBSCRIPTION]({ dispatch, commit }, { data }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await PAYMENT.setPaySubscription(data)
      .then((response) => {
        result = response;
        commit(M_SET_PAY_SUBSCRIPTION, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_SET_PAY_SUBSCRIPTION, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_PURCHASE_ADMIN_ACCOUNT]({ dispatch, commit }, { data }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await PAYMENT.purchaseAdminAccount(data)
      .then((response) => {
        result = response;
        commit(M_PURCHASE_ADMIN_ACCOUNT, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_PURCHASE_ADMIN_ACCOUNT, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_CHECK_PAYMENT_SUBSCRIPTION]({ dispatch, commit }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await PAYMENT.checkPaymentSubscription()
      .then((response) => {
        result = response;
        commit(M_CHECK_PAYMENT_SUBSCRIPTION, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_CHECK_PAYMENT_SUBSCRIPTION, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_PAYMENT_HISTORY_STATE]({ commit }) {
    commit(M_INITIAL_GET_PAYMENT_HISTORY_STATE);
  },
  [A_INITIAL_GET_PAYMENT_SUBSCRIPTIONS_STATE]({ commit }) {
    commit(M_INITIAL_GET_PAYMENT_SUBSCRIPTIONS_STATE);
  },
  [A_INITIAL_GET_MY_SUBSCRIPTIONS_STATE]({ commit }) {
    commit(M_INITIAL_GET_MY_SUBSCRIPTIONS_STATE);
  },
  [A_INITIAL_SET_PAY_UPLOAD_CREDIT_STATE]({ commit }) {
    commit(M_INITIAL_SET_PAY_UPLOAD_CREDIT_STATE);
  },
  [A_INITIAL_SET_PAY_SUBSCRIPTION_STATE]({ commit }) {
    commit(M_INITIAL_SET_PAY_SUBSCRIPTION_STATE);
  },
  [A_INITIAL_PURCHASE_ADMIN_ACCOUNT_STATE]({ commit }) {
    commit(M_INITIAL_PURCHASE_ADMIN_ACCOUNT_STATE);
  },
  [A_INITIAL_CHECK_PAYMENT_SUBSCRIPTION_STATE]({ commit }) {
    commit(M_INITIAL_CHECK_PAYMENT_SUBSCRIPTION_STATE);
  }
};
// Mutations
const mutations = {
  [M_GET_PAYMENT_HISTORY](state, result) {
    if (result.code === 200) {
      let d = result.data;

      state.paymentHistory = {
        code: result.code,
        complete: true,
        data: d,
        message: null
      };
    } else {
      state.paymentHistory = {
        code: result.code,
        complete: true,
        data: {},
        message: apiHelper.sortOutMessage(result.error_message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_GET_PAYMENT_SUBSCRIPTIONS](state, result) {
    if (result.code === 200) {
      let d = result.data;
      let subscription = [];
      if (result.userRole == 'talent') {
        d.forEach((x) => {
          let packageDetails = [];
          x.package_details.forEach((p) => {
            packageDetails.push({
              id: p.id,
              packageDuration: p.package_duration,
              packageDiscount: p.package_discount,
              packPrice: p.package_price
            });
          });

          subscription.push({
            id: x.id,
            name: x.name,
            level: x.level,
            castingPerMonth: x.casting_per_month,
            pricePerMonth: x.price_per_month,
            addonVideoPrice: x.addon_video_price,
            addonVideoCount: x.addon_video_count,
            selectPackage: x.level,
            packageDetails: packageDetails,
            packageDetailIds: packageDetails.map((x) => x.id)
          });
        });
      } else if (['agency', 'production_house', 'freelancer'].includes(result.userRole)) {
        d.forEach((x) => {
          let packageDetails = [];
          x.package_details.forEach((p) => {
            packageDetails.push({
              id: p.id,
              noOfFreeUsers: p.no_of_free_users,
              noOfUsers: p.no_of_users,
              pricePerAdmin: p.price_per_admin,
              pricePerAnnum: p.price_per_annum
            });
          });

          subscription.push({
            id: x.id,
            name: x.name,
            level: x.level,
            selectPackage: x.level,
            packageDetails: packageDetails,
            packageDetailIds: packageDetails.map((x) => x.id)
          });
        });
      }

      state.paymentSubscriptions = {
        code: result.code,
        complete: true,
        data: subscription,
        message: null
      };
    } else {
      state.paymentSubscriptions = {
        code: result.code,
        complete: true,
        data: {},
        message: apiHelper.sortOutMessage(result.error_message)
      };
    }
  },
  [M_GET_MY_SUBSCRIPTIONS](state, result) {
    if (result.code === 200) {
      let d = result.data;

      state.mySubscriptions = {
        code: result.code,
        complete: true,
        data: {
          id: d?.id || d?.user_package.id || null,
          expiredDate:
            (result.role == 'talent'
              ? d?.user_package.expired_date
              : d?.expired_date) || null,
          packageName:
            (result.role == 'talent'
              ? d?.user_package.package_name
              : d?.package_name) || null,
          startFrom:
            (result.role == 'talent' ? d?.user_package.start_from : d?.start_from) || null,
          uploadRemaining: d?.upload_remaining || null,
          creditRemaining: d?.credit_remaining || null,
          packageId:
            (result.role == 'talent'
              ? d?.user_package.talent_package_id
              : d?.production_package_id) || null,
          buyAdmin: (result.role == 'talent' ? null : d?.buy_admin) || null
        },
        message: null
      };
    } else {
      state.mySubscriptions = {
        code: result.code,
        complete: true,
        data: {},
        message: apiHelper.sortOutMessage(result.error_message)
      };
    }
  },
  [M_SET_PAY_UPLOAD_CREDIT](state, result) {
    if (result.redirect_url) {
      state.payUploadCredit = {
        code: 200,
        complete: true,
        data: result.redirect_url,
        message: null
      };
    } else {
      state.payUploadCredit = {
        code: result.code,
        complete: true,
        data: {},
        message: apiHelper.sortOutMessage(result.error_message)
      };
    }
  },
  [M_SET_PAY_SUBSCRIPTION](state, result) {
    if (result.redirect_url) {
      state.paySubscriptions = {
        code: 200,
        complete: true,
        data: result.redirect_url,
        message: null
      };
    } else {
      state.paySubscriptions = {
        code: result,
        complete: true,
        data: {},
        message: apiHelper.sortOutMessage(result.error_message)
      };
    }
  },
  [M_PURCHASE_ADMIN_ACCOUNT](state, result) {
    if (result.redirect_url) {
      state.purchaseAdminAccount = {
        code: 200,
        complete: true,
        data: result.redirect_url,
        message: null
      };
    } else {
      state.purchaseAdminAccount = {
        code: result,
        complete: true,
        data: {},
        message: apiHelper.sortOutMessage(result.error_message)
      };
    }
  },
  [M_CHECK_PAYMENT_SUBSCRIPTION](state, result) {
    state.checkPaymentSubscription = {
      code: result.code,
      complete: true,
      data: result.data || null,
      message:
        result.code === 200
          ? null
          : apiHelper.sortOutMessage(result.error_message)
    };
  },

  [M_INITIAL_GET_PAYMENT_HISTORY_STATE](state) {
    let data = state.paymentHistory.data;

    state.paymentHistory = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_PAYMENT_SUBSCRIPTIONS_STATE](state) {
    let data = state.paymentSubscriptions.data;

    state.paymentSubscriptions = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_GET_MY_SUBSCRIPTIONS_STATE](state) {
    let data = state.mySubscriptions.data;

    state.mySubscriptions = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_SET_PAY_UPLOAD_CREDIT_STATE](state) {
    let data = state.payUploadCredit.data;

    state.payUploadCredit = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  },
  [M_INITIAL_SET_PAY_SUBSCRIPTION_STATE](state) {
    state.paySubscriptions = {
      code: 0,
      complete: false,
      message: null
    };
  },
  [M_INITIAL_PURCHASE_ADMIN_ACCOUNT_STATE](state) {
    state.purchaseAdminAccount = {
      code: 0,
      complete: false,
      message: null
    };
  },
  [M_INITIAL_CHECK_PAYMENT_SUBSCRIPTION_STATE](state) {
    state.checkPaymentSubscription = {
      code: 0,
      complete: false,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
