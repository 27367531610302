import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import commonMixin from './mixin/common.mixin';

//Third part plugins
import i18n from './plugins/i18n';
import vuetify from './plugins/vuetify';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import './plugins/vue-clipboard2';
import './plugins/vue-observe-visibility';
// import './plugins/vue-flicking';
import './plugins/vue-gtag';
import './filters';

import { i18nService } from './services';

//Customize Style
import '@/assets/sass/init.scss';

//Reuseable UI Component
import AppButton from '@/components/base/AppButton';
import AppFormField from '@/components/base/AppFormField';
import AppImageModal from '@/components/base/AppImageModal.vue';
import AppModalBase from '@/components/base/ModalBase.vue';
import PreviewVideoModal from '@/components/base/PreviewVideoModal.vue';
import AppProgressSnackbar from '@/components/base/AppProgressSnackbar.vue';
import AppSearchBar from '@/components/base/AppSearchBar.vue';

Vue.component('AppButton', AppButton);
Vue.component('AppFormField', AppFormField);
Vue.component('AppImageModal', AppImageModal);
Vue.component('AppModalBase', AppModalBase);
Vue.component('AppPreviewVideoModal', PreviewVideoModal);
Vue.component('AppProgressSnackbar', AppProgressSnackbar);
Vue.component('AppSearchBar', AppSearchBar);
Vue.use(VueTelInputVuetify, {
  vuetify
});

Vue.config.productionTip = false;

export const bus = new Vue();

i18nService.init();
Vue.mixin(commonMixin);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount('#app');
