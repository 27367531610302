import {
  apiService,
  mapObjectToFormData,
  getQueryParamStringFromArray
} from '@/services';
import { uiHelper } from '@/utils';
import { LOCAL } from '@/constants';
export const CREATE_JOB = {
  getTalentList,
  sendInvitation,
  getSingleJobData,
  postJob,
  postCharacter,
  uploadCover,
  getRecommendationTalentList,
  uploadDraftFile,
  updateJob,
  removeRole,
  updateRole,
  postDraftJob,
  postDraftCharacter,
  updateDraftJob,
  updateDraftRole,
  removeDraftRole,
  getSingleDraftJobData
};

const URL = {
  API: 'api',
  V1: 'v1',
  V2: 'v2',
  V3: 'v3',
  CASTING: 'casting',
  REVIEW: 'review',
  ASSET: 'asset',
  INVITE: 'invite',
  ROLE: 'role',
  SEARCH_NAME: 'search-name',
  RECOMMENDATIONS: 'recommendations',
  CASTING_JOB_DRAFTS: 'casting-job-drafts',
  CASTING_DRAFTS: 'casting-drafts'
};

function mapQuery(query) {
  return Object.entries(query).reduce((acc, [key, value]) => {
    if (typeof value == 'string' || typeof value == 'number' || value == null) {
      acc[key] = value;
    } else if (value.tags) {
      acc[key] = value.tags;
    } else {
      acc[key] = (value || []).map((i) => i.value);
    }
    return acc;
  }, {});
}
function getTalentList(query) {
  const queryParam = getQueryParamStringFromArray({
    ...query,
    ...mapQuery(query)
  });
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.CASTING}/${URL.SEARCH_NAME}?` +
      queryParam.toString()
  );
}
function getRecommendationTalentList(query) {
  const queryParam = getQueryParamStringFromArray({
    ...query,
    ...mapQuery(query)
  });
  return apiService().get(
    `/${URL.API}/${URL.V1}/${URL.CASTING}/${URL.RECOMMENDATIONS}?` +
      queryParam.toString()
  );
}
function getSingleJobData(id) {
  return apiService().get(`/${URL.API}/${URL.V1}/${URL.CASTING}/${id}`);
}
function getSingleDraftJobData(id) {
  return apiService().get(`/${URL.API}/${URL.V1}/${URL.CASTING_DRAFTS}/${id}`);
}
function sendInvitation(data) {
  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.CASTING}/${URL.INVITE}`,
    data
  );
}
function postJob(data) {
  return apiService(null, true, true).post(
    `/${URL.API}/${URL.V1}/${URL.CASTING}`,
    mapObjectToFormData(data)
  );
}
function postDraftJob(data) {
  return apiService(null, true, true).post(
    `/${URL.API}/${URL.V1}/${URL.CASTING_DRAFTS}`,
    mapObjectToFormData(data)
  );
}
function postCharacter(data) {
  return apiService(null, true, true).post(
    `/${URL.API}/${URL.V3}/${URL.CASTING}/${URL.ROLE}`,
    mapObjectToFormData(data)
  );
}
function postDraftCharacter(data) {
  return apiService(null, true, true).post(
    `/${URL.API}/${URL.V1}/${URL.CASTING_DRAFTS}/${URL.ROLE}`,
    mapObjectToFormData(data)
  );
}
function removeRole(jobId, roleId) {
  return apiService().delete(
    `/${URL.API}/${URL.V1}/${URL.CASTING}/${jobId}/${URL.ROLE}/${roleId}`
  );
}
function removeDraftRole(jobId, roleId) {
  return apiService().delete(
    `/${URL.API}/${URL.V1}/${URL.CASTING_DRAFTS}/${jobId}/${URL.ROLE}/${roleId}`
  );
}
function uploadCover(id, data, draft = false) {
  return apiService(null, true, true).post(
    `/${URL.API}/${URL.V1}/${URL.ASSET}/${id}`,
    mapObjectToFormData({
      media_collection: JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS))
        .media_collections[draft ? 9 : 7],
      ...data
    })
  );
}
function updateJob(data, id) {
  return apiService(null, true, true).post(
    `/${URL.API}/${URL.V1}/${URL.CASTING}/${id}`,
    mapObjectToFormData({
      ...data
    })
  );
}
function updateDraftJob(data, id) {
  return apiService(null, true, true).post(
    `/${URL.API}/${URL.V1}/${URL.CASTING_DRAFTS}/${id}`,
    mapObjectToFormData({
      ...data
    })
  );
}
function updateRole(data, id) {
  return apiService(null, true, true).post(
    `/${URL.API}/${URL.V2}/${URL.CASTING}/${URL.ROLE}/${id}`,
    mapObjectToFormData({
      ...data
    })
  );
}
function updateDraftRole(data, id) {
  return apiService(null, true, true).post(
    `/${URL.API}/${URL.V1}/${URL.CASTING_DRAFTS}/${URL.ROLE}/${id}`,
    mapObjectToFormData({
      ...data
    })
  );
}
function uploadDraftFile(data) {
  return apiService(null, true, true).post(
    `/${URL.API}/${URL.V1}/${URL.CASTING_JOB_DRAFTS}`,
    mapObjectToFormData({
      ...data
    })
  );
}
