import { NEWSROOM } from '@/api';
import { apiHelper } from '@/utils';
import {
  SHARED_OPEN_LOADING,
  SHARED_CLOSE_LOADING
} from '@/store/shared.module';

/* Define Module Name */
const MODULE_NAME = 'newsroom/';

/* Define Action Name */
const A_GET_NEWS_LIST = 'getNewsList';

/* Define Reset State Action Name */
const A_INITIAL_GET_NEWS_LIST_STATE = 'initialGetNewsListState';
/* Define Getter Name */

/* Define Mutation Name */
const M_GET_NEWS_LIST = 'setGetNewsList';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_NEWS_LIST_STATE = 'setInitialGetNewsListState';

/* Define Export Name */
export const NEWSROOM_GET_NEWS_LIST = MODULE_NAME + A_GET_NEWS_LIST;

/* Define Reset State Name */
export const NEWSROOM_INITIAL_GET_NEWS_LIST_STATE =
  MODULE_NAME + A_INITIAL_GET_NEWS_LIST_STATE;

const state = {
  newsList: {
    code: 0,
    complete: false,
    data: [],
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    },
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_NEWS_LIST]({ dispatch, commit }, page) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    NEWSROOM.getNewsList(page)
      .then((response) => {
        result = response;
        commit(M_GET_NEWS_LIST, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_NEWS_LIST, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_NEWS_LIST_STATE]({ commit }) {
    commit(M_INITIAL_GET_NEWS_LIST_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_NEWS_LIST](state, result) {
    if (result.code === 200) {
      const { items, pagination } = result.data;
      const data = [...state.newsList.data, ...items];

      state.newsList = {
        code: result.code,
        data,
        pagination: {
          currentPage: pagination?.current_page,
          total: pagination?.total,
          perPage: pagination?.per_page,
          lastPage: pagination?.last_page
        },
        complete: true,
        message: null
      };
    } else {
      state.newsList = {
        code: result.code,
        data: [],
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        },
        complete: true,
        message: apiHelper.sortOutMessage(result.error_message)
      };
    }
  },
  [M_INITIAL_GET_NEWS_LIST_STATE](state) {
    let data = state.newsList.data;

    state.newsList = {
      code: 0,
      complete: false,
      data: {
        ...data
      },
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
