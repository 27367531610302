import { i18nHelper } from '@/utils';
import { LANGUAGE_CODE } from '@/constants/enums';

export const listService = {
  getDynamicList,
  getCountryList,
  getPhoneCodeList,
  getFreelancerList
};

function getDynamicList(originList, hasOptionAll = false, customKey = '') {
  let list = [];

  if (hasOptionAll) {
    let val = {
      text: i18nHelper.getMessage(`label.all`),
      value: ''
    }
    if(customKey) val[customKey] = ''
    list.push(val);
  }

  originList.forEach((x) => {
    let val = {
      text: x.description,
      value: x.value
    };
    if(customKey) val[customKey] = x[customKey]
    list.push(val);
  });

  return list;
}

function getCountryList(originList, language, hasOptionAll = false) {
  let list = [];
  let nameLocalization = {
    [LANGUAGE_CODE.ENGLISH]: 'name',
    [LANGUAGE_CODE.CHINESE]: 'name_chinese',
    [LANGUAGE_CODE.BAHASA_MALAYU]: 'name'
  };

  originList.forEach((x) => {
    list.push({
      text: x[nameLocalization[language]],
      value: x.id
    });
  });

  if (!hasOptionAll) {
    list = list.filter((x) => x.text != 'Worldwide');
  }

  return list;
}

function getPhoneCodeList(originList) {
  let list = [];

  originList.forEach((x) => {
    list.push({
      text: x.phone_code,
      value: x.phone_code
    });
  });

  return list;
}

function getFreelancerList(originList) {
  let list = [];

  originList.forEach((x) => {
    list.push({
      text: x.description,
      value: x.value
    });
  });

  return list;
}
