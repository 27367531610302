import dotenv from 'dotenv';

dotenv.config();

export class Configuration {
  static get CONFIG() {
    return {
      baseApiUrl: '$BASE_API_URL',
      gaMeasurementId: '$GA_MEASUREMENT_ID',
      assetEnvironment: '$ASSET_ENVIRONMENT',
      spacesUrl: '$SPACES_URL',
      baseChatUrl: '$BASE_CHAT_URL',
      chatApiUrl: '$CHAT_API_URL',
      stripeAcc: '$STRIPE_ACCOUNT',
      stripePk: '$STRIPE_PUBLISHABLE_KEY',
      stripeSk: '$STRIPE_SECRET_KEY'
    };
  }

  static value(name) {
    if (!(name in this.CONFIG)) {
      console.log(`Configuration: There is no key named "${name}"`);
      return;
    }

    const value = this.CONFIG[name];

    if (!value) {
      console.log(`Configuration: Value for "${name}" is not defined`);
      return;
    }

    if (value.startsWith('$')) {
      // value was not replaced, it seems we are in development.
      // Remove $ and get current value from process.env
      const envName = 'VUE_APP_' + value.substr(1);
      const envValue = process.env[envName];
      if (envValue) {
        return envValue;
      } else {
        console.log(
          `Configuration: Environment variable "${envName}" is not defined`
        );
      }
    } else {
      // value was already replaced, it seems we are in production.
      return value;
    }
  }
}
