import { i18nHelper } from '@/utils';
import moment from 'moment';

export const formValidationHelper = {
  requiredRules,
  usernameRules,
  passwordRules,
  maxLengthRules,
  confirmPasswordRules,
  emailRules,
  mobileNumberRules,
  twoDecimalRules,
  numericRules,
  dateRules,
  unionNo
};

const REGEX = Object.freeze({
  alphanumeric: /^[a-zA-Z0-9]+$/,
  username: /^[a-zA-Z0-9-. ]+$/,
  usernameNotAllowSpace: /^[a-zA-Z0-9-.]+$/,
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  notAllowMultipleSpace: /^\S+(?: \S+)*$/,
  decimalTwo: /^[0-9]\d*(\.\d{1,2})?$/,
  numeric: /^[0-9]+$/,
  mobileNumber: /[0-9]{8,14}$/,
  unionNo: /^\d{12}$/,
  mobileNumberMalaysia: /^[1][0-9]{8}$|^[1][1][1][0-9]{7}$/,
  mobileNumberSingapore: /^[6|8|9]\d{7}$/,
  passwordFormat:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)” + “(?=.*[-+_!@#$%^&*., ?]).+$/
});

/* Start Rules */
function requiredRules(fieldName = null, min = 0, max = 0) {
  return [
    requiredField(fieldName),
    notAllowMultipleSpace(),
    minMaxLength(min, max)
  ];
}

function usernameRules() {
  return [
    requiredField(),
    usernameFormat(),
    minMaxLength(6, 30),
    usernameNotAllowSpace()
  ];
}

function passwordRules(val) {
  return [requiredField(), minMaxLength(8, 15), passwordFormat(val)];
}

function confirmPasswordRules(targetValue, value) {
  return [
    requiredField(),
    comparePasswordValue(targetValue, value),
    minMaxLength(6, 30)
  ];
}

function maxLengthRules(val) {
  return [maxLength(val)];
}

function emailRules() {
  return [
    //requiredField(i18nHelper.getMessage('label.email')),
    emailFormat()
    //notAllowMultipleSpace(),
    //minMaxLength(0, 50)
  ];
}

function mobileNumberRules(countryCode, mobile) {
  if (!mobile) return [];
  return [mobileNumberFormat(countryCode, mobile)];
}

function twoDecimalRules() {
  return [requiredField(), notAllowMultipleSpace(), twoDecimalFormat()];
}

function numericRules() {
  return [requiredField(), notAllowMultipleSpace(), numericFormat()];
}

function dateRules(min, max, fieldName) {
  return [requiredField(fieldName), minMaxDate(min, max)];
}
/* end Rules */

/* Start Shared Validation */
function requiredField(fieldName) {
  return (v) =>
    !!v ||
    (fieldName
      ? i18nHelper.getMessage('fieldErrorMessage.requiredFieldDynamic', [
          fieldName
        ])
      : i18nHelper.getMessage('fieldErrorMessage.requiredField'));
}

function minMaxLength(min, max) {
  if (min == 0 && max == 0) {
    return true;
  } else {
    return (v) =>
      (v && v.length >= min && v.length <= max) ||
      i18nHelper.getMessage('fieldErrorMessage.exceedMinMaxLength', [min, max]);
  }
}

function maxLength(max) {
  if (max == 0) {
    return true;
  } else {
    return (v) =>
      (v && v.length <= max) ||
      i18nHelper.getMessage('fieldErrorMessage.exceedMaxLength', [max]);
  }
}

// function containSpecialCharacter(val) {
//   const specialChars = "!@#$^&%*()+=-[]{}|:<>?,.`" + `'"`;
//   return specialChars.split('').includes(val.split(''));
// }

function passwordFormat(val) {
  const pattern = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$'
  );
  return (
    (val && pattern.test(val)) ||
    'Please use 8-15 characters with a mix of upper and lowercase letters, numbers & special characters i.e: ! @ # $'
  );
}

function comparePasswordValue(targetValue, value) {
  return () =>
    targetValue === value ||
    i18nHelper.getMessage('fieldErrorMessage.confirmPasswordNotMatch');
}
/* End Shared Validation */

/* Start Format Validation */
function usernameNotAllowSpace() {
  return (v) =>
    (v && REGEX.usernameNotAllowSpace.test(v)) ||
    i18nHelper.getMessage('fieldErrorMessage.invalidUsernameNotAllowSpace');
}

function notAllowMultipleSpace() {
  return (v) =>
    (v && REGEX.notAllowMultipleSpace.test(v)) ||
    i18nHelper.getMessage('fieldErrorMessage.multipleSpace');
}

function usernameFormat() {
  return (v) =>
    (v && REGEX.username.test(v)) ||
    i18nHelper.getMessage('fieldErrorMessage.invalidUsernameFormat');
}

function emailFormat() {
  return (v) => {
    if (v == '' || v == null) {
      return true;
    } else {
      return (
        (v && REGEX.email.test(v)) ||
        i18nHelper.getMessage('fieldErrorMessage.invalidEmailFormat')
      );
    }
  };
}

function twoDecimalFormat() {
  return (v) =>
    (v && REGEX.decimalTwo.test(v)) ||
    i18nHelper.getMessage('fieldErrorMessage.invalidDecimalFormat');
}

function numericFormat() {
  return (v) =>
    (v && REGEX.numeric.test(v)) ||
    i18nHelper.getMessage('fieldErrorMessage.invalidNumericFormat');
}

function mobileNumberFormat(mobile, countryCode) {
  if (mobile == '' || mobile == null) {
    return true;
  } else {
    let mobileNumberRegex = REGEX.mobileNumber;
    switch (countryCode) {
      case '+65':
        mobileNumberRegex = REGEX.mobileNumberSingapore;
        break;
      case '+60':
        mobileNumberRegex = REGEX.mobileNumberMalaysia;
        break;
      default:
        break;
    }
    return (v) =>
      (v && mobileNumberRegex.test(v)) ||
      i18nHelper.getMessage('fieldErrorMessage.invalidMobileNumberFormat');
  }
}

function minMaxDate(min, max) {
  let defaultMinDate = new Date('01/01/1920');
  let minDate = new Date(min);
  let maxDate = new Date(max);

  if (min == 0) {
    minDate = defaultMinDate;
  }

  return (v) => {
    let date = new Date(v).getTime();
    let displayMaxDate = maxDate.toUTCString().substr(5, 11);
    let displayMinDate = minDate.toUTCString().substr(5, 11);
    let displayDefaultMinDate = defaultMinDate.toUTCString().substr(5, 11);

    if (v) {
      if (new Date(v) != 'Invalid Date' && moment(v, 'YYYY-MM-DD').isValid()) {
        if (!max) {
          return (
            (v && min
              ? minDate.getTime() > date
              : defaultMinDate.getTime() > date) ||
            i18nHelper.getMessage('fieldErrorMessage.invalidMinDate', [
              min ? displayMinDate : displayDefaultMinDate
            ])
          );
        } else {
          if (v && maxDate.getTime() < date) {
            return i18nHelper.getMessage('fieldErrorMessage.invalidMaxDate', [
              displayMaxDate
            ]);
          } else if (v && minDate.getTime() > date) {
            return i18nHelper.getMessage('fieldErrorMessage.invalidMinDate', [
              min ? displayMinDate : displayDefaultMinDate
            ]);
          } else {
            return true;
          }
        }
      } else {
        return i18nHelper.getMessage('fieldErrorMessage.invalidDateFormat');
      }
    } else {
      return true;
    }
  };
}

function unionNo() {
  return (v) =>
    (v && REGEX.unionNo.test(v)) ||
    i18nHelper.getMessage('fieldErrorMessage.invalidUnionNoFormat');
}
/* End Format Validation*/
