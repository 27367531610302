<template>
  <div>
    <ModalBase id="reportModal" width="900px" @closeModal="clearInputs">
      <h2 class="text-uppercase pb-4">
        {{ $t('message.whyAreYouReportingThis?') }}
      </h2>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-radio-group
          v-model="formValues.type"
          :rules="[(v) => !!v || 'This field is required']"
          :class="formValues.type == 8 ? 'pb-3' : ''"
          :hide-details="true"
        >
          <v-row no-gutters class="px-3">
            <v-col
              cols="12"
              sm="6"
              v-for="type in flags"
              :key="type.value"
              class="mb-3"
            >
              <v-radio
                :label="type.description"
                :value="type.value"
                color="primary"
              />
            </v-col>
            <v-col cols="12" class="mb-3" v-if="formValues.type == 8">
              <CustomInput
                :label="$t('label.pleaseSpecify')"
                name="typeOther"
                type="text"
                v-model="formValues.typeOther"
                :rules="[(v) => !!v || 'This field is required']"
                dark
              />
            </v-col>
          </v-row>
        </v-radio-group>
        <div class="mb-4">
          <CustomInput
            :label="$t('label.comment')"
            name="comment"
            type="textarea"
            v-model="formValues.comment"
            counter="500"
            :rules="[
              (v) => !!v || 'Comment is required',
              (v) =>
                v.length < 500 || 'This field must not exceed 500 characters'
            ]"
            dark
            outlined
          />
        </div>
        <div>
          <div>{{ $t('label.photo') }}</div>
          <v-row no-gutters>
            <template v-for="(photo, index) in formValues.proof">
              <v-col cols="12" sm="auto" class="me-2" :key="index" v-if="photo">
                <CustomInput
                  name="proof"
                  type="file"
                  :config="{
                    accept: 'image/png, image/jpeg, image/jpg'
                  }"
                  v-model="formValues.proof[index]"
                  @clearFile="removeFromArr(index)"
                  :showFileName="false"
                  dark
                  outlined
                />
              </v-col>
            </template>

            <v-col cols="12" sm="auto" v-if="formValues.proof.length < 5">
              <div>
                <CustomInput
                  name="proof"
                  type="file"
                  :config="{
                    accept: 'image/png, image/jpeg, image/jpg'
                  }"
                  v-model="formValues.proof[formValues.proof.length || 0]"
                  dark
                  outlined
                />
              </div>
            </v-col>
          </v-row>
          <div class="text-right grey--text">
            {{ formValues.proof.length }}/5
          </div>
        </div>
      </v-form>
      <div class="text-center pt-12 mt-10">
        <v-btn
          class="btn1"
          :disabled="!valid || !(formValues.comment && formValues.type)"
          @click="report"
        >
          {{ $t('label.report') }}
        </v-btn>
      </div>
    </ModalBase>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import CustomInput from '@/components/inputs/custom-input.vue';
  import ModalBase from '@/components/base/ModalBase';
  import { HOME_SCREENS } from '@/api';
  import { bus } from '@/main.js';

  export default {
    name: 'ReportModal',
    props: [],
    components: {
      ModalBase,
      CustomInput
    },
    data() {
      return {
        formValues: {
          id: null,
          type: null,
          typeOther: null,
          comment: '',
          proof: []
        },
        reportType: null,
        valid: false,
        fields: [
          {
            key: 'id',
            default: null
          },
          {
            key: 'type',
            default: null
          },
          {
            key: 'typeOther',
            default: null
          },
          {
            key: 'comment',
            default: ''
          },
          {
            key: 'proof',
            default: []
          }
        ]
      };
    },
    computed: {
      ...mapGetters({
        flags: ['constants/flag']
      })
    },
    methods: {
      removeFromArr() {
        this.$nextTick(() => {
          this.formValues.proof = this.formValues.proof.filter((x) => !!x);
        });
      },
      async report() {
        try {
          const data = {
            ...this.formValues,
            proof: this.formValues.proof.map((x) => x.file)
          };
          const response = await HOME_SCREENS.flag(data, this.reportType);
          this.$openModal('messageModal', response.msg, {
            title: 'Ok',
            action: () => {
              if (response.code == 200) {
                this.fields.forEach((x) => {
                  this.formValues[x.key] = x.default;
                });
                this.reportType = null;
              }
              this.$closeModal();
            }
          });
        } catch (e) {
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
      },
      clearInputs() {
        this.formValues = {
          id: null,
          type: null,
          typeOther: null,
          comment: '',
          proof: []
        }
      }
    },
    created() {
      bus.$on('report', (data) => {
        this.formValues.id = data.id;
        this.reportType = data.reportType;
        this.$openModal('reportModal');
      });
    }
  };
</script>

<style></style>
