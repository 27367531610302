import { AUTH } from '@/api';
import { LOCAL } from '@/constants';
import { jwtHelper, uiHelper, i18nHelper, apiHelper } from '@/utils';
import {
  SHARED_OPEN_LOADING,
  SHARED_CLOSE_LOADING
} from '@/store/shared.module';

/* Define Module Name */
const MODULE_NAME = 'auth/';

/* Define Action Name */
const A_ME = 'me';
const A_LOGIN = 'login';
const A_LOGOUT = 'logout';
const A_REFRESH = 'refresh';
const A_CHECK_AVAILABILITY_TEASER = 'checkAvailabilityTeaser';
const A_REQUEST_OTP = 'requestOTP';
const A_VERIFY_OTP = 'verifyOTP';
const A_REGISTER_TALENT = 'registerTalent';
const A_REGISTER_AGENCY = 'registerAgency';
const A_REGISTER_FREELANCER = 'registerFreelancer';
const A_REGISTER_PRODUCTION_HOUSE = 'registerProductionHouse';
const A_CHECK_USER_EXISTENCE = 'checkUserExist';

/* Define Reset State Action Name */
const A_INITIAL_LOGIN_STATE = 'initialLoginState';
const A_INITIAL_ME_STATE = 'initialMeState';
const A_RESET_LOGIN_STATE = 'resetLoginState';
const A_INITIAL_CHECK_AVAILABILITY_TEASER_STATE =
  'initialCheckAvailabilityTeaserState';
const A_INITIAL_REQUEST_OTP_STATE = 'initialRequestOTPState';
const A_INITIAL_VERIFY_OTP_STATE = 'initialVerifyOTPState';
const A_INITIAL_REGISTER_TALENT_STATE = 'initialRegisterTalentState';
const A_INITIAL_REGISTER_AGENCY_STATE = 'initialRegisterAgencyState';
const A_INITIAL_REGISTER_FREELANCER_STATE = 'initialRegisterFreelancerState';
const A_INITIAL_REGISTER_PRODUCTION_HOUSE_STATE =
  'initialRegisterProductionHouseState';
const A_INITIAL_CHECK_USER_EXISTENCE = 'initialCheckUserExistence';

/* Define Getter Name */
const G_IS_AUTHENTICATED = 'isAuthenticated';

/* Define Mutation Name */
const M_ME = 'setMe';
const M_LOGIN = 'setLogin';
const M_LOGOUT = 'setLogout';
const M_LOGOUT_SESSION_EXPIRED = 'setLogoutSessionExpired';
const M_REFRESH = 'setRefresh';
const M_CHECK_AVAILABILITY_TEASER = 'setCheckAvailabilityTeaser';
const M_REQUEST_OTP = 'setRequestOTP';
const M_VERIFY_OTP = 'setVerifyOTP';
const M_REGISTER_TALENT = 'setRegisterTalent';
const M_REGISTER_AGENCY = 'setRegisterAgency';
const M_REGISTER_FREELANCER = 'setRegisterFreelancer';
const M_REGISTER_PRODUCTION_HOUSE = 'setRegisterProductionHouse';
const M_CHECK_USER_EXISTENCE = 'setCheckUserExistence';

/* Define Reset State Mutation Name */
const M_INITIAL_LOGIN_STATE = 'setInitialLoginState';
const M_RESET_LOGIN_STATE = 'setResetLoginState';
const M_INITIAL_ME_STATE = 'setInitialMeState';
const M_INITIAL_CHECK_AVAILABILITY_TEASER_STATE =
  'setInitialCheckAvailabilityTeaserState';
const M_INITIAL_REQUEST_OTP_STATE = 'setInitialRequestOTPState';
const M_INITIAL_VERIFY_OTP_STATE = 'setInitialVerifyOTPState';
const M_INITIAL_REGISTER_TALENT_STATE = 'setInitialRegisterTalentState';
const M_INITIAL_REGISTER_AGENCY_STATE = 'setInitialRegisterAgencyState';
const M_INITIAL_REGISTER_FREELANCER_STATE = 'setInitialRegisterFreelancerState';
const M_INITIAL_REGISTER_PRODUCTION_HOUSE_STATE =
  'setInitialRegisterProductionHouseState';
const M_INITIAL_CHECK_USER_EXISTENCE = 'setInitialCheckUserExistence';

/* Define Export Name */
export const AUTH_ME = MODULE_NAME + A_ME;
export const AUTH_RESET_LOGIN_STATE = MODULE_NAME + A_RESET_LOGIN_STATE;
export const AUTH_LOGIN = MODULE_NAME + A_LOGIN;
export const AUTH_LOGOUT = MODULE_NAME + A_LOGOUT;
export const AUTH_REFRESH = MODULE_NAME + A_REFRESH;
export const AUTH_IS_AUTHENTICATED = MODULE_NAME + G_IS_AUTHENTICATED;
export const AUTH_CHECK_AVAILABILITY_TEASER =
  MODULE_NAME + A_CHECK_AVAILABILITY_TEASER;
export const AUTH_REQUEST_OTP = MODULE_NAME + A_REQUEST_OTP;
export const AUTH_VERIFY_OTP = MODULE_NAME + A_VERIFY_OTP;
export const AUTH_REGISTER_TALENT = MODULE_NAME + A_REGISTER_TALENT;
export const AUTH_REGISTER_AGENCY = MODULE_NAME + A_REGISTER_AGENCY;
export const AUTH_REGISTER_FREELANCER = MODULE_NAME + A_REGISTER_FREELANCER;
export const AUTH_REGISTER_PRODUCTION_HOUSE =
  MODULE_NAME + A_REGISTER_PRODUCTION_HOUSE;
export const AUTH_CHECK_USER_EXISTENCE = MODULE_NAME + A_CHECK_USER_EXISTENCE;

/* Define Reset State Name */
export const AUTH_INITIAL_LOGIN_STATE = MODULE_NAME + A_INITIAL_LOGIN_STATE;
export const AUTH_INITIAL_ME_STATE = MODULE_NAME + A_INITIAL_ME_STATE;
export const AUTH_INITIAL_CHECK_AVAILABILITY_TEASER_STATE =
  MODULE_NAME + A_INITIAL_CHECK_AVAILABILITY_TEASER_STATE;
export const AUTH_INITIAL_REQUEST_OTP_STATE =
  MODULE_NAME + A_INITIAL_REQUEST_OTP_STATE;
export const AUTH_INITIAL_VERIFY_OTP_STATE =
  MODULE_NAME + A_INITIAL_VERIFY_OTP_STATE;
export const AUTH_INITIAL_REGISTER_TALENT_STATE =
  MODULE_NAME + A_INITIAL_REGISTER_TALENT_STATE;
export const AUTH_INITIAL_REGISTER_AGENCY_STATE =
  MODULE_NAME + A_INITIAL_REGISTER_AGENCY_STATE;
export const AUTH_INITIAL_REGISTER_FREELANCER_STATE =
  MODULE_NAME + A_INITIAL_REGISTER_FREELANCER_STATE;
export const AUTH_INITIAL_REGISTER_PRODUCTION_HOUSE_STATE =
  MODULE_NAME + A_INITIAL_REGISTER_PRODUCTION_HOUSE_STATE;
export const AUTH_INITIAL_CHECK_USER_EXISTENCE =
  MODULE_NAME + A_INITIAL_CHECK_USER_EXISTENCE;

const state = {
  me: {
    code: 0,
    complete: false,
    data: {
      id: null,
      username: null,
      name: null,
      email: null,
      emailVerifiedAt: null,
      createdAt: null,
      updatedAt: null
    },
    message: null
  },
  checkUserExist: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  loginResponse: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  checkAvailabilityTeaser: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  requestOTP: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  verifyOTP: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  registerTalent: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  logoutResponse: { code: 0, complete: false, data: null, message: null },
  registerAgency: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  registerFreelancer: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  registerProductionHouse: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {
  [G_IS_AUTHENTICATED]: (state) => {
    let result = false;
    let token = jwtHelper.getToken();

    if (state.loginResponse.complete) {
      token = jwtHelper.getToken();
    }

    if (![null, undefined, ''].includes(token)) {
      result = true;
    }

    return result;
  },
  me: (state) => {
    return state?.me?.data || {};
  }
};

// Actions
const actions = {
  async [A_ME]({ dispatch, commit }) {
    if (jwtHelper.getToken()) {
      dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
      await AUTH.me()
        .then((response) => {
          let result = response;

          commit(M_ME, result);
          dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
        })
        .catch((error) => {
          let result = error;
          commit(M_ME, result);
          dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
        });
    }
  },
  async [A_LOGIN]({ dispatch, commit }, { data }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    await AUTH.login(data)
      .then((response) => {
        let result = response;
        commit(M_LOGIN, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_LOGIN, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_LOGOUT]({ dispatch, commit }, { isSessionExpired }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    if (!isSessionExpired) {
      await AUTH.logout()
        .then((response) => {
          let result = response;
          commit(M_LOGOUT, result);
          dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
        })
        .catch((error) => {
          let result = error;
          commit(M_LOGOUT, result);
          dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
        });
    } else {
      commit(M_LOGOUT_SESSION_EXPIRED, isSessionExpired);
    }
  },
  async [A_REFRESH]({ commit }) {
    await AUTH.refresh()
      .then((response) => {
        let result = response;
        commit(M_REFRESH, result);
      })
      .catch((error) => {
        let result = error;
        commit(M_REFRESH, result);
      });
  },
  async [A_INITIAL_LOGIN_STATE]({ commit }) {
    await AUTH.checkPasswordAge()
      .then((data) => {
        commit(M_INITIAL_LOGIN_STATE, data);
      })
      .catch((error) => {
        commit(M_INITIAL_LOGIN_STATE, error);
      });
    commit(M_INITIAL_LOGIN_STATE);
  },
  [A_RESET_LOGIN_STATE]({ commit }) {
    commit(M_RESET_LOGIN_STATE);
  },
  [A_INITIAL_ME_STATE]({ commit }) {
    commit(M_INITIAL_ME_STATE);
  },
  [A_CHECK_AVAILABILITY_TEASER]({ dispatch, commit }, { data }) {
    let result;
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    AUTH.checkAvailabilityTeaser(data)
      .then((response) => {
        result = response;
        commit(M_CHECK_AVAILABILITY_TEASER, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_CHECK_AVAILABILITY_TEASER, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_REQUEST_OTP]({ dispatch, commit }, { data }) {
    let result;
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    await AUTH.requestOTP(data)
      .then((response) => {
        result = response;
        commit(M_REQUEST_OTP, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_REQUEST_OTP, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_VERIFY_OTP]({ dispatch, commit }, { data }) {
    let result;
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    await AUTH.verifyOTP(data)
      .then((response) => {
        result = response;
        commit(M_VERIFY_OTP, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_VERIFY_OTP, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_REGISTER_TALENT]({ dispatch, commit }, { data }) {
    let result;
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    AUTH.registerTalent(data)
      .then((response) => {
        result = response;
        commit(M_REGISTER_TALENT, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_REGISTER_TALENT, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_REGISTER_AGENCY]({ dispatch, commit }, { data }) {
    let result;
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    AUTH.registerAgency(data)
      .then((response) => {
        result = response;
        commit(M_REGISTER_AGENCY, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_REGISTER_AGENCY, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_REGISTER_FREELANCER]({ dispatch, commit }, { data }) {
    let result;
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    AUTH.registerFreelancer(data)
      .then((response) => {
        result = response;
        commit(M_REGISTER_FREELANCER, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_REGISTER_FREELANCER, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_REGISTER_PRODUCTION_HOUSE]({ dispatch, commit }, { data }) {
    let result;
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    AUTH.registerProductionHouse(data)
      .then((response) => {
        result = response;
        commit(M_REGISTER_PRODUCTION_HOUSE, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_REGISTER_PRODUCTION_HOUSE, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_CHECK_USER_EXISTENCE]({ commit }, { data }) {
    await AUTH.checkUserExist(data)
      .then((response) => {
        let result = response;
        commit(M_CHECK_USER_EXISTENCE, result);
      })
      .catch((error) => {
        let result = error;
        commit(M_CHECK_USER_EXISTENCE, result);
      });
  },
  [A_INITIAL_CHECK_AVAILABILITY_TEASER_STATE]({ commit }) {
    commit(M_INITIAL_CHECK_AVAILABILITY_TEASER_STATE);
  },
  [A_INITIAL_REQUEST_OTP_STATE]({ commit }) {
    commit(M_INITIAL_REQUEST_OTP_STATE);
  },
  [A_INITIAL_VERIFY_OTP_STATE]({ commit }) {
    commit(M_INITIAL_VERIFY_OTP_STATE);
  },
  [A_INITIAL_REGISTER_TALENT_STATE]({ commit }) {
    commit(M_INITIAL_REGISTER_TALENT_STATE);
  },
  [A_INITIAL_REGISTER_AGENCY_STATE]({ commit }) {
    commit(M_INITIAL_REGISTER_AGENCY_STATE);
  },
  [A_INITIAL_REGISTER_FREELANCER_STATE]({ commit }) {
    commit(M_INITIAL_REGISTER_FREELANCER_STATE);
  },
  [A_INITIAL_REGISTER_PRODUCTION_HOUSE_STATE]({ commit }) {
    commit(M_INITIAL_REGISTER_PRODUCTION_HOUSE_STATE);
  },
  [A_INITIAL_CHECK_USER_EXISTENCE]({ commit }) {
    commit(M_INITIAL_CHECK_USER_EXISTENCE);
  }
};

// Mutations
const mutations = {
  [M_ME](state, result) {
    if (result.code === 200) {
      let data = result?.data?.profile;
      let auth = result?.data?.auth;
      state.me = {
        code: result.code,
        complete: true,
        data: {
          ...data,
          role: auth?.role,
          project_role_type_ids: auth?.project_role_type_ids || [],
          isUnder18: auth?.role == 'talent' && (uiHelper.getAge(data.dob) < 18),
          guardian_relationship: auth?.guardian_relationship,
          guardian_gender: auth?.guardian_gender,
        },
        message: null
      };
    } else {
      jwtHelper.removeToken();
      jwtHelper.removeLastActivityTime();
      jwtHelper.removeSessionExpirationTime();
      uiHelper.openPage('/', '_self');
    }
  },
  async [M_LOGIN](state, result) {
    if (result.code === 200) {
      let data = result.data;
      jwtHelper.setToken(data.access_token);
      const me = await AUTH.me();
      // if(['talent', 'agency'].includes(me.data.auth.role)) jwtHelper.removeToken();
      state.loginResponse = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(
          i18nHelper.getMessage('message.loginSuccessfully')
        ),
        data: {
          accessToken: data.access_token,
          tokenType: data.token_type,
          isTalentOrAgency: ['talent', 'agency'].includes(me.data.auth.role)
        }
      };
    } else {
      state.loginResponse = {
        code: result.code,
        complete: true,
        message: result?.error_message || 'Invalid Email or Mobile or Password'
      };
    }
  },
  [M_LOGOUT](state, result) {
    if (result.code === 200) {
      state.logoutResponse = {
        code: result.code,
        complete: true,
        message: result.msg
      };
    } else {
      state.logoutResponse = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result)
      };
    }
    jwtHelper.removeToken();
    jwtHelper.removeLastActivityTime();
    jwtHelper.removeSessionExpirationTime();
    uiHelper.removeLocalStorage(LOCAL.CONSTANTS);
    window.location.replace('/');
  },
  [M_LOGOUT_SESSION_EXPIRED](isSessionExpired) {
    if (isSessionExpired) {
      jwtHelper.removeToken();
      jwtHelper.removeLastActivityTime();
      jwtHelper.removeSessionExpirationTime();
      uiHelper.removeLocalStorage(LOCAL.CONSTANTS);
      uiHelper.openPage('/', '_self');
    }
  },
  [M_REFRESH](state, result) {
    if (result.code === 0) {
      let data = result.data;
      state.loginResponse = {
        code: result.code,
        complete: true,
        message: null,
        data: {
          accessToken: data.access_token,
          tokenType: data.token_type,
          expiryIn: data.expires_in
        }
      };

      jwtHelper.setToken(data.access_token);
      jwtHelper.setSessionExpirationTime(data.expires_in);
    } else {
      state.loginResponse = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result)
      };
    }
  },
  [M_RESET_LOGIN_STATE](state) {
    state.loginResponse = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_LOGIN_STATE](state, result) {
    if (result?.code >= 400) {
      jwtHelper.removeToken();
      jwtHelper.removeLastActivityTime();
      jwtHelper.removeSessionExpirationTime();
      uiHelper.openPage('/', '_self');
    }
  },
  [M_CHECK_AVAILABILITY_TEASER](state, result) {
    if (result.code == 200) {
      state.checkAvailabilityTeaser = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.msg)
      };
    } else {
      state.checkAvailabilityTeaser = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.error_message)
      };
    }
  },
  [M_CHECK_USER_EXISTENCE](state, result) {
    state.checkUserExist = {
      code: result.code,
      complete: true,
      data: result.code == 200 ? result.data : null,
      message: apiHelper.sortOutMessage(result.error_message)
    };
  },
  [M_REQUEST_OTP](state, result) {
    if (result.code == 200) {
      state.requestOTP = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.msg)
      };
    } else {
      state.requestOTP = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.error_message)
      };
    }
  },
  [M_VERIFY_OTP](state, result) {
    if (result.code == 200) {
      state.verifyOTP = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.msg)
      };
    } else {
      state.verifyOTP = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.error_message)
      };
    }
  },
  [M_REGISTER_TALENT](state, result) {
    if (result.code == 200) {
      state.registerTalent = {
        code: result.code,
        complete: true,
        data: result.data,
        message: apiHelper.sortOutMessage(result.msg)
      };
    } else {
      state.registerTalent = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.error_message)
      };
    }
  },
  [M_REGISTER_AGENCY](state, result) {
    if (result.code == 200) {
      state.registerAgency = {
        code: result.code,
        complete: true,
        data: result.data,
        message: apiHelper.sortOutMessage(result.msg)
      };
    } else {
      state.registerAgency = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.error_message)
      };
    }
  },
  [M_REGISTER_FREELANCER](state, result) {
    if (result.code == 200) {
      state.registerFreelancer = {
        code: result.code,
        complete: true,
        data: result.data,
        message: apiHelper.sortOutMessage(result.msg)
      };
    } else {
      state.registerFreelancer = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.error_message)
      };
    }
  },
  [M_REGISTER_PRODUCTION_HOUSE](state, result) {
    if (result.code == 200) {
      state.registerProductionHouse = {
        code: result.code,
        complete: true,
        data: result.data,
        message: apiHelper.sortOutMessage(result.msg)
      };
    } else {
      state.registerProductionHouse = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.error_message)
      };
    }
  },
  [M_INITIAL_CHECK_AVAILABILITY_TEASER_STATE](state) {
    state.checkAvailabilityTeaser = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_ME_STATE](state) {
    state.me = {
      code: 0,
      complete: false,
      data: {
        id: null,
        username: null,
        name: null,
        email: null,
        emailVerifiedAt: null,
        createdAt: null,
        updatedAt: null
      }
    };
  },
  [M_INITIAL_REQUEST_OTP_STATE](state) {
    state.requestOTP = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_VERIFY_OTP_STATE](state) {
    state.verifyOTP = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_REGISTER_TALENT_STATE](state) {
    state.registerTalent = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_REGISTER_AGENCY_STATE](state) {
    state.registerAgency = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_REGISTER_FREELANCER_STATE](state) {
    state.registerFreelancer = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_REGISTER_PRODUCTION_HOUSE_STATE](state) {
    state.registerProductionHouse = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_CHECK_USER_EXISTENCE](state) {
    state.checkUserExist = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
