<template>
  <div class="text-center">
    <v-dialog
      content-class="baseModal"
      v-model="dialog"
      :max-width="width"
      :persistent="persistent"
      dark
      @click:outside="closeModal(persistent)"
    >
      <div class="w-100 d-flex justify-end close-button">
        <v-btn
          icon
          dark
          class=""
          v-if="!noCloseButton"
          @click="closeOnAlert ? alertClose() : closeModal()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <slot />
    </v-dialog>
  </div>
</template>
<script>
  import { SHARED_CLOSE_MODAL } from '@/store/shared.module';
  export default {
    name: 'ModalBase',
    props: {
      id: String,
      persistent: { type: Boolean, default: false },
      width: {
        type: String,
        default: '700'
      },
      noCloseButton: {
        type: Boolean,
        default: false
      },
      closeOnAlert: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      closeModal(persistent = false) {
        if (persistent) return;

        this.close();
        this.$emit('closeModal');
      },
      close() {
        this.$store.dispatch(SHARED_CLOSE_MODAL);
      },
      alertClose() {
        if (confirm('Are you sure?')) this.closeModal();
      }
    },
    computed: {
      dialog() {
        return (
          this.$store.state.shared.openModal &&
          this.id == this.$store.state.shared.modalId
        );
      }
    }
  };
</script>
<style lang="scss">
  .baseModal {
    background-color: #242424;
    width: 100%;
    min-height: 100px;
    padding: 2rem 5rem;
  }
  @media (max-width: 720px) {
    .baseModal {
      padding: 2rem;
    }
  }
  .close-button {
    top: -10%;
  }
</style>
