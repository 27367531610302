import { PROFILE } from '@/api';
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg/dist/ffmpeg.min.js';

const uploadMediaMixin = {
  methods: {
    uploadMedia(data) {
      return PROFILE.uploadFile(
        data.profileId,
        {
          file: data.file
        },
        data.mediaId
      );
    },
    async compressFile(value) {
      const ffmpeg = await createFFmpeg({
        log: true,
        progress: async (params) => {
          const percentage = params.ratio * 100;
          this.$compressVideoPercentage(percentage.toFixed(2));
        }
      });

      const transcode = async (file) => {
        const { name } = file;
        await ffmpeg.load();
        ffmpeg.FS('writeFile', name, await fetchFile(file));
        // await ffmpeg.run('-i', name, '-vcodec', 'libx264', '-crf 24', '-preset ultrafast', 'output9.mp4');
        await ffmpeg.run(
          '-i',
          name,
          // '-t', // reduce video time to speed up compress time
          // '5', // reduce video time
          '-ss',
          '2.0',
          '-f',
          'mp4',
          'output9.mp4'
        );
        const data = await ffmpeg.FS('readFile', 'output9.mp4');

        // // Do something with the processed video data, e.g., download it
        if (data) {
          const compressed = new File([data.buffer], name, {
            type: 'video/mp4'
          });
          return compressed;
        }
      };

      // transcode(e);
      let result = await transcode(value.file);
      this.$resetCompressVideoPercentage();
      if (typeof result.name == 'string') {
        result = { file: result, complete: true };
        return result;
      } else {
        result = { file: null, complete: false };
        return result;
      }
    },
    async processValue(values, extra) {
      const openAppDialogInfo = (e, isVideo = false) => {
        let errMsg = isVideo
          ? 'Upload Failed, Please check your internet connection and try again'
          : 'Request Failed';
        this.openAppDialogInfo('error', e.error_message || errMsg, '', [
          {
            text: 'OK',
            action: () => {
              this.closeAppDialogInfo();
            }
          }
        ]);
      };
      let compressedFiles = [];
      const process = (value) => {
        return new Promise((resolve) => {
          // Simulating an asynchronous operation with setTimeout
          setTimeout(() => {
            // Your function logic for each value goes here

            // Simulating the condition where the function returns true
            resolve(this.compressFile(value));
          }, 2000);
        });
      };
      const processValues = async () => {
        for (let i = 0; i < values.length; i++) {
          if (!values[i] || values[i].id) {
            compressedFiles.push(values[i]);
            continue;
          }
          const value = { ...values[i] };
          const result = await process(value);
          if (result.complete) {
            // Handle the case where the function return true, return data need to push in array to continue API
            compressedFiles.push(result);

            if (compressedFiles.length == values.length) {
              // Handle API when compressed file complete
              compressedFiles.map(async (f, idx) => {
                let data = {
                  file: f.file
                };
                if (extra.mediaId == 2) data.seq = idx + 1;
                this.$videoLoading();
                await PROFILE.uploadFile(
                  extra.profileId,
                  data,
                  extra.mediaId
                ).catch((e) => {
                  openAppDialogInfo(e, true);
                });
                this.$resetVideoLoading();
              });
            }
          } else {
            // Handle the case where the function didn't return true
            console.log(
              'Function did not return true for value :',
              result.complete
            );
            break; // Stop processing further values
          }
        }
      };

      await processValues();
    }
  }
};

export default uploadMediaMixin;
