import { uiHelper } from '@/utils';
import { JWT } from '@/constants';

export const jwtHelper = {
  getToken,
  setToken,
  removeToken,
  getBearerToken,
  createAccessToken,
  getLastActivityTime,
  setLastActivityTime,
  removeLastActivityTime,
  setSessionExpirationTime,
  getSessionExpirationTime,
  removeSessionExpirationTime
};

function getToken() {
  return uiHelper.getLocalStorage(JWT.TOKEN);
}

function setToken(keyValue) {
  uiHelper.setLocalStorage(JWT.TOKEN, keyValue);
}

function removeToken() {
  uiHelper.removeLocalStorage(JWT.TOKEN);
}

function getBearerToken() {
  return JWT.JWT_TOKEN_TYPE + uiHelper.getLocalStorage(JWT.TOKEN);
}

function getLastActivityTime() {
  return parseInt(uiHelper.getLocalStorage(JWT.LAST_ACTIVITY_TIME));
}

function setLastActivityTime() {
  uiHelper.setLocalStorage(
    JWT.LAST_ACTIVITY_TIME,
    JSON.stringify(new Date().getTime())
  );
}

function removeLastActivityTime() {
  uiHelper.removeLocalStorage(JWT.LAST_ACTIVITY_TIME);
}

function setSessionExpirationTime(time) {
  let originalExpirationHour = time * 1000;
  let newTime = time != 0 ? new Date().getTime() + originalExpirationHour : 0;

  uiHelper.setLocalStorage(
    JWT.SESSION_EXPIRATION_TIME,
    JSON.stringify(newTime)
  );
}

function getSessionExpirationTime() {
  return parseInt(uiHelper.getLocalStorage(JWT.SESSION_EXPIRATION_TIME));
}

function removeSessionExpirationTime() {
  uiHelper.removeLocalStorage(JWT.SESSION_EXPIRATION_TIME);
}

function createAccessToken(tokenType, accessToken) {
  return [tokenType, accessToken].join(' ');
}
