<template>
  <div class="wh-100">
    <Header :withBorderBottom="true"></Header>
    <v-main class="profileBody">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
      <v-img src="/assets/image/icons/leftBar.png" class="leftBar" />
      <v-img src="/assets/image/icons/rightBar.png" class="rightBar" />
    </v-main>
    <Footer></Footer>
    <NotAllowModal />
  </div>
</template>

<script>
  import Header from '@/components/layout/Header';
  import Footer from '@/components/layout/Footer';
  import NotAllowModal from '@/components/base/NotAllowModal';
  import {
    CONSTANTS_GET_CONSTANTS,
    CONSTANTS_INITIAL_CONSTANTS_STATE
  } from '@/store/constants.module';
  import { AUTH_ME } from '@/store/auth.module';

  export default {
    name: 'ProfileLayout',
    components: {
      Header,
      Footer,
      NotAllowModal
    },
    computed: {
      constantsComplete() {
        return this.$store.state.constants.constants.complete;
      }
    },
    watch: {
      constantsComplete() {
        let response = this.$store.state.constants.constants;

        if (response.complete) {
          this.constantsCompleteAction(response);
        }
      }
    },
    async created() {
      await this.initLayout();
    },
    methods: {
      constantsCompleteAction(response) {
        if (response.code != 0) {
          // console.log(response);
        } else {
          this.initialGetConstantsState();
        }
      },
      async getConstants() {
        await this.$store.dispatch(CONSTANTS_GET_CONSTANTS);
      },
      initialGetConstantsState() {
        this.$store.dispatch(CONSTANTS_INITIAL_CONSTANTS_STATE);
      },
      async getMe() {
        await this.$store.dispatch(AUTH_ME);
      },
      async initLayout() {
        await this.getMe();
        await this.getConstants();
      }
    }
  };
</script>

<style>
  .profileBody {
    background: url('../../../public/assets/image/icons/defaultBackground.png');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
  }
  @media (max-width: 720px) {
    .leftBar {
      width: 55px !important;
    }
    .rightBar {
      width: 20px !important;
    }
  }
  .leftBar {
    position: fixed;
    width: 75px;
    top: 35%;
    left: -37px;
  }
  .rightBar {
    position: fixed;
    width: 30px;
    top: 30%;
    right: 0;
  }
</style>
