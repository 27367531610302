import { apiService } from '@/services';

export const STRIPE = {
  createPaymentIntent,
  getUrl,
  loadStripe
};

const URL = {
  API: 'api',
  V1: 'v1',
  PAYMENT_STRIPE: 'payment-stripe',
  CREATE_PAYMENT_INTENT: 'create-payment-intent',
  CREATE_PAYMENT_INTENT_DUMMY: 'create-payment-intent-dummy',
  LOAD_CARD_FORM: 'load-card-form',
  CANCEL_PAYMENT_INTENT: 'cancel-payment-intent'
};

function createPaymentIntentPayload(data) {
  const { name, item_type, value, ...restPayload } = data;

  let requestBody = {
    item: name,
    item_type: item_type
  };

  switch (item_type) {
    case 'upload_token':
      requestBody.item_quantity = Number(value);
      requestBody.price = 3;
      break;
    default:
      requestBody.item_quantity = 1;
      requestBody.price = Number(value);
      break;
  }

  requestBody = {
    ...requestBody,
    ...restPayload
  };

  return requestBody;
}

/**
 * @param {Object} data
 * @param {String} data.item
 * @param {"subscription" | "upload_token" | "application_fee"} data.item_type
 * @param {Number | String} data.value
 */
function createPaymentIntent(data) {
  let requestBody = createPaymentIntentPayload(data);
  return apiService().post(
    `/${URL.API}/${URL.V1}/${URL.PAYMENT_STRIPE}/${URL.CREATE_PAYMENT_INTENT}`,
    requestBody
  );
}

/**
 * @param {Object} data
 * @param {String} data.clientSecret
 */
function getUrl(url) {
  return window.origin + '/' + url;
}

function loadStripe(payload) {
  const params = new URLSearchParams(payload);

  return (
    apiService().defaults.baseURL +
    `/${URL.API}/${URL.V1}/${URL.LOAD_CARD_FORM}?${params}`
  );
}
