import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ModalBase',{attrs:{"id":"reportModal","width":"900px"},on:{"closeModal":_vm.clearInputs}},[_c('h2',{staticClass:"text-uppercase pb-4"},[_vm._v(" "+_vm._s(_vm.$t('message.whyAreYouReportingThis?'))+" ")]),_c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRadioGroup,{class:_vm.formValues.type == 8 ? 'pb-3' : '',attrs:{"rules":[function (v) { return !!v || 'This field is required'; }],"hide-details":true},model:{value:(_vm.formValues.type),callback:function ($$v) {_vm.$set(_vm.formValues, "type", $$v)},expression:"formValues.type"}},[_c(VRow,{staticClass:"px-3",attrs:{"no-gutters":""}},[_vm._l((_vm.flags),function(type){return _c(VCol,{key:type.value,staticClass:"mb-3",attrs:{"cols":"12","sm":"6"}},[_c(VRadio,{attrs:{"label":type.description,"value":type.value,"color":"primary"}})],1)}),(_vm.formValues.type == 8)?_c(VCol,{staticClass:"mb-3",attrs:{"cols":"12"}},[_c('CustomInput',{attrs:{"label":_vm.$t('label.pleaseSpecify'),"name":"typeOther","type":"text","rules":[function (v) { return !!v || 'This field is required'; }],"dark":""},model:{value:(_vm.formValues.typeOther),callback:function ($$v) {_vm.$set(_vm.formValues, "typeOther", $$v)},expression:"formValues.typeOther"}})],1):_vm._e()],2)],1),_c('div',{staticClass:"mb-4"},[_c('CustomInput',{attrs:{"label":_vm.$t('label.comment'),"name":"comment","type":"textarea","counter":"500","rules":[
            function (v) { return !!v || 'Comment is required'; },
            function (v) { return v.length < 500 || 'This field must not exceed 500 characters'; }
          ],"dark":"","outlined":""},model:{value:(_vm.formValues.comment),callback:function ($$v) {_vm.$set(_vm.formValues, "comment", $$v)},expression:"formValues.comment"}})],1),_c('div',[_c('div',[_vm._v(_vm._s(_vm.$t('label.photo')))]),_c(VRow,{attrs:{"no-gutters":""}},[_vm._l((_vm.formValues.proof),function(photo,index){return [(photo)?_c(VCol,{key:index,staticClass:"me-2",attrs:{"cols":"12","sm":"auto"}},[_c('CustomInput',{attrs:{"name":"proof","type":"file","config":{
                  accept: 'image/png, image/jpeg, image/jpg'
                },"showFileName":false,"dark":"","outlined":""},on:{"clearFile":function($event){return _vm.removeFromArr(index)}},model:{value:(_vm.formValues.proof[index]),callback:function ($$v) {_vm.$set(_vm.formValues.proof, index, $$v)},expression:"formValues.proof[index]"}})],1):_vm._e()]}),(_vm.formValues.proof.length < 5)?_c(VCol,{attrs:{"cols":"12","sm":"auto"}},[_c('div',[_c('CustomInput',{attrs:{"name":"proof","type":"file","config":{
                  accept: 'image/png, image/jpeg, image/jpg'
                },"dark":"","outlined":""},model:{value:(_vm.formValues.proof[_vm.formValues.proof.length || 0]),callback:function ($$v) {_vm.$set(_vm.formValues.proof, _vm.formValues.proof.length || 0, $$v)},expression:"formValues.proof[formValues.proof.length || 0]"}})],1)]):_vm._e()],2),_c('div',{staticClass:"text-right grey--text"},[_vm._v(" "+_vm._s(_vm.formValues.proof.length)+"/5 ")])],1)],1),_c('div',{staticClass:"text-center pt-12 mt-10"},[_c(VBtn,{staticClass:"btn1",attrs:{"disabled":!_vm.valid || !(_vm.formValues.comment && _vm.formValues.type)},on:{"click":_vm.report}},[_vm._v(" "+_vm._s(_vm.$t('label.report'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }