<template>
  <div class="wh-100">
    <v-main>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>
  </div>
</template>

<script>
  export default {
    name: 'BlankLayout'
  };
</script>

<style></style>
