import Vue from 'vue';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';
import { routes } from './routes';
import store from '@/store';
import { AUTH_IS_AUTHENTICATED } from '@/store/auth.module';
import { ROUTE_NAME } from '@/constants';
Vue.use(VueRouter);
Vue.use(Meta);

const scrollBehavior = () => {
  return { x: 0, y: 0 };
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior
});
router.beforeEach((to, from, next) => {
  // Redirect to login page if not logged in and trying to access a restricted page
  const isAuthenticated = store.getters[AUTH_IS_AUTHENTICATED];
  const me = store.getters['auth/me'];
  if (to.meta.requiresAuth) {
    if (!isAuthenticated) {
      // Not logged in so redirect to login page
      next({ name: ROUTE_NAME.HOME });
    } else {
      // to check whether the role able to access the page
      if (to.meta.accessRole && !to.meta.disabledForAdmin) {
        if (!to.meta.accessRole.includes(me.role)) {
          next({ name: ROUTE_NAME.HOME });
        } else next();
      } else if (to.meta.accessRole && to.meta.disabledForAdmin) {
        if (to.meta.accessRole.includes(me.role) && !me.is_admin_login) {
          next();
        } else next({ name: ROUTE_NAME.HOME });
      } else if (!to.meta.accessRole && to.meta.disabledForAdmin) {
        if (me.is_admin_login) {
          next({ name: ROUTE_NAME.HOME });
        } else next();
      } else next();
    }
  } else {
    if (to.meta.beforeLogin) {
      if (isAuthenticated) {
        next({ name: ROUTE_NAME.HOME });
      } else next();
    } else next();
  }
});
export default router;
